import {AuthModel} from './_models'
// @ts-ignore
import Cookies from 'js-cookie'
import {constants} from "../../../config/constants";
import Swal from "sweetalert2";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {getI18n} from "../../../../_spc/i18n/I18n";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

/**
 * Returns true if the token has expired
 * @param token
 */
export const checkTokenExpiration = (token:string) => {
  const payload = token.split('.')[1]; // Get the payload part of the JWT (assuming the payload is the second part)
  const decodedPayload = atob(payload); // Decode the base64-encoded payload
  const parsedPayload = JSON.parse(decodedPayload); // Parse the decoded payload as JSON
  const expiration = parsedPayload.exp; // Assuming the expiration time is stored in 'exp' field
  const currentTime = Math.floor(Date.now() / 1000); // Get the current time in seconds

  return expiration < currentTime;
}

const handleRefreshTokenError = () => {
  Swal.fire({
    customClass: {
      confirmButton: 'btn btn-primary btn-sm fs-5',
    },
    icon: 'error',
    background: '#151521',
    text: 'Location is temporally offline, please try again.',
    confirmButtonText: 'OK, got it!',
    width: '29rem',
    heightAuto: false,
  })

  Cookies.remove(constants.cookies_name.temp_access_token)
  Cookies.remove(constants.cookies_name.access_token)
  window.location.reload()
}

export const sleep = (seconds:number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    async (config: AxiosRequestConfig) => {

      config.params = {
        ...config.params,
        i18n: getI18n()
      }

      if (config?.headers != undefined && config.headers['X-Skip-Interceptor'] === 'true') {
        // Skip interception for requests with the custom header
        return config;
      }

      let access_token = Cookies.get(constants.cookies_name.access_token)

      if (access_token != undefined) {
        //Avoid to refresh token in multiple calls
        if(access_token == 'none') {
          await sleep(3);
          access_token = Cookies.get(constants.cookies_name.access_token)
        }

        // Check if the token has expired
        const isTokenExpired = checkTokenExpiration(access_token);
        if(isTokenExpired) {
          try {
            let skip_config = {
              headers: {
                Authorization: `Bearer ${access_token}`,
                'X-Skip-Interceptor': `true`,
              }
            }

            Cookies.set(constants.cookies_name.access_token, 'none')
            const newToken = await axios.post(`${process.env.REACT_APP_SP_API_URL}/auth/refresh_access_token`, { global_on_id: process.env.REACT_APP_GLOBAL_ON }, skip_config)
            if(newToken?.data?.access_token == undefined ) {
              handleRefreshTokenError()
              return false;
            }
            let new_access_token = newToken?.data?.access_token;
            Cookies.set(constants.cookies_name.access_token, new_access_token)

            if(config?.headers != undefined) {
              config.headers.Authorization = `Bearer ${new_access_token}`;
            }
          }
          catch (error) {
            console.log("Error refreshing the token", error)
            handleRefreshTokenError()
            return false;
          }
        } else if(config?.headers != undefined){
          config.headers.Authorization = `Bearer ${access_token}`
        }
      } else {
        handleRefreshTokenError()
        return false;
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        let response_data = error.response?.data
        if(response_data != undefined && response_data?.error != undefined && response_data?.error?.code != undefined && response_data?.error?.code == constants.errors.auth.access_token_invalid) {
          console.log("error response_data", response_data)
          Cookies.remove(constants.cookies_name.temp_access_token)
          Cookies.remove(constants.cookies_name.access_token)

          setTimeout(() => {
            Swal.fire({
              customClass: {
                confirmButton: 'btn btn-primary btn-sm fs-5',
              },
              icon: 'error',
              background: '#151521',
              text: `Session expired, please try again, if problem continues make sure that you have enabled the cookies.`,
              confirmButtonText: 'Try again!',
              width: '29rem',
              heightAuto: false,
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          }, 10);
          throw error;
        }
        // An error occurred during the request
        throw error;
      }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
