import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UsersData {
  value: any
}

const initialState: UsersData = {
  value: {},
}

export const UsersDataSlice = createSlice({
  name: 'UsersData',
  initialState,
  reducers: {    
    StoreUsersData: (state, action: PayloadAction<any>) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { StoreUsersData } = UsersDataSlice.actions

export default UsersDataSlice.reducer