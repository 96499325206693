import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useLocationBasedBtnColor, useLocationBasedTextBorderColor } from "../../utilities/helpers";

interface Props {
  id?:string;
  title: string;
  disabled?: boolean;
  extraClass?: string;
  onClick?: () => void;
  type?:'submit'|'button';
  children?: React.ReactNode[];
}

const CustomButton: React.FC<Props> = ({
  id,
  title,
  disabled,
  extraClass,
  onClick,
  type,
  children
}) => {
  const dynamicBtnColor = useLocationBasedBtnColor();
  const confirmExtraCss = useLocationBasedTextBorderColor();
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
  const textColor = LocSettings.appearance === 1 ? 'text-black' : 'text-white';
  const isSecondaryButton = extraClass && extraClass.indexOf("customBtnNoBg") > -1;

  return (
    <button
      id={id ?? ''}
      type={type ?? 'button'}
      onClick={onClick}
      disabled={disabled ?? false}
      className={`btn btn-lg ${isSecondaryButton ? "btn-secondary" : "btn-primary"} ${confirmExtraCss} ${extraClass}`}
    >
      {
        children //The idea is to make this more generic with the option of passing multiple children nodes
          ?
          <>{children}</>
          :
          (<span className={`indicator-label ${isSecondaryButton ? textColor : dynamicBtnColor}`}>{title}</span>)
      }
    </button>
  );
};

export default CustomButton;