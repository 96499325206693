import * as React from 'react'
export const HeaderTitleContext = React.createContext<any>(''); //Creates context


export const HeaderTitleProvider = (props: any) => {
    const [pageTitle, setPageTitle] = React.useState<React.SetStateAction<string>>('')
    return (
        <HeaderTitleContext.Provider
            value={{ pageTitle, setPageTitle }}
        >
            {props.children}
        </HeaderTitleContext.Provider>
    );
};