import { createSlice } from '@reduxjs/toolkit'

export interface ReservationState {
  isLoading: boolean,
  data: any,
  error: object,
  isTempReg?:boolean|null
}

export interface intReservationGuest {
  email:string,
  global_country_id:number,
  guest:string,
  guest_id:number,
  phone:string,
  primary:number,
  status_checkin:number,
  status_pre_checkin:number,
}

const initialState: ReservationState = {
  isLoading: false,
  data: {},
  error: {},
  isTempReg:false
}

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    setReservation: (state, action) => {
      state.data = action.payload
      state.isLoading = false
    },
    setError: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },
    clearReservation: (state) => {
      state.data = {}
      state.error = {}
      state.isTempReg=false
    },
    setisTempReg: (state, action) => {
      state.isTempReg = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { startLoading, setReservation, setError, clearReservation,setisTempReg } = reservationSlice.actions
