import { createSlice } from '@reduxjs/toolkit'

interface ITable {
    id: number|null
    id_external: number|null
    location_id: number|null
    number: number|null
    name: string|null
    name_custom: string|null
}

export interface TicketState {
    isLoading: boolean,
    data: object,
    isPublicReceipt: boolean,
    fromUrl: object,
    error: object,
    table: ITable
}

const initialState: TicketState = {
    isLoading: false,
    data: {},
    isPublicReceipt: false,
    fromUrl: {},
    error: {},
    table: {
        id:null,
        id_external:null,
        location_id:null,
        number: null,
        name: null,
        name_custom: null,
    }
}

export const ticketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setTicket: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
        setUrlData: (state, action) => {
            state.fromUrl = action.payload
            state.isLoading = false
        },
        setIsPublicReceipt: (state, action) => {
            state.isPublicReceipt = action.payload
            state.isLoading = false
        },
        setError: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        setTicketInitialState: (state) => {
            state.isLoading = false
            state.data = {}
            state.isPublicReceipt = false
            state.fromUrl = {}
            state.error = {}
        },
        setTable: (state, action) => {
            state.table = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setTicket, setUrlData, setError, setIsPublicReceipt, setTicketInitialState, setTable } = ticketSlice.actions
