import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IRegistrationScanData {
    first_name?:string,
    last_name?:string,
    date_of_birth?:string,
    card_type?:string,
    ExpYear?:string|number
}

export interface IeRegistrationScanData{
    value:IRegistrationScanData|null
}

const initialState: IeRegistrationScanData = {
    value:{},    
}

export const ScanDataSlice = createSlice({
    name: 'ScanData',
    initialState,
    reducers: {
        setScanCardData: (state, action:PayloadAction<IRegistrationScanData>) => {            
            state.value = action.payload
        },
    }
})
export const {setScanCardData} = ScanDataSlice.actions