import * as ApiCallService from "../../../../ApiCallService";
import { setMenuCategory, startLoading, setError, setMenuLocationSettingsId } from "./menuCategoriesSlice";
export const getMenuCategories = (LocSettings: any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())
        ApiCallService.ApiCall({}, `app/menu/categories`, 'GET', LocSettings)
            .then((res: any) => {
                if(res?.error?.message != undefined) {
                    dispatch( setError(res.error) )
                    dispatch( setMenuCategory({}) )
                } else {
                    
                    dispatch( setMenuCategory(res.data.data) )
                    dispatch( setMenuLocationSettingsId(LocSettings.id) )
                }
        }).catch(() => {
            dispatch( setMenuCategory({}) )
        });
    }
}


