import {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import './ScrollTop.scss'
import {
  ScrollTopComponent,
  DrawerComponent,
  ToggleComponent,
  StickyComponent,
} from '../../assets/ts/components'
import {KTSVG} from '../../helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'

export function ScrollTop() {
  const {pathname} = useLocation()
  const isFirstRun = useRef(true)
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
  const exceptScrollTopRoute = ['menu']

  const pluginsReinitialization = () => {
    setTimeout(() => {
      StickyComponent.reInitialization()
      setTimeout(() => {
        ToggleComponent.reinitialization()
        DrawerComponent.reinitialization()
      }, 70)
    }, 140)
  }

  const scrollTop = () => {
    ScrollTopComponent.goTop()
  }

  const updateHeaderSticky = () => {
    const stickyHeader = document.body.querySelectorAll(`[data-kt-sticky-name="header"]`)
    if (stickyHeader && stickyHeader.length > 0) {
      const sticky = StickyComponent.getInstance(stickyHeader[0] as HTMLElement)
      if (sticky) {
        sticky.update()
      }
    }
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
    } else {
      pluginsReinitialization()
    }

    updateHeaderSticky()
    if(exceptScrollTopRoute.includes(pathname.split("/")[3]) && typeof pathname.split("/")[4] == 'undefined'){
      
    }else{
      setTimeout(() => {
        scrollTop()
      }, 0)
    }
  }, [pathname])

  const getButtonBackgroundColor = () => {
    return LocSettings?.primary_color  == '#ffffff' ? '#151520' : '#ffffff';
  }

  useEffect(()=>{
    ScrollTopComponent.reinitialization()
  },[])
 
  return (
    <div id='kt_scrolltop' className='scrolltop' data-kt-scrolltop='true'>
       <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill={LocSettings?.primary_color} fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24"></polygon>
          <rect fill={getButtonBackgroundColor()} opacity="1" x="11" y="10" width="2" height="10" rx="1"></rect>
          <path d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z" fill={getButtonBackgroundColor()} fillRule="nonzero"></path>
        </g>
      </svg>
    </div>
  )
}
 