import React from "react";
import { useLocationBasedTextBorderColor } from "../../utilities/helpers";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {IAPILocSettings} from "../../services/ApiCallService";

interface Props {
    id?:string;
    title: string;
    disabled?: boolean;
    extraClass?: string;
    onClick?: () => void;
    type?:'submit'|'button';
    children?: React.ReactNode[];
    style?: React.CSSProperties;
}

const CustomTransparentButton: React.FC<Props> = ({
                                           id,
                                           title,
                                           disabled,
                                           extraClass,
                                           onClick,
                                           type,
                                           children,
                                           style,
                                       }) => {

    const confirmExtraCss = useLocationBasedTextBorderColor();
    const locSettings = useSelector((state:RootState)=>state.LocSettings.value) as IAPILocSettings|any
    const textColor = locSettings.appearance === 1 ? 'text-black' : 'text-white'
    return (
        <button
            id={id ?? ''}
            type={type ?? 'button'}
            onClick={onClick}
            disabled={disabled ?? false}
            className={`btn btn-lg btn-outline-primary ${confirmExtraCss} ${extraClass}`}
            style={style}
        >
            {
                children //The idea is to make this more generic with the option of passing multiple children nodes
                    ?
                    <>{children}</>
                    :
                    (<span className={`indicator-label fw-bold ${textColor}`}>{title}</span>)
            }
        </button>
    );
};

export default CustomTransparentButton;
