import {setTicket, setUrlData, startLoading, setError} from "./ticketSlice";
import * as ApiCallService from "../../ApiCallService";
import axios from "axios";
import { constants } from "../../../config/constants";

export const getTicket = (ticket_id: number, LocSettings: any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())
        ApiCallService.ApiCall({with: 'guests'}, `tickets/${ticket_id}/digital`, 'GET', LocSettings)
            .then((res: any) => {
                if(res?.error?.message != undefined) {
                    dispatch( setError(res.error) )
                } else {
                    dispatch( setTicket(res.data) )
                }
        }).catch((err) => {
            dispatch( setTicket({}) )
        });
    }
}

export const getUrlData = (short_code: string | undefined) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())

        let config = {
            headers: {
                'X-Skip-Interceptor': `true`,
            }
        }

        const API_URL = `${process.env.REACT_APP_SP_API_URL}/url_data/${short_code}?global_on_id=${process.env.REACT_APP_GLOBAL_ON}`
        axios.get(`${API_URL}`, config)
            .then((res) => {
                dispatch(setUrlData(res.data) )
            })
            .catch((error) => {
                dispatch(setError({message:error.response?.status == 404 ? "profile.receipt.not_found" : constants.errors.static.default_error}))
                dispatch(setUrlData({}) )
            })
    }
}
