export enum REGISTRATION_STEP {
   PHONE_VERIFICATION = 'PHONE_VERIFICATION',
   TERMS = 'TERMS_AND_CONDITIONS',
   BIOMETRICS = 'BIOMETRIC_ENROLLMENT',
   ID_VERIFICATION = 'IDENTITY_VERIFICATION',
   COF = 'PAY_METHOD',
   SUCCESS = 'SUCCESS',
}

export const REGISTRATION_URL_PATTERN = '/:location_id/register/*'
