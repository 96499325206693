import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IModifierGroup} from '../../../../pages/online_ordering/state/CartState'

export interface ICartItemState {
  id: number
  id_external: string
  category_id: number
  locId: number
  itemId: number
  itemName: string
  itemPrice: number
  itemUrl: string
  itemQty: number
  categoryName: string
  categorySectionId: string
  mods: Array<IModifierGroup>
  comment: string
  taxRate: number
  price_level?:string|number
}

export interface ICartItemUpdateState {
  id: number
  itemQty: number
  comment: string
  mods: Array<IModifierGroup>
}

export interface IcartState {
  isLoading: boolean
  locId: number | null
  data: Array<ICartItemState>
}
export interface ILocId {
  locId: number
}

const initialState: IcartState = {
  isLoading: true,
  locId: null,
  data: [],
}

export const cartSlice = createSlice({
  name: 'cartItems',
  initialState,
  reducers: {
    addItemToCart: (state, action: PayloadAction<ICartItemState>) => {
      state.data.push(action.payload)
    },
    updateCartItem: (state, action: PayloadAction<ICartItemUpdateState>) => {
      const itemIdToUpdate = action.payload.id
      state.data = state.data.filter((cartItem) => {
        if (cartItem.id == itemIdToUpdate) {
          cartItem.itemQty = action.payload.itemQty
          cartItem.mods = action.payload.mods
          cartItem.comment = action.payload.comment
        }
        return cartItem
      })
    },
    removeItemFromCart: (state, action: PayloadAction<number>) => {
      const itemIdToRemove = action.payload
      state.data = state.data.filter((cartItem) => {
        return cartItem.id != itemIdToRemove
      })
    },

    setCartLocationId: (state, action: PayloadAction<number | null>) => {
      state.locId = action.payload
    },

    resetCart: (state) => {
      state.data = []
      state.isLoading = true
      state.locId = null
    },
  },
})

// Action creators are generated for each case reducer function
export const {addItemToCart, updateCartItem, removeItemFromCart, resetCart, setCartLocationId} =
  cartSlice.actions
