import { createSlice } from '@reduxjs/toolkit'

export interface MenuModifierGroupsState {
    isLoading: boolean,
    data: object,
}

const initialState: MenuModifierGroupsState = {
    isLoading: false,
    data: {},
    
}

export const menuModifierGroupsSlice = createSlice({
    name: 'menuModifierGroups',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setMenuModifierGroups: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
        
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setMenuModifierGroups } = menuModifierGroupsSlice.actions
