import { Dispatch, FC, memo, SetStateAction, useEffect, useRef } from "react";
import ReactFlagsSelect from "react-flags-select";
import { countries } from "../../common/country_state/Countries";

//define types for props passed down
interface CountryFlagSelector {
    selected: string
    previousSelected: string
    setSelected: Dispatch<SetStateAction<string>>;
    setPreviousSelected: Dispatch<SetStateAction<string>>;
}
const CountryFlagSelector: FC<CountryFlagSelector> = ({ selected, previousSelected, setSelected, setPreviousSelected }) => {
    //attempts to assign selected element to ref hook to persist styling after dropdown list is hidden
    let liRef = useRef<HTMLLIElement | HTMLElement | null>(document.querySelector(`#countryList2 #rfs-${selected}`));
    //search for call code and append className to selected call code
    const addSyles = () => {
        
        liRef.current = document.querySelector(`#countryList2 #rfs-${selected}`)
        let appendSelectedList = document?.querySelector('#countryList2 ul div');
        if (appendSelectedList && liRef.current) {
            appendSelectedList.after(liRef.current)
        }
        liRef.current?.classList.add('activeCallCodeID')
        setTimeout(() => {
            liRef.current = document.querySelector(`#countryList2 #rfs-${previousSelected}`);
            liRef.current?.classList.remove('activeCallCodeID');
            //clearInterval(persist)
        }, 5000)
    };
    //needed to clearInternal for perisist styling li element after selection
    let persist = setInterval(addSyles, 700);
    useEffect(() => {
        //onmount runs after page is rendered
        setPreviousSelected(selected)
        clearInterval(persist)
        liRef.current = document.querySelector(`#countryList2 #rfs-${previousSelected}`);
        liRef.current?.classList.remove('activeCallCodeID');
        return (() => {
            //cleanup - unmount side effects
            clearInterval(persist)
            liRef.current = document.querySelector(`#countryList2 #rfs-${previousSelected}`);
            liRef.current?.classList.remove('activeCallCodeID');
            setPreviousSelected(selected)
        })
    }, [previousSelected]);

    const getCountryCodes = () => {
        const countriesArray = Object.entries(countries).map(([key, value]) => ({ countryCode: key, ...value }));
        countriesArray.sort((a, b) => a.primary.localeCompare(b.primary));
        const sortedKeys = countriesArray.map(country => country.countryCode);
        sortedKeys.unshift(...["US","CA","MX"]);
        return sortedKeys;
    }
    //prevents excessive calls
    setTimeout(() => {
        clearInterval(persist)
    }, 20000) //clear after 20 seconds- will cause all selected styles to stop persisting
    return (
        <ReactFlagsSelect
            className="w-100"
            selectButtonClassName="w-100 overflow-hidden react-select-flags-bg-color form-control form-control-solid customInputBackground"
            fullWidth={true}
            searchable={true}
            selected={selected}
            countries={getCountryCodes()}
            customLabels={countries}
            showSelectedLabel={true}
            showSecondarySelectedLabel={true}
            showOptionLabel={true}
            showSecondaryOptionLabel={false}
            id="countryList2"
            onSelect={(code) => {
                clearInterval(persist)
                setSelected(code)
            }}
        />
    );
};
export default memo(CountryFlagSelector)
