import {useEffect, useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import type {RootState} from '../../../../store'
import {AppDispatch} from '../../../../store'
import {useLocationBasedBtnColor} from '../../../../utilities/helpers'
import {constants} from '../../../../config/constants'
import {useAppAlerts} from '../../../../utilities/alerts'
// @ts-ignore
import Cookies from 'js-cookie'
import {FormattedMessage, useIntl} from 'react-intl'
import axios from 'axios'
import { instanceOfSpError, ISpError } from '../../../../common/api_interfaces/Errors'
import { getRegistrationStepRequest } from '../../registration_module_apis'
import { getLocationId, getRegistrationId, getUserId } from '../../utilities'
import { setCvvDone } from '../../../../services/store/registration/RegistrationSlice'

export interface IShareGuest {
   onCancel?: () => void
}

const CardReviewView = (props: IShareGuest) => {
    const intl = useIntl();

   let navigateTo = useNavigate()
   const dispatch = useDispatch<AppDispatch>()
   const guest = useSelector((state: RootState) => state.guest)

   const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
   const registrationSessionTracker = useSelector(
      (state: RootState) => state.registrationSessionTracker
   )
    const dynamicBtnColor = useLocationBasedBtnColor()
    
   const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON
   const API_URL = process.env.REACT_APP_SP_API_URL

   const {fireErrorAlert} = useAppAlerts()
   const [cvv, setCvv] = useState('');
   const [fieldErrorCvc, setFieldErrorCvc] = useState('');
   const {errors, routes} = constants

   useEffect(() => {
    setCardImage(registrationSessionTracker.cvv_stored_token?.card_type)
   }, [])
   
 
   const addNewBtnHandled = () => {
      navigateTo(LocSettings.base_domain + routes.private.registration_pay_method)
   }

   const submitBtnHandled = () => {
    if(validateCVV(cvv)){
        const thumbnailPayload = new FormData()
        thumbnailPayload.append('cvv', cvv)
    
        axios.post(`${API_URL}/locations/${LocSettings.location.id}/guests/${guest.data.id}/guest_cc_token/${registrationSessionTracker.cvv_stored_token?.token_id}/add_validating_cvv?global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`, thumbnailPayload)
        .then((uploadRes) => {
            dispatch(setCvvDone(true))
            navigateTo(`${LocSettings.base_domain }/${routes.private.submission_review}`)
        })
        .catch(error => {
            const jsonRes:any | ISpError = error?.response?.data
            const msg = jsonRes && instanceOfSpError(jsonRes) ?
                error.response.data.error.message :
                intl.formatMessage({id:errors.static.default_error})
            fireErrorAlert(msg);
        })
    }
   }

   const setCardImage = (cardType?: string) => {
        if(!cardType) return
        var img = document.createElement("img");

        var ccnumImg = document.getElementById("CardThumbnailImg");
        if (ccnumImg) ccnumImg.remove();

        if (cardType == 'mastercard' || cardType == "M")
            img.src = "\\media\\cards\\mc.png";
        else if (cardType == 'Visa' || cardType == "V")
            img.src = "\\media\\cards\\visa.png";
        else if (cardType == 'discover')
            img.src = "\\media\\cards\\discover.png";
        else if (cardType == 'amex' || cardType == "A")
            img.src = "\\media\\cards\\amex.png";
        else
            img.src = "\\media\\cards\\no_card.png";

        img.height = 40
        img.id = "CardThumbnailImg";
        var ccnum = document.getElementById("tsep-cardNum");
        if (ccnum) {
            ccnum.classList.add("ccnum-style");//positions CC image at the end of input field
            ccnum.style.backgroundImage = `url(${img.src})`;
        }
    }

    function handleCvcChange(event:React.ChangeEvent<HTMLInputElement>){
        const maxLength = 4;
        const inputValue = event.target.value;
        const regex = /^[0-9]*$/;

        if (regex.test(inputValue)) {
            if (inputValue.length <= maxLength) {
              setCvv(inputValue);
            }
        }
    }
    function handleCvcBlur(event:React.ChangeEvent<HTMLInputElement>){
        let value =  event.target.value;
        setCvv(value)
        validateCVV(value)
    }

    function validateCVV(cvv:string){
        let isValid = false
        if(cvv.trim() === ''){
            setTimeout(() => {
                setFieldErrorCvc('Field is required')
            }, 15);
        }else if(cvv.length < 3){
            setTimeout(() => {
                setFieldErrorCvc('Field must be at least 3 characters long')
            }, 15);
        }else{
            isValid = true
            setFieldErrorCvc('');
        }
        return isValid
    }

   return (
      <>
           <div className='container-fluid mw-500px px-0 pt-10 pt-lg-20' style={{ marginTop: '17px' }}>
               <div className=' mx-md-0'>
                   <div className='fs-6 form-label fw-bold card p-5'>
                       <div className='card p-6 mb-xl-9 w-100 text-center'>
                           {' '}
                           <FormattedMessage
                               id='guest.enter_cvv_of_default_payment_method'
                               defaultMessage='Welcome back, you already have a payment method registered with us.
                                Please enter the CVV of your default payment method or add a new one to authorize your wallet on this location.'
                           />{' '}
                       </div>
                       <div className='card p-6 w-100'>
                           <div className='d-flex flex-column mb-2 fv-row'>
                               <label className='required fs-6 fw-bold form-label mb-2'><FormattedMessage id='common.card_number' defaultMessage='Card Number' /></label>
                               <div className='mb-5'>
                                   {/* <input id='tsep-cardNum' className='form-control form-control-solid customInputBackground' type="text" readOnly/> */}
                                   <input id='tsep-cardNum' value={`**** **** **** ${registrationSessionTracker.cvv_stored_token?.last4}`} className='form-control form-control-solid customInputBackground' readOnly />
                               </div>
                               <div className='col-md-4 fv-row'>
                                   <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                       <span className='required'><FormattedMessage id='common.cvv' defaultMessage='CVV' /></span>
                                   </label>
                                   <div className="input">
                                       <input type="text" id="cvv" className="form-control validate-cvc" placeholder="CVV" onBlur={handleCvcBlur} onChange={handleCvcChange} inputMode="numeric" />
                                   </div>
                            
                               </div>
                               {fieldErrorCvc && <div className='fv-plugins-message-container'>
                                           <div className='fv-help-block'>
                                               {fieldErrorCvc}</div></div>}
                           </div>
                            <p className='mt-10'>
                            <div className='row d-flex'>
                           <button
                               type='button'
                               id='kt_sign_in_submit'
                               className={`btn btn-lg btn-primary w-100  ${dynamicBtnColor}`}
                               onClick={submitBtnHandled}
                           >
                               <span className='indicator-label'>
                                   <FormattedMessage
                                       id='common.continue'
                                       defaultMessage='Continue'
                                   />
                               </span>
                           </button>
                       </div>
                       <div className='row d-flex pt-5'>
                           <button
                               type='button'
                               id='kt_sign_in_submit'
                               className={`btn btn-lg btn-primary w-100  ${dynamicBtnColor}`}
                               onClick={addNewBtnHandled}
                           >
                               <span className='indicator-label'>
                                   <FormattedMessage id='registration.add_new_payment_method' defaultMessage='Add New Payment Method' />
                               </span>
                           </button>
                       </div>
                            </p>
                       </div>

                     
                   </div>
               </div>
           </div>
      </>
   )
}

export default CardReviewView
 