import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export const useTextColor = () => {
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    interface State {
        textColorBasedBgLoction: string | null | undefined,
        btnColorBasedPCLoction:  string | null,
        SVGColorBasedPCLoction:  string | null,
        textBorderColorBasedBgPCLoction: string | null | undefined,
        SVGTextColorBasedPCLoction: string,

    }

  const intialState: State ={
     textColorBasedBgLoction: '',
    btnColorBasedPCLoction: null,
    SVGColorBasedPCLoction: 'text-primary',
    textBorderColorBasedBgPCLoction: 'text-primary',
    SVGTextColorBasedPCLoction: 'text-primary',
}
     const [data, setData] = useState<State | null>(null);
useEffect(() => {

     const TextColorBasedBgLoction = () => {

              if(LocSettings.appearance === 1 && LocSettings?.background_color == '#151520'){
                 return 'text-white';
             }else if( LocSettings.appearance === 0 && LocSettings?.background_color == '#ffffff'){
                  return 'text-black';
             }
               return '';

    }

    const TextBorderColorBasedBgPCLoction= () =>  {

         if(LocSettings.appearance === 1 && LocSettings?.background_color == '#ffffff' && LocSettings?.primary_color == '#ffffff'){
            return 'text-black border border-secondary';
        }else if( LocSettings.appearance === 0 && LocSettings?.background_color == '#151520' && LocSettings?.primary_color == '#151520'){
             return 'text-white  border border-secondary';
        }
     }


    const BtnColorBasedPCLoction = ()  => {

        if(LocSettings.appearance === 1 && LocSettings?.background_color == '#ffffff' && LocSettings?.primary_color == '#ffffff'){
         return 'text-black';
     }else if( LocSettings.appearance === 0 && LocSettings?.background_color == '#151520' && LocSettings?.primary_color == '#151520'){
          return 'text-white ';
     }else if(LocSettings.appearance === 0 && LocSettings?.primary_color == '#ffffff'){
           return 'text-black';
        }else if(LocSettings.appearance === 0 && LocSettings?.primary_color == '#151520'){
            return 'text-white';
       }else if( LocSettings.appearance === 1 && LocSettings?.primary_color == '#ffffff'){
            return 'text-white';
       }else if( LocSettings.appearance === 1 && LocSettings?.primary_color == '#151520'){
        return 'text-white';
    }
       else{
            return ''
       }


    }

    const SVGColorBasedPCLoction = ()  => {


        if(LocSettings.appearance === 1 && LocSettings?.primary_color == '#ffffff'){
           return 'svg-icon-dark';
       }else if( LocSettings.appearance === 0 && LocSettings?.primary_color == '#151520'){
            return 'svg-icon-white';
       }else{
            return 'svg-icon-primary'
       }
    }

    const SVGTextColorBasedPCLoction = ()  => {


        if(LocSettings.appearance === 1 && LocSettings?.primary_color == '#ffffff'){
           return 'text-black';
       }else if( LocSettings.appearance === 0 && LocSettings?.primary_color == '#151520'){
            return 'text-white';
       }else{
            return 'text-primary'
       }
    }

    setData({...data,
     textBorderColorBasedBgPCLoction:TextBorderColorBasedBgPCLoction(),
     SVGTextColorBasedPCLoction:SVGTextColorBasedPCLoction(),
     SVGColorBasedPCLoction:SVGColorBasedPCLoction(),
     btnColorBasedPCLoction:BtnColorBasedPCLoction(),
     textColorBasedBgLoction:TextColorBasedBgLoction()
});

   },[]);

    return [data];
}
