import {useEffect, useState} from 'react'

export async function getImage(url: string): Promise<string> {
  const CACHE_NAME = `image-cache`
  // console.log('--->GET IMAGE', url)
  try {
    // Check if the image is already cached in the browser cache
    const cache = await caches.open(CACHE_NAME)
    const cachedResponse = await cache.match(url)
    if (cachedResponse) {
      return cachedResponse.url
    }

    // Fetch the image
    const imageResponse = await fetch(url)

    // Ensure response was successful
    if (!imageResponse.ok) {
      throw new Error(`Failed to fetch image: ${url}`)
    }

    // Extract the blob from the response
    const imageBlob = await imageResponse.blob()

    // Cache the blob
    const cachePromises = [cache.put(url, new Response(imageBlob))]

    // Wait for all the caches to complete
    await Promise.all(cachePromises)

    // Return the URL of the original image
    return imageResponse.url
  } catch (error) {
    console.error(error)
    return url
  }
}

export function useCachedImage(url: string, placeholderUrl: string): [boolean, string] {
  const [isLoading, setIsLoading] = useState(true)
  const [imageSrc, setImageSrc] = useState(placeholderUrl)
  const queryString = url.split('?')
  const CACHE_NAME = `image-cache`

  useEffect(() => {
    let isMounted = true
    async function loadImage() {
      // Check for a null path
      const params = new URLSearchParams(url.split('?')[1])
      const searchQuery = params.get('path')
      if (searchQuery?.includes('null')) return [isMounted, placeholderUrl]

      try {
        //console.log('test1')
        const cachedImage = await getImage(url)
        const cache = await caches.open(CACHE_NAME)
        const cachedResponse = await cache.match(url)

        if (cachedResponse && cachedResponse.blob) {
          const cachedResponseBlob = await cachedResponse.blob()
          const cachedResponseUrl = URL.createObjectURL(cachedResponseBlob)
          if (isMounted && cachedResponseUrl) {
            setIsLoading(false)
            setImageSrc(cachedResponseUrl)
          } else {
            setIsLoading(false)
            setImageSrc(cachedImage)
          }
        } else {
          setIsLoading(false)
          setImageSrc(cachedImage)
        }
      } catch (err) {
        console.error(`Error loading image: ${err}`)
      }
    }

    if (queryString.length > 1) {
      const queryUri = new URLSearchParams(queryString[1])

      if (queryUri.has('path') && queryUri.get('path') == 'null') {
        setIsLoading(false)
      } else {
        loadImage()
      }
    } else {
      loadImage()
    }

    return () => {
      isMounted = false
    }
  }, [url])

  return [isLoading, imageSrc]
}
//caches.open('image-cache').then(cache => cache.keys().then(console.log))
