import React,{FC,useState } from "react";
import {KTSVG} from '../../../../_spc/helpers'
import '../../receipt/styles/index.scss'
import {constants} from '../../../config/constants'
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

export interface ITicketItemDiscount{
    id:number,
    name:string|null,
    value:number|null
}

export interface ITicketItem{
    modifiers: any;
    id:number,
    quantity:number|null,
    price:number|null,
    tax:number|null,
    name:string|null,
    payment_status:number,
    discounts:[ITicketItemDiscount]
    seat:string|null,
    seats_paid:number[],
    other_charges:number,
    comment:string|null,
    menu_item:number|null,
}

export interface IItemizedTab{
    items:[ITicketItem], 
    surcharge_item_ids?:number[]     
}

export interface IItemizedTabInt{
    items:[ITicketItem],
    IsSplitItemSel:boolean,
    handleChkChange:(e:React.ChangeEvent<HTMLInputElement>,seat:any,id:number,price:number|0,quantity:number|null,taxAmt:number|0,dSeatCnt:number|1,discounts:[ITicketItemDiscount],OtherCharge:number) => void,
    CheckedItems:any[],
    handleSeatChkChange:(e:React.ChangeEvent<HTMLInputElement>,seat:any,SeatItems:any) => void,
    surcharge_item_ids?:number[],
    other_charges?:number
}

export const ItemizedTab: FC<IItemizedTab> = ({items,surcharge_item_ids}) => {
    const locationSettings = useSelector((state:RootState) => state.LocSettings.value )
    return (
        <table border={0} className="text-gray-600" id="receiptItemTable" cellPadding="5" cellSpacing="0" width="100%">
            <tbody>
                {
                    items.map((item) => {
                        if( item.menu_item == locationSettings.olo_item_id_guest_name ||
                            item.menu_item == locationSettings.olo_item_id_phone ||
                            item.menu_item == locationSettings.olo_item_id_pickup_time ||
                            item.menu_item == locationSettings.olo_item_id_vehicle
                        ) {
                            return
                        }

                        const itemPrice = item.price ? (item.price / 100.00).toFixed(2) : (0).toFixed(2)
                        return (
                            (surcharge_item_ids && surcharge_item_ids.length>0 && surcharge_item_ids.includes(item.id))?<></>:
                            <React.Fragment key={item.id}>
                                <tr>                                    
                                    <td className='text-start'>{item?.quantity} {item.name ?? '[Item Name]'}</td>
                                    <td />
                                    <td />
                                    <td className='text-end'>{itemPrice == '0.00' ? '' : `$` + itemPrice}</td>
                                </tr>
                                {
                                    item.comment != undefined &&
                                    (
                                        <tr className='text-start'>
                                            <td className='ps-12'>{item.comment}</td>
                                            <td />
                                            <td />
                                            <td />
                                        </tr>
                                    )
                                }
                                {
                                    item.modifiers.length > 0
                                        ?
                                        item.modifiers.map((mod: any, index: number) => {
                                            // let str = mod.price.toString();
                                            // let arr = str.split('');
                                            // arr.splice(1, 0, '.');
                                            // let modPrice = arr.join('');
                                            let modPrice = (mod.price / 100.00).toFixed(2)

                                            return (
                                                <tr className='text-start d-none' key={mod.id}>                                                     
                                                    <td className='ps-12'>{mod.name ?mod.quantity+' '+mod.name : ''}</td>
                                                    <td />
                                                    <td />
                                                    <td className='text-end'>{mod.price ? `$${modPrice}` : '$0.00'}</td> 
                                                </tr>
                                            )
                                        })
                                        :
                                        <></>
                                }
                            </React.Fragment>
                        )
                                                
                    })
                }
            </tbody>
        </table>
    )
}
export const ItemizedTabBySeat: FC<IItemizedTabInt> = ({ items, IsSplitItemSel,handleChkChange,CheckedItems,handleSeatChkChange,surcharge_item_ids,other_charges }) => {
    const locationSettings = useSelector((state:RootState) => state.LocSettings.value )
    var seatArray:any = [];
    for (let i = 0; i < items.length; ++i) {
        if( items[i].menu_item == locationSettings.olo_item_id_guest_name ||
            items[i].menu_item == locationSettings.olo_item_id_phone ||
            items[i].menu_item == locationSettings.olo_item_id_pickup_time ||
            items[i].menu_item == locationSettings.olo_item_id_vehicle
        ) {
            continue
        }
        if((surcharge_item_ids && surcharge_item_ids.length>0 && surcharge_item_ids.includes(items[i].id))){
            continue;
        }
        let Seats = items[i].seat?items[i].seat?.split(','):[]
        Seats?.map(function(r){
            if(!seatArray.includes(r)){
                seatArray.push(r);
            }
        })
    }  
    seatArray.sort((a:any,b:any) => a - b);
    
    
    return (
        <table border={0} className="text-gray-600" id="receiptItemTable" cellPadding="5" cellSpacing="0" width="100%">
            <tbody>
                {
                    seatArray.map((seat:any) => {
                        let AllItemsPaid = true;
                        let AllChecked = true;
                        let SeatItems = items.filter((itm)=>{                                 
                                if((surcharge_item_ids && surcharge_item_ids.length>0 && surcharge_item_ids.includes(itm.id))){}else{
                                    let st = itm.seat?.split(',');
                                    if(st?.includes(seat)){
                                        let seats_paid = itm.seats_paid?.length>0?itm.seats_paid:[];
                                        if(!seats_paid.includes(seat)){
                                            AllItemsPaid = false;
                                            let isChecked = CheckedItems.filter((sitm)=>sitm.id==itm.id && sitm.seat==seat).length;
                                            if(!isChecked){
                                                AllChecked = false;
                                            }
                                        }
                                        return itm
                                    }
                                } 
                            }
                        );                        
                                          
                        return (
                            SeatItems.length>0?
                                <React.Fragment key={seat}>
                                    <tr>
                                        <td className='text-start u text-info'>
                                            <div className="form-check form-check-custom form-check-solid">
                                                {
                                                    AllItemsPaid?<>
                                                        <KTSVG path='/media/icons/duotune/finance/fin010.svg' className='svg-icon-2 rounded-border' />
                                                        <label className="form-check-label" htmlFor={`flexCheckDefaultSeat_${seat}`}>
                                                        <u>Seat {seat}</u>
                                                        </label>
                                                    </>:
                                                    <>
                                                        <input onChange={(e)=>handleSeatChkChange(e,seat,SeatItems)}  className="form-check-input" checked={AllChecked} type="checkbox" value="" id={`flexCheckDefaultSeat_${seat}`}/>
                                                        <label className="form-check-label" htmlFor={`flexCheckDefaultSeat_${seat}`}>
                                                        <u>Seat {seat}</u>
                                                        </label>
                                                    </>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        SeatItems.map((item)=>{
                                            let SeatCnt = item.seat?.split(',').length;
                                            let seats_paid = item.seats_paid?.length>0?item.seats_paid:[];
                                            let dSeatCnt = SeatCnt && SeatCnt>0?SeatCnt:1;
                                            const itemPrice = item.price ? ((item.price/dSeatCnt) / 100.00).toFixed(2) : (0).toFixed(2);
                                            const priceSent = item.price?(item.price/dSeatCnt):0;
                                            const taxAmt = item.tax?(item.tax/dSeatCnt):0;
                                            let isChecked = CheckedItems.filter((itm)=>itm.id==item.id && itm.seat==seat).length;
                                            let OtherCharge = other_charges?(other_charges/dSeatCnt):0;
                                            let OtherChargeByitm = OtherCharge?(OtherCharge/SeatItems.length):0;              
                                            return (
                                                ((surcharge_item_ids && surcharge_item_ids.length>0 && surcharge_item_ids.includes(item.id)))?<></>:
                                                <React.Fragment>
                                                    <tr key={item.id}>
                                                        {                                                            
                                                            <td className='text-start'>
                                                                <div className="mb-0">
                                                                    <div className="form-check form-check-custom form-check-solid">
                                                                        {
                                                                            //item.payment_status!=1 && item.payment_status!=2?
                                                                            !seats_paid.includes(seat)?
                                                                            <>
                                                                                <input onChange={(e)=>handleChkChange(e,seat,item.id,priceSent,item.quantity,taxAmt,dSeatCnt,item.discounts,OtherChargeByitm)}  className="form-check-input" type="checkbox" checked={isChecked?true:false} value="" id={`flexCheckDefault_${item.id}`}/>
                                                                                <span style={{minWidth:"25px",marginLeft:'5px',display:"inline-block"}}> {item.quantity} {dSeatCnt && dSeatCnt>1?`/ ${dSeatCnt}`:''} </span> <span style={{marginLeft:'20px', lineHeight:'15px'}}> {item.name?item.name:''}</span>
                                                                            </>:
                                                                            <>
                                                                                <KTSVG path='/media/icons/duotune/finance/fin010.svg' className='svg-icon-2 rounded-border' />
                                                                                <span style={{minWidth:"25px",marginLeft:'5px',display:"inline-block"}}> {item.quantity} {dSeatCnt && dSeatCnt>1?`/ ${dSeatCnt}`:''} </span> <span style={{marginLeft:'20px', lineHeight:'15px'}}> {item.name?item.name:''}</span>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>                                       
                                                            </td>
                                                        }                                    
                                                        <td />
                                                        <td />
                                                        <td className='text-end'>${itemPrice}</td>
                                                    </tr>
                                                    {
                                                        item.modifiers.length > 0
                                                            ?
                                                            item.modifiers.map((mod: any, index: number) => {
                                                                // let str = mod.price.toString();
                                                                // let arr = str.split('');
                                                                // arr.splice(1, 0, '.');
                                                                // let modPrice = arr.join('');
                                                                let modPrice = (mod.price / 100.00).toFixed(2)

                                                                return (
                                                                    <tr className='text-start d-none' key={mod.id}>                                                     
                                                                        <td className='ps-12'>{mod.name ?mod.quantity+' '+mod.name : ''}</td>
                                                                        <td />
                                                                        <td />
                                                                        <td className='text-end'>{mod.price ? `$${modPrice}` : '$0.00'}</td> 
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <></>
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </React.Fragment>
                            :''
                        )
                    })
                }
            </tbody>
        </table>
    )
    
}
