
export interface ILogoProps{
    location_name?:string|null,
    img_props:{
        logoFilePath:string|null,
        global_on_id?:number|string|undefined
    }
}

export const ReceiptLogo = ({img_props, location_name}:ILogoProps) => {
    const globalOn = process.env.REACT_APP_GLOBAL_ON
    const downLoadFileURL = `${process.env.REACT_APP_SP_API_URL}/download-file`
    const imgSrc = `${downLoadFileURL}?path=${img_props.logoFilePath}&global_on_id=${globalOn}`
    return (
        <tr>
            <td className='logo' align="center">
                {
                    img_props.logoFilePath ? <img 
                        src={imgSrc}
                        className="h-100px w-100px symbol"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.alt="";
                            }} />
                        :''
                        // <h1 className='text-center mt-3'>{location_name ?? '[Location]'}</h1>
                }
            </td>
        </tr>
    )
}