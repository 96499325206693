import React, {useContext, useEffect, useState} from 'react'
import {HeaderTitleContext} from "../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {constants} from "../../config/constants";
import {resetKioskCheckinState} from "../../services/store/checkin/kiosk_checkin/codeActivationSlice";
import {Navigate, useNavigate} from 'react-router-dom';
import {useLocationBasedBtnColor} from "../../utilities/helpers";
// @ts-ignore
import Cookies from 'js-cookie'
import {useFirstRender} from "../../services/hooks/useFirstRender";
import { FormattedMessage, useIntl } from 'react-intl';

export const ConfirmationPage = () => {
    const intl = useIntl();
    let navigateTo = useNavigate()
    const dispatch = useDispatch<AppDispatch>();
    const { setPageTitle } = useContext(HeaderTitleContext);
    const [fetchLocationLogo, setFetchLocationLogo] = useState('');
    let darkModeLogo = '/media/logos/darkModeFullSoftPointLogo.svg';
    let lightModeLogo = '/media/logos/lightModeFullSoftPointLogo.svg';
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const kiosk_checkin = useSelector((state: RootState) => state.codeActivation);
    const topMessage = `${intl.formatMessage({id:"kiosk.confirmation.your"})} ${LocSettings.location.location_name} ${intl.formatMessage({id:"kiosk.confirmation.authentication_completed"})}`;
    const bottomMessage = intl.formatMessage({id:"kiosk.confirmation.return_to_kiosk_to_continue"})
    const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON
    const dynamicBtnColor = useLocationBasedBtnColor()
    if (LocSettings?.location?.logo) {
        lightModeLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.location.logo}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`;
        darkModeLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.location.logo}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`;
    }
    const locationLogoInConfigurationProfile =
        localStorage.getItem('kt_theme_mode_value') === 'light'
            ? lightModeLogo
            : darkModeLogo
    ;
    let base_domain = Cookies.get(constants.cookies_name.location_domain_name)
    if (base_domain && base_domain.trim() === '') {
        base_domain = LocSettings.location.id;
    }
    useEffect(() => {
        if(kiosk_checkin?.steps?.confirmation == true) {
            //will fetch location logo in configuration profile
            fetch(locationLogoInConfigurationProfile)
                .then(response =>
                    setFetchLocationLogo(response.url),
                ).catch(err => console.log(err))

            console.log("kiosk_checkin", kiosk_checkin)
            dispatch(resetKioskCheckinState())
        }
    }, []);
    useEffect(() => {
        setPageTitle(intl.formatMessage({id:constants.staticPageTitle.Authenticated}));
    }, []);


    if(useFirstRender() && kiosk_checkin?.steps?.confirmation == false) {
        dispatch(resetKioskCheckinState())
        return <Navigate to={`/${base_domain}`} />
    }

    return (
        <div className='p-0 text-center' >
            <div className='pb-10 text-center d-flex flex-column'>
                <div ref={(el) => el?.style.setProperty('border-color', 'rgb(80, 205, 137, 0.3)', 'important')} className='swal2-icon swal2-success'>
                    <span 
                      ref={(el) => el?.style.setProperty('background-color', '#50cd89', 'important')} className='swal2-success-line-tip'></span>
                    <span  ref={(el) => el?.style.setProperty('background-color', '#50cd89', 'important')} className='swal2-success-line-long'></span>
                </div>            
                
            </div>
            <p className='fw-bold fs-6'> {topMessage} </p>
            <p className='fw-bold fs-6'> {bottomMessage} </p>
            <div className='mt-5 d-flex justify-content-center'>
                <div className="mw-200px card">
                    <button
                        onClick={() => {
                            navigateTo(`/${base_domain}`);
                        }}
                        type='button'
                        id='kt_sign_in_submit'
                        className={`btn btn-lg btn-primary w-100 ${dynamicBtnColor}`}
                    >
                        <span className='indicator-label'><FormattedMessage id='common.return_to_home' defaultMessage='Return to home' /></span>
                    </button>
                </div>
            </div>
        </div>
    )
}
