import React, {useContext, useEffect, useState} from 'react'
import {Step6} from '../../../../modules/wizards/components/steps/Step6'
import moment from 'moment/moment'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../../store'
import * as ApiCallService from '../../../../services/ApiCallService'
import {HeaderTitleContext} from '../../../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore'
import {constants} from '../../../../config/constants'
import {clearReservation} from '../../../../services/store/reservations'
import {useNavigate} from 'react-router-dom'
import {LoadingPage} from '../../../../modules/widgets/Loaders/LoadingPage'
import {useErrorAlert} from '../../../../utilities/alerts'
import {useLocationBasedBtnColor} from '../../../../utilities/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {getI18n} from "../../../../../_spc/i18n/I18n";
import useIframeSession from "../../../../modules/registration/views/iframe_session/useIframeSession";

export const ConfirmationDone = () => {
   const intl = useIntl()
   const fireErrorAlert = useErrorAlert()
   const dispatch = useDispatch<AppDispatch>()
   const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
   const SourceFlow = useSelector((state: RootState) => state.Source.flow)
   const reservation = useSelector((state: RootState) => state.reservation)
   const guest = useSelector((state: any) => state.guest)
   const [loading, setLoading] = useState(true)
   const navigateTo = useNavigate()
   const base_domain = LocSettings.base_domain
   const {setPageTitle} = useContext(HeaderTitleContext)
   setPageTitle(intl.formatMessage({id: constants.staticPageTitle.confirmed}))
   const dynamicBtnColor = useLocationBasedBtnColor()
   const iframe_session_id = useSelector((state: RootState) => state.Source.iframe_session_id)
   const { loading: loadingRegistrationSession, activateRegistrationSession } = useIframeSession();

   useEffect(() => {
      const reservationDataLoaded =
         reservation.isLoading == false && Object.keys(reservation.data).length > 0

      if (!reservationDataLoaded) {
         dispatch(clearReservation())
         navigateTo(base_domain + '/' + constants.routes.private.pre_checkin)
         return
      }

      const reservationGuests = Object.values(reservation.data._embedded.guests) as any[]
      const currentGuest = reservationGuests.find((reservationGuest) => {
         return reservationGuest.guest_id == guest.data?.id
      })
      if (currentGuest && currentGuest.status_pre_checkin == 0) {
         let params: any = {
            status_pre_checkin: 1,
            flow: SourceFlow,
            biometrics_sync_id: guest.auth.biometricsLogSyncId,
         }

         ApiCallService.ApiCall(
            params,
            `reservations/${reservation.data.id}/guests/${guest.data.id}`,
            'PUT',
            LocSettings
         )
            .then((res: any) => {
               dispatch(clearReservation())
               if(iframe_session_id) {
                  activateRegistrationSession(constants.status.iframe_register_session.success)
               }
               setLoading(false)
            })
            .catch((err) => {
               fireErrorAlert(
                  intl.formatMessage({id: 'checkin.confirmation.alert.error_finishing_precheckin'})
               )
               dispatch(clearReservation())
               navigateTo(base_domain + '/' + constants.routes.private.pre_checkin)
            })
      }
   }, [])

   console.log(`Loadingss: ${loading}`)
   console.log(`loadingRegistrationSession: ${loadingRegistrationSession}`)

   if (loading || loadingRegistrationSession) {
      return <LoadingPage />
   }

   let date_at = intl.formatMessage({id: 'dates.at'})

   const topMessage = `${intl.formatMessage({id: 'checkin.confirmation.your_reservation_at'})} ${
      LocSettings?.location.location_name
   } ${intl.formatMessage({id: 'common.on'})} ${moment(reservation?.data?.check_in).locale(getI18n()).format(
      `dddd, MMMM DD, YYYY [${date_at}] LT`
   )}.`
   const bottomMessage = intl.formatMessage({
      id: 'checkin.confirmation.you_may_receive_additional_notification',
   })
   return (
      <div className='d-flex justify-content-center h-100 pt-5 px-5 p-lg-20'>
         <div style={{maxWidth: '500px'}}>
            <div data-kt-stepper-element='content'>
               <div className='d-flex card-rounded flex-stack justify-content-center flex-column'>
                  <div className='pb-5'>
                     <div className='p-0 w-100 mw-500px text-center'>
                        <div className='pb-10 text-center d-flex flex-column'>
                           <div
                              ref={(el) =>
                                 el?.style.setProperty(
                                    'border-color',
                                    'rgb(80, 205, 137, 0.3)',
                                    'important'
                                 )
                              }
                              className='swal2-icon swal2-success'
                           >
                              <span
                                 ref={(el) =>
                                    el?.style.setProperty(
                                       'background-color',
                                       '#50cd89',
                                       'important'
                                    )
                                 }
                                 className='swal2-success-line-tip'
                              ></span>
                              <span
                                 ref={(el) =>
                                    el?.style.setProperty(
                                       'background-color',
                                       '#50cd89',
                                       'important'
                                    )
                                 }
                                 className='swal2-success-line-long'
                              ></span>
                           </div>
                        </div>
                        <p
                           style={{
                              paddingLeft: '15px',
                              paddingRight: '15px',
                              fontFamily: 'Poppins, Helvetica, sans-serif',
                           }}
                           className='fw-bold fs-6'
                        >
                           {' '}
                           {topMessage}{' '}
                        </p>
                        <p
                           style={{
                              paddingLeft: '15px',
                              paddingRight: '15px',
                              fontFamily: 'Poppins, Helvetica, sans-serif',
                           }}
                           className='fs-6'
                        >
                           {' '}
                           {bottomMessage}{' '}
                        </p>
                        <div className='mt-5 d-flex justify-content-center'>
                           <div className='mw-200px card'>
                              <button
                                 onClick={() => {
                                    navigateTo(base_domain)
                                 }}
                                 type='button'
                                 id='kt_sign_in_submit'
                                 className={`btn btn-lg btn-primary w-100 ${dynamicBtnColor}`}
                              >
                                 <span className='indicator-label'>
                                    <FormattedMessage
                                       id='common.return_to_home'
                                       defaultMessage='Return to home'
                                    />
                                 </span>
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
