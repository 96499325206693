import React, {FC, useEffect} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CustomButton from '../../../common/button/CustomButton'

const EmptyCartPlaceholder = () => {
  const intl = useIntl();

  return (
    <>
      <div className='row ' style={{height: '90vh'}}>
        <div className='my-auto text-center'>
          <p className='fs-3 fw-bold text-gray-900 mt-n20'><FormattedMessage id='olo.cart_is_empty' defaultMessage='Your cart is empty' /></p>
          <CustomButton title={intl.formatMessage({id:"common.back"})} extraClass={'w-100 mt-2'} id='kt_cartbar_close' />
        </div>
      </div>
    </>
  )
}

export default EmptyCartPlaceholder
