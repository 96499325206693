import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { AppDispatch, RootState } from "../store"
import { useEffect } from "react"
import * as ApiCallService from "../services/ApiCallService"
import { setFlow } from "../services/SourceSlice"
import { constants } from "../config/constants"
import { useErrorAlert } from "../utilities/alerts"
import { useIntl } from "react-intl"
import { LoadingPage } from "../modules/widgets/Loaders/LoadingPage"
import { setTable } from "../services/store/ticket"

export const TableRedirect = () => {
    const {location_id, table_id} = useParams()
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);

    const dispatch = useDispatch<AppDispatch>()
    const navigateTo = useNavigate()
    const fireErrorAlert = useErrorAlert()
    const intl = useIntl()

    useEffect(() => {
        dispatch(setTable({}))
        
        ApiCallService.ApiCall({}, `table/${table_id}/last_ticket`, 'GET', LocSettings)
            .then((res: any) => {
                if(res?.error?.message != undefined) {
                    fireErrorAlert(res.error.message)
                    navigateTo(`/${LocSettings.domain_name ?? location_id}`)
                } else {
                    if (res.data?.ticket?.url) {
                        dispatch(setFlow(constants.flow.public_receipt))
                        navigateTo(`/r/${res.data.ticket.url.split('/r/').pop()}`)
                    } else if(!LocSettings.order_in_store) {
                        fireErrorAlert(intl.formatMessage({id:"error.alert.no_ticket_available"}))
                        navigateTo(`/${LocSettings.domain_name ?? location_id}`)
                    } else {
                        dispatch(setTable(res.data.table))

                        dispatch(setFlow(constants.flow.order))
                        navigateTo(`/${LocSettings.domain_name ?? location_id}/olo/menu`)
                    }

                    console.log('data ', res.data)
                }
            }).catch((err:any) => {
                console.log('err ', err)
                fireErrorAlert(intl.formatMessage({id:"common.alert.error_sending_data"}))
                navigateTo(`/${LocSettings.domain_name ?? location_id}`);
            });
    }, [])

    return <LoadingPage />
}