import React, {Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState} from 'react'
import {useLocationBasedBtnColor, useLocationBasedTextBorderColor} from "../../../../utilities/helpers";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {useTextColor} from "../../../../services/hooks/useTextColor";
import * as ApiCallService from "../../../../services/ApiCallService";
import Swal, {SweetAlertResult} from "sweetalert2";
import {constants} from "../../../../config/constants";
import {useNavigate} from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';

interface StoreCard {
    displayStoreCard: boolean
    setDisplayStoreCard: Dispatch<SetStateAction<boolean>>
    submitNextStepHandle: any
}

export const StoredCard = ({ displayStoreCard, setDisplayStoreCard, submitNextStepHandle }:StoreCard) => {
    const intl = useIntl();
    const dynamicBtnColor = useLocationBasedBtnColor()
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    const SourceFlow = useSelector((state: RootState) => state.Source.flow);
    const guest = useSelector((state: any) => state.guest.data)
    const [storedCards,setStoredCards] = useState([])
    const [storedCardsTemp,setStoredCardsTemp] = useState([])
    const [cardIdSelected,setCardIdSelected] = useState(null)
    const confirmExtraCss = useLocationBasedTextBorderColor()
    const confirmMessage = (
        <p className='text-gray-800 py-5 mb-0 agree-terms-text form-label fs-6'>
            {' '}
            <FormattedMessage id='common.biometric_terms_condition' defaultMessage="
            By tapping the 'Agree & Confirm' button and providing SoftPoint with your biometric
            information, you consent and provide a written release to SoftPoint's collection, disclosure,
            use and storage of your biometric information and identifiers to facilitate and manage your
            clear application; operate and administer the SoftPoint programs; verify your identity; and
            comply with and enforce legal requirements and policies, as explained in SoftPoint's Privacy
            Policy." />
        </p>
    )
    const [textBgColor] = useTextColor();

    useEffect(() => {
        let data={
            guest_id:guest.id,
            flow: SourceFlow
        }

        ApiCallService.ApiCall(data, `/guests/${guest.id}/guest_cc_token`, 'GET', LocSettings)
            .then((response: any) => {
                if(response.data.length <= 0) {
                    setDisplayStoreCard(false);
                }
                setStoredCardsTemp(response.data);
            })
            .catch((err) => {
                setDisplayStoreCard(false);
            })

    }, [])
    useEffect(() => {
        let ret = storedCardsTemp;
        ret.forEach((element:any) =>{
            if(element.card_type.toLowerCase()=="visa")
                element.path="\\media\\cards\\visa.png";
            else if (element.card_type.toLowerCase()=="mastercard")
                element.path="\\media\\cards\\mc.png";
            else if (element.card_type.toLowerCase()=="discover")
                element.path="\\media\\cards\\discover.png";
            else if (element.card_type.toLowerCase()=="amex")
                element.path="\\media\\cards\\amex.png";
            else
                element.path="\\media\\cards\\no_card.png";
        })
        setStoredCards(ret)

    }, [storedCardsTemp])

    const addNewPaymentButton = ()=>{
        setDisplayStoreCard(!displayStoreCard);
    }



    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger btn-sm fs-5',
        },
        buttonsStyling: false,
        icon: 'error',
        background: LocSettings.appearance === 1 //if 1 or light mode set white background
            ? '#fffff'
            : '#151521',
        confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
        width: '29rem',
        heightAuto: false,
    })
    const fireErrorMessage = async (
        _errorMessage: string
    ): Promise<SweetAlertResult<ReactElement<any, any>>> =>
        swalWithBootstrapButtons.fire({
            html: `<h6 class="swal2-html-container fs-5 mx-0 gray-800-dark">${_errorMessage}</h6>`,
        })


    const handleContinueSubmit = () => {
        if(cardIdSelected === null) {
            fireErrorMessage(intl.formatMessage({id:"checkin.stored_card.alert.select_card"}))
        } else {
            submitNextStepHandle()
        }
    }

    return (
        <>
            <div className='d-flex flex-column w-100'>
                <div className='w-100'>
                    {storedCards.length > 0 && (
                        storedCards.map((element: any) => {
                            return (
                                <>
                                    <label style={{borderColor:'black'}} className={`btn btn-outline btn-outline-dashed btn-active-primary d-flex text-start p-3 mb-3 ${cardIdSelected == element.id ? 'active' : ''}`} onClick={() => {setCardIdSelected(element.id)}}>
                                        <input className="btn-check" type="radio" name="project_type" value="1" />
                                        <span className="d-flex">
                                            <span className="svg-icon svg-icon-3hx">
                                                <img style={{ width: '50px' }} className="card-img-top" src={element.path} alt="Card image cap" />
                                            </span>
                                            <span className="ms-4">
                                                <span className="fs-6 form-label mb-2 d-block ">{element.card_type} &nbsp; **** &nbsp;{element.last4}</span>
                                                <span className="fw-semibold fs-7 text-muted " >{element.card_holder} &nbsp; &nbsp;   {element.exp_month} / {element.exp_year}
                                                </span>
                                            </span>
                                            {element.default === 1 ? <span className='badge  h-20px ms-5 mb-0 mt-0' style={{ backgroundColor: LocSettings.appearance === 1 ? '#f1faff' : '#212e48', color: "#3699ff" }}><FormattedMessage id='myaccount.cards.default' defaultMessage='Default' /></span> : <></>}
                                        </span>
                                    </label>

                                </>
                            )
                        })
                    )}

                </div>
                <div className='w-100 mt-2'>
                    <button id='PayNowButton' onClick={addNewPaymentButton} type='button'

                            className={`btn btn-lg btn-primary w-100  ${confirmExtraCss}`}
                    >
                        <span className={`indicator-label ${dynamicBtnColor}`}><FormattedMessage id='common.add_payment_method' defaultMessage='Add Payment Method' /></span>
                    </button>

                </div>
                <div className='text-muted d-flex flex-justify-center flex-column'>
                    {confirmMessage}
                    <div className='w-100'>
                        <button onClick={handleContinueSubmit} type='button'
                                className={`btn btn-lg btn-primary w-100  ${confirmExtraCss}`}>
                            <span className={`indicator-label ${dynamicBtnColor}`}><FormattedMessage id='common.agree_confirm' defaultMessage='Agree & Confirm' /></span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
