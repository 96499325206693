import { createSlice } from '@reduxjs/toolkit'

export interface GuestCCTokensState {
    isLoading: boolean,
    data: object,
}

const initialState: GuestCCTokensState = {
    isLoading: false,
    data: {},
}

export const guestCCTokensSlice = createSlice({
    name: 'guest_cc_tokens',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setGuestCCTokens: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setGuestCCTokens } = guestCCTokensSlice.actions
