/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCartButtonDisplay, setCartItemAddedMsg } from '../../../app/services/store/ordering/cart/CartButtonSlice';
import { AppDispatch, RootState } from '../../../app/store';
import { useLocationBasedBtnColor, useSVGHeaderCartTextColorBasedPCLoction } from '../../../app/utilities/helpers';
import { isMobileDevice } from '../../assets/ts/_utils';
import { KTSVG } from '../../helpers';
import { LazyLoadImg } from './LazyLoadImg';
import './ScrollTop.scss';

const Footer: FC = () => {
  const dispatch = useDispatch<AppDispatch>(); 
  const svgStyleColor = useSVGHeaderCartTextColorBasedPCLoction()
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)

  const cartBtnDisplay = useSelector((state: RootState) => state.cartButton.cartButtonDisplay)
  const cartItemAddedMsg = useSelector((state: RootState) => state.cartButton.cartItemAddedMsg)
  const cartItems = useSelector((state: RootState) => state.cartItems.data)
  const [timerEnds, setTimerEnds] = useState<boolean>(true)
  let location = useLocation()
  let pathName = location.pathname
  const dynamicBtnColor = useLocationBasedBtnColor();
 
useEffect(() => {
  handleWindowResize();
},[window.location.pathname])
  const handleWindowResize = () => {
    if (window.location.pathname.includes('/olo/')) {
      if (window.innerWidth <= 480) {
        dispatch(setCartButtonDisplay(false));
      } else {
        dispatch(setCartButtonDisplay(false));
      }
    }else{
      dispatch(setCartButtonDisplay(false));
    }

  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize()

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[])
  
  let footerLogoPath = LocSettings.appearance === 0 ? '/media/logos/softpoint_logo_app_white.svg' : '/media/logos/lightModeFullSoftPointLogo.svg'

  useEffect(()=>{
    if(cartItemAddedMsg!=null)
    {
      setTimerEnds(false)
      setTimeout(()=>{
        setTimerEnds(true)
        dispatch(setCartItemAddedMsg(null))
      },4000)
    }

  },[cartItemAddedMsg])

  const checkIfOloPage = () => {
    return window.location.pathname.indexOf('olo') > -1;
  }

  return (
<>
      {cartBtnDisplay ?(
        <>
        <div  style={{ height: '60px', position:'fixed', bottom:'0px', zIndex:'99'}} className={` ${cartBtnDisplay?LocSettings.appearance==0?'bg-dark':'bg-white':'bg-transparent'} w-100 footer d-flex flex-lg-column mt-2`} id='kt_footer'>
        <div className={` d-flex flex-column align-items-center justify-content-end  my-auto mx-auto`}>
        
          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
            <li className=''>  
            <h4 className='my-auto' style={{fontWeight: 'normal'}}>
                      <div className='w-100 bg-white py-10'>

                        <button id='kt_cbar_mobile_toggle' className={`btn mb-2 btn-footer-cart btn-primary ${cartItemAddedMsg != null?'d-none':''}`}>
                        <KTSVG
                              path='/media/icons/duotune/ecommerce/ecm012.svg'
                              className={`svg-icon d-block mb-lg-0 ${svgStyleColor}`}
                              svgClassName="cartIconFooterBtn"
                            />
                            
                            <span className={`fs-3 ${dynamicBtnColor}`}><FormattedMessage id='footer.view_cart' defaultMessage='View Cart'/></span>
                            {
                              cartItems.length > 0 &&  <span className={`${cartItems.length > 99 ? 'custom-item-count-3-digit' :'custom-item-count-2-digit'} mt-1 ms-1 float-end`}>
                              <span>{cartItems.length}</span>
                            </span>
                            }
                           
                            {/* <i style={{float:'right', fontSize:'20px'}} className={`${dynamicBtnColor}  bi bi-${cartItems.length}-circle`}></i> */}
                        </button>
                        <button id='kt_cbar_info' className={` btn-footer-cart mb-2 btn btn-danger ${cartItemAddedMsg != null && timerEnds !=true?'':'d-none'}`}>
                        <i style={{float:'left', fontSize:'20px'}} className={`bi bi-check-lg`}></i>
                        {cartItemAddedMsg}
                        {
                              cartItems.length > 0 &&  <span className={`${cartItems.length > 99 ? 'custom-item-count-3-digit' :'custom-item-count-2-digit'} mt-1 ms-1 float-end`}>
                              <span>{cartItems.length}</span>
                            </span>
                            }
                        {/* <i style={{float:'right', fontSize:'20px'}} className={`bi bi-${cartItems.length}-circle`}></i> */}
                        </button>
                        </div>
                    </h4>            
            </li>
          </ul>
        </div>
      </div>
        </>
      ):<></>
      }
        <div  style={{ height: '60px', backgroundColor:'inherit'}} className={`footer d-flex flex-lg-column mx-auto`} id='kt_footer'>
        <div className={checkIfOloPage() ? `d-flex container-fluid` : ` d-flex flex-column justify-content-end  my-auto mx-auto`}>
          {/* flex-md-row */}
          {/* Nav */}
          <ul className='menu menu-gray-600 fw-bold order-1 justify-content-between just align-items-center w-100'>
            <li className=''>
              <h4 className='my-auto' style={{fontWeight: 'normal'}}>
                <a id='footerLink' className='' href="https://www.softpointcloud.com/" rel="noopener noreferrer" target="_blank">
                <FormattedMessage id='sidebar.powered_by' defaultMessage='Powered By'/> <img style={{ imageRendering: 'auto'}} src={footerLogoPath} className="w-100px h-30px" alt="" />
                </a>
              </h4>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export {Footer}