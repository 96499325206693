import { createSlice } from '@reduxjs/toolkit';

export interface ActiveMenuCategory {
    isLoading: boolean,
    data: {}
}

const initialState: ActiveMenuCategory = {
    isLoading: false,
    data: {}
}

export const activeMenuCategorySlice = createSlice({
    name: 'activeMenuCategory',
    initialState,
    reducers: {
        startLoading: (state) => { //When exporting current error: 
            state.isLoading = true; //Contains conflicting star exports for the name 'startLoading' with the previous requested module './menuCategoriesSlice'
        },
        setActiveCat: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
    },
});

export const { setActiveCat } = activeMenuCategorySlice.actions;