import { RefAttributes } from "react";
import { OverlayTrigger, Tooltip, TooltipProps } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/esm/OverlayTrigger";
  
type ContainerProps = {
    children: React.ReactElement<OverlayInjectedProps> ;
    title:string
  };

  const TooltipComponent = (props: ContainerProps) => {
    const renderTooltip = (title:string) => (
        <Tooltip id="button-tooltip" style={{position:"fixed"}}>
          {title}
        </Tooltip>
      );

    return (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(props.title)}
        >
          {props.children}
        </OverlayTrigger>
      );
  
  }
 
  
  export default TooltipComponent;