import * as ApiCallService from "../../../../ApiCallService";
import { setTaxRates, startLoading } from "./taxRatesSlice";

export const getTaxRates = (LocSettings: ApiCallService.IAPILocSettings) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
        dispatch(startLoading())
        ApiCallService.ApiCall({}, `tax_rates`, 'GET', LocSettings)
            .then((res: any) => {
                if(res?.error?.message != undefined) {
                    console.log(res.error.message)
                } else {
                    dispatch( setTaxRates(res.data?.data) )
                }
        }).catch((err: any) => {
            console.log(err)
        });
    }
}
 