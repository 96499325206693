import React, { useContext, useEffect } from 'react'
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import CustomButton from '../../button/CustomButton';
import { HeaderTitleContext } from '../../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore';
import { constants } from '../../../config/constants';
import { FormattedMessage, useIntl } from 'react-intl';

export const EnableCameraInstructionsAndroid = () => {
    const intl = useIntl();
    let navigateTo = useNavigate()
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const { setPageTitle } = useContext(HeaderTitleContext);

    useEffect(() => {
        setPageTitle(intl.formatMessage({id:constants.staticPageTitle.camera_permissions}));
    },[])
    return (
        <div className='d-flex flex-center flex-column-fluid  mt-n5'>
            <div
                className='w-100 card mx-20px mt-20px mw-lg-500px'
            >
                <div className="row p-5">
                    <div className="col-12">
                        <h1><FormattedMessage id='camera.overlays.android.follow_next_steps' defaultMessage='Follow the next steps to enable your camera again' /></h1>
                    </div>

                    <div className="col-12 mt-5">
                        <h1><FormattedMessage id='camera.overlays.android.click_on_padlock_icon' defaultMessage='1. Click on "padlock" icon'/></h1>
                        <img src={require('./images/android_turn_on_camera_01.png')} className="img-fluid"></img>
                    </div>

                    <div className="col-12 mt-5">
                        <h1><FormattedMessage id='camera.overlays.android.click_on_permissions' defaultMessage='2. Click on permissions'/></h1>
                        <img src={require('./images/android_turn_on_camera_02.png')} className="img-fluid"></img>
                    </div>

                    <div className="col-12 mt-5">
                        <h1><FormattedMessage id='camera.overlays.android.turn_on_camera_permission' defaultMessage='3. Turn on the camera permission'/></h1>
                        <img src={require('./images/android_turn_on_camera_03.png')} className="img-fluid"></img>
                    </div>

                    <div className="col-12 mt-5">
                        <h1><FormattedMessage id='camera.overlays.android.click_on_continue_button' defaultMessage='4. Click on "Continue" button'/>.</h1>
                        <CustomButton title='Continue' extraClass='mt-5 w-100'  onClick={() => {navigateTo(`${LocSettings.base_domain}/auth`)}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
