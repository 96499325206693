import { useEffect } from 'react';

export const useKeyboardScrollLock = (isFocused: boolean) => {
  useEffect(() => {
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport && !isFocused) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0 user-scalable=no');
    }
  }, [isFocused]);
};
