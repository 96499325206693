import React, {useEffect} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {constants} from "../../../config/constants";
import { setFlow } from '../../../../app/services/SourceSlice';
import { useDispatch, useSelector } from 'react-redux'
// @ts-ignore
import Cookies from 'js-cookie'
import {
    setRedirectGuestId,
    setRegistrationID,
    setSessionGuestId,
} from "../../../services/store/registration/RegistrationSlice";
import {RootState} from "../../../store";
import {locationHasBiometricsOn, locationHasCOFOn} from "../../../common/core/BusinessLogic";

export const IdMeCallbackRedirect = () => {
    const navigateTo = useNavigate()
    const StoreDispatch = useDispatch();
    let [searchParams] = useSearchParams();
    const idme_code = searchParams.get('code');
    const {cookies_name} = constants;
    const guestData:any = useSelector((state:RootState)=>state.guest.data)
    const location_domain_name = Cookies.get(cookies_name.location_domain_name)
    const sourceFlow = Cookies.get(cookies_name.sourceFlow)
    const registrationID = guestData._embedded.registration.id

    useEffect(() => {

        StoreDispatch(setFlow(sourceFlow));
        StoreDispatch(setSessionGuestId(guestData.id))
        StoreDispatch(setRegistrationID(registrationID))

        navigateTo( `/${location_domain_name}/idme/callback?code=${idme_code}`);
    }, []);

    return (
        <>
            <h1>
                {/* IdMe Validation */}
            </h1>
        </>
    )
}
