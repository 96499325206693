import {useDispatch} from 'react-redux'
import {
   resetOrder,
   setGuestCardDetails,
   setGuestDraftCardDetails,
} from '../../../services/store/ordering/GuestOrder/OrderSlice'
import {resetCart} from '../../../services/store/ordering/cart'
import {resetCartItemToUpdate} from '../../../services/store/ordering/cart/updateCartItemSlice'
import {resetCartButton} from '../../../services/store/ordering/cart/CartButtonSlice'
import moment from 'moment'

export interface IResetOrderHook {
   sessionDuration?: number
   resetType: 'Nullify' | 'Timer'
}

type IResetCallback = (locationId?: any) => void

/**
 * The hook consolidates all order actions that need to happen in order to reset an order session.
 * The hook will return two types of callbacks one that clears order data and resets the expiration timestamp on the order session.
 * And another that will just clear out all order data and not reset the expiration timestamp.
 * @param resetConfigs A configuration object, with two settings.
 * The property sessionDuration, is used to calculate the expiration timestamp that will be stored.
 * @returns a callback that can be stored on a variable to be invoked at a desired time.
 */
export const useResetOrder = ({
   sessionDuration = 5,
   resetType,
}: IResetOrderHook): IResetCallback => {
   const appDispatch = useDispatch()

   if (resetType == 'Nullify') {
      return () => {
         appDispatch(resetOrder(null))
         appDispatch(resetCart())
         appDispatch(resetCartItemToUpdate())
         appDispatch(resetCartButton())
         appDispatch(setGuestCardDetails(null))
         appDispatch(setGuestDraftCardDetails(null))
      }
   } else {
      return (locationId: any) => {
         const expirationStamp = moment().utc().add(sessionDuration, 'minutes').format()
         appDispatch(
            resetOrder({
               location_id: locationId,
               expiration_datetime: expirationStamp,
            })
         )
         appDispatch(resetCart())
         appDispatch(resetCartItemToUpdate())
         appDispatch(resetCartButton())
         appDispatch(setGuestCardDetails(null))
         appDispatch(setGuestDraftCardDetails(null))
      }
   }
}
