import {setGuestCCTokens, startLoading} from "./guestCCTokensSlice";
import * as ApiCallService from "../../../ApiCallService";

export const getGuestCCToken = (guest_id: number, LocSettings: any,Calltype:string="") => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())

        let data={guest_id:guest_id,req_type:Calltype};        
        ApiCallService.ApiCall(data, `guests/${guest_id}/guest_cc_token`, 'GET', LocSettings)
            .then((res: any) => {
                dispatch( setGuestCCTokens(res.data) )
            }).catch((err) => {
            dispatch( setGuestCCTokens({}) )
        });
    }
}
