import React, {useEffect} from 'react'
import {AppDispatch, RootState} from '../../../store'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {capitalizeWords} from '../../../services/hooks/useCapitalizeWords'
import {setSelectedCategorySectionId} from '../../../services/store/ordering/menu/categories/selectedCategorySectionIdSlice'
import StaticRoutePath from '../../../routing/StaticRoutePath'
import {setCartButtonDisplay} from '../../../services/store/ordering/cart/CartButtonSlice'
import {FormattedMessage} from 'react-intl'
export interface IProductSectionProps {
  catId: number
  title: string
  totalItems: number
  categorySectionId: string
}

const MenuCategoryHeader: React.FunctionComponent<IProductSectionProps> = ({
  catId,
  title,
  totalItems,
  categorySectionId,
}) => {
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  const base_domain = LocSettings.base_domain
  const dispatch = useDispatch<AppDispatch>()
  let navigateTo = useNavigate()
  const capitalizedWord = capitalizeWords(title)

  const handleclick = () => {
    dispatch(setSelectedCategorySectionId('section-0'))
    // dispatch(setCartButtonDisplay(false))
    navigateTo(base_domain + StaticRoutePath.OLO_ITEM_VIEW_ALL, {state: {catId, categorySectionId}})
  }
  return (
    <>
      <section className='root-product my-5'>
        <header className='product-header px-0'>
          <div className='_headings_16lwz1'>
            <h2 className='product-title fs-3'>{capitalizedWord}</h2>
            <p className='product-subheader'>({totalItems})</p>
          </div>
          <span onClick={handleclick} className='view-link'>
            <FormattedMessage id='olo.view_all' defaultMessage='View All' />
          </span>
        </header>
      </section>
    </>
  )
}

export {MenuCategoryHeader}
