import {HorizontalRuler} from "./HorizontalRuler";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import { FormattedMessage } from "react-intl";
import { constants } from "../../../config/constants";

export interface IServiceCharge{
    name:string
    price:number
}
export interface ITaxBreakDown{
    name:string
    amount:number
}
export interface IReceiptTotals{
    due: any;
    sub_total:number,
    tax:number,
    service_charges:number,
    surcharges:number,
    tips:number,
    total:number,
    other_charges:number
    service_charges_list?:[IServiceCharge]
    inclusive_tax?:number
    tax_break_down?:[ITaxBreakDown]
}
export const ReceiptTotals = ({props}:{props:IReceiptTotals}) => {
    const ticket:any = useSelector((state: RootState) => state.ticket);
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);        
    return(
        <table border={0}  cellPadding="5" cellSpacing="0" width="100%" id="receiptTotalsTable">
            <tbody>
            <tr>
                <td colSpan={4}>
                    <div className="separator separator-dashed my-1"></div>
                </td>
            </tr>
            <tr>
                <td className='text-start text-gray-700 fw-semibold fs-6 '><FormattedMessage id='profile.receipt.subtotal' defaultMessage='Subtotal' /></td>
                <td/>
                <td className='text-end'/>
                <td className='text-end text-gray-700 fw-bold fs-6' id="sub_total">${((props.inclusive_tax ? props.sub_total - props.inclusive_tax : props.sub_total)/100.00).toFixed(2)}</td>
            </tr>
            {
                LocSettings.tax_break_down==1 && props.tax_break_down && props.tax_break_down?.length>0?(
                    props.tax_break_down.map((taxItem, index) => (
                        <tr key={index}>
                            <td className='text-start text-gray-700 fw-semibold fs-6'>
                                {taxItem.name} {/* Adjust the property as per your data structure */}
                            </td>
                            <td/>
                            <td className='text-end'/>
                            <td className='text-end text-gray-700 fw-bold fs-6'>
                                ${((taxItem.amount || 0) / 100.0).toFixed(2)} 
                            </td>
                        </tr>
                    ))
                )
                :<tr>
                    <td className='text-start text-gray-700 fw-semibold fs-6 '><FormattedMessage id='profile.receipt.tax' defaultMessage='Tax'/></td>
                    <td/>
                    <td className='text-end'/>
                    <td className='text-end text-gray-700 fw-bold fs-6' id="tax">${((props.inclusive_tax ? props.tax + props.inclusive_tax : props.tax)/100.0).toFixed(2)}</td>
                </tr>
            }
            
            {
                LocSettings.location.receipt_pos_payments==1 && props.service_charges>0?
                <tr>
                    <td className='text-start text-gray-700 fw-semibold fs-6 '>{LocSettings?.location?.service_charges_name!=""?LocSettings?.location?.service_charges_name:'Service Charges'}</td>
                    <td/>
                    <td className='text-end'/>
                    <td className='text-end text-gray-400 fw-bold fs-6' id="tax">${(props.service_charges/100.00).toFixed(2)}</td>
                </tr>:''
            }
            { LocSettings.location.receipt_pos_payments == 2 && props.service_charges > 0 && props.service_charges_list && props.service_charges_list.map( (service_charge, i) => 
                <tr key={i}>
                    <td colSpan={3} className='text-start text-gray-700 fw-semibold fs-6 '>{ service_charge.name ?? 'Service Charge'}</td>
                    <td className='text-end text-gray-700 fw-bold fs-6'>${(service_charge.price/100.00).toFixed(2)}</td>
                </tr>
            )}

                {
                    // !ticket?.data?.totals?.donations || '0.00' // falsy_values: 0 (zero), "" (empty string), null, undefined, NaN (Not a Number).
                    !ticket?.data?.totals?.donations || ticket?.data?.totals?.donations<=0 
                        ?<></>
                        : <tr>
                            <td className='text-start text-gray-700 fw-semibold fs-6'><FormattedMessage id='profile.receipt.donation' defaultMessage='Donations' /></td>
                            <td />
                            <td className='text-end' />
                            <td className='text-end text-gray-400 fw-bold fs-6'>${(ticket?.data?.totals?.donations != undefined && ticket?.data?.totals?.donations > 0 ? ticket.data.totals.donations / 100.00 : 0).toFixed(2)}</td>
                        </tr>
                }
            
            <tr>
                <td className='text-start text-gray-700 fw-semibold fs-6 '><FormattedMessage id='profile.receipt.tip' defaultMessage='Tip' /></td>
                <td/>
                <td className='text-end'/>
                <td className='text-end text-gray-700 fw-bold fs-6' id="tips">${(props.tips/100.00).toFixed(2)}</td>
            </tr>
            {
                props.surcharges>0?
                <tr>
                    <td className='text-start text-gray-700 fw-semibold fs-6 '>
                        {
                            (LocSettings?.location?.service_charges_name != undefined && LocSettings?.location?.service_charges_name != '') ?
                                LocSettings?.location?.service_charges_name
                                :
                                <FormattedMessage id='profile.receipt.surcharge' defaultMessage='Surcharge' />
                        }
                        </td>
                    <td/>
                    <td className='text-end'/>
                    <td className='text-end text-gray-400 fw-bold fs-6' id="tips">${(props.surcharges/100.00).toFixed(2)}</td>
                </tr>:""
            }            
            <tr>
                <td colSpan={4}>
                    <div className="separator separator-dashed my-1"></div>
                </td>
            </tr>
            <tr >
                <td className='text-start fw-semibold fs-6'><FormattedMessage id='profile.receipt.total' defaultMessage='Total' /></td>
                <td/>
                <td/>
                <td className='text-end fw-bold fs-6' id="grand_total">${(ticket.data.totals.grand_total/100.00).toFixed(2)}</td>
            </tr>
            </tbody>
        </table>
    )
}
