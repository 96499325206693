import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface StepTracker {
    step: number,
    uploadSelfie: boolean,
    uploadFormId: boolean,
    unableToUseBiometricsLogin: boolean,
}

const initialState: StepTracker = {
    step: 0,
    uploadSelfie: false,
    uploadFormId: false,
    unableToUseBiometricsLogin: false,
}

export const stepTrackerSlice = createSlice({
    name: 'StepTracker',
    initialState,
    reducers: {
        setStepTracker: (state, action: PayloadAction<any>) => {
            state.step = action.payload
        },
        setUpLoadSelfie: (state, action: PayloadAction<any>) => {
            state.uploadSelfie = action.payload
        },
        setUpLoadFormId: (state, action: PayloadAction<any>) => {
            state.uploadFormId = action.payload
        },
        setUnableToUseBiometricsLogin: (state, action: PayloadAction<any>) => {
            state.unableToUseBiometricsLogin = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setStepTracker,
    setUpLoadSelfie,
    setUpLoadFormId,
    setUnableToUseBiometricsLogin
} = stepTrackerSlice.actions

export default stepTrackerSlice.reducer
