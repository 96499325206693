import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";

export interface IAuthModuleSlice{
    base_64_images:{
        auth_selfie:string,
        auth_thumbnail_selfie:string
    }
}


const initialState:IAuthModuleSlice = {
    base_64_images:{
        auth_selfie:"",
        auth_thumbnail_selfie:""
    }
}

export const AuthModuleSlice = createSlice({
    name:'AuthModule',
    initialState,
    reducers:{
        setAuthSelfie: (state, action:PayloadAction<string>) => {
            state.base_64_images.auth_selfie = action.payload
        },
        setAuthSelfieThumbnail: (state, action:PayloadAction<string>)=>{
            state.base_64_images.auth_thumbnail_selfie = action.payload
        },
        clearAuthSelfies:(state) => {
            state.base_64_images.auth_selfie = ''
            state.base_64_images.auth_thumbnail_selfie = ''
        }
    }
})

export const {
    setAuthSelfie,
    setAuthSelfieThumbnail,
    clearAuthSelfies
} = AuthModuleSlice.actions
