import {useReducer} from "react";

export enum AuthViewTypes{
    BIOMETRICS = 'BIOMETRICS',
    PHONE = 'PHONE',
    CODE = 'CODE',
    CHOOSE_GUESTS = 'CHOOSE_GUESTS',
}

export interface changeAuthViewAction{
    type:AuthViewTypes
}

export interface authViewState{
    displayBioView:boolean,
    displayPhoneView:boolean,
    displayCodeView:boolean,
    displayChooseGuest:boolean,
}

const renderReducer = (currentState:authViewState, action:changeAuthViewAction) => {
    const {type} = action
    switch (type){
        case AuthViewTypes.BIOMETRICS:
            return{
                displayBioView:true,
                displayPhoneView:false,
                displayCodeView:false,
                displayChooseGuest:false,
            }
        case AuthViewTypes.PHONE:
            return{
                displayBioView:false,
                displayPhoneView:true,
                displayCodeView:false,
                displayChooseGuest:false,
            }
        case AuthViewTypes.CODE:
            return{
                displayBioView:false,
                displayPhoneView:false,
                displayCodeView:true,
                displayChooseGuest:false,
            }
        case AuthViewTypes.CHOOSE_GUESTS:
            return{
                displayBioView:false,
                displayPhoneView:false,
                displayCodeView:false,
                displayChooseGuest:true,
            }
        default:
            return currentState
    }
}

const initState:authViewState = {
    displayBioView:true,
    displayPhoneView:false,
    displayCodeView:false,
    displayChooseGuest:false,
}

export const useAuthViewReducer = () => {
    return useReducer(renderReducer, initState)
}
