import * as Yup from 'yup';
import { useSelector } from 'react-redux';

// const reservation = useSelector((state: any) => state.reservation);
// const reservation_data = reservation.data;
// console.log(reservation)

export interface ICreateAccount {
  accountType: string
  birthdate: string
  phoneNumber: string
  addGuestFirstName:string
  addGuestLastName:string
  nameOnCard: string
  cardNumber: string
  cardExpiryMonth: string
  cardExpiryYear: string
  cardCvv: string
  cardZipCode:number | string
  saveCard: string
}

{/* errors are only needed for newly added guests since the guestList already checks for blank fields in the dashboard */ }
const createAccountSchemas = [
  Yup.object({
    accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    birthdate: Yup.string().label('Birthdate'),
    phoneNumber: Yup.string().label('Phone number')
  }),
  Yup.object({
    addGuestFirstName: Yup.string().matches(/^[aA-zZ\s\-\/.]+$/, "Invalid First Name").label('First name'),
    addGuestLastName: Yup.string().matches(/^[a-zA-Z\s\-\/.]+$/, 'Invalid Last Name').label('Last name')
  }),
  // add validation in regex for all fields Deadline oct-17
  Yup.object({
    nameOnCard: Yup.string().required().label('Name on card'),
    cardNumber: Yup.string().required().label('Card number'),
    cardExpiryMonth: Yup.string().required().label('Expiration month'),
    cardExpiryYear: Yup.string().required().label('Expiration year'),
    cardCvv: Yup.string().required().label('CVV'),
    cardZipCode: Yup.string().required().label('Zip code'),
  }),
]

const inits: ICreateAccount = {
  accountType: 'reservation',
  birthdate: '',
  phoneNumber:'',
  addGuestFirstName:'',
  addGuestLastName:'',
  nameOnCard: '',
  cardNumber: '',
  cardExpiryMonth: '',
  cardExpiryYear: '',
  cardCvv: '',
  cardZipCode: '',
  saveCard: '',
}

export {createAccountSchemas, inits}
