import React, {FC, useEffect} from 'react'
import {PageTitle} from '../../../_spc/layout/core'
import {MainMenuView} from './MainMenuView/MainMenuView'
// @ts-ignore
import Cookies from 'js-cookie'
import {constants} from '../../config/constants'
import {useLocation, useNavigate} from 'react-router-dom'
import {AppDispatch, RootState} from '../../store'
import {useDispatch, useSelector} from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getTaxRates } from '../../services/store/ordering/menu/tax_rates'
import {setLogOut} from "../../services/store/guests";
const MainMenuController: FC = () => {
  const {cookies_name} = constants
  const {pathname} = useLocation()
  let navigateTo = useNavigate()
  let IsOnlineOrder = false
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  const taxRatesStore = useSelector((state: RootState) => state.taxRates)
  let guest = useSelector((state: RootState) => state.guest)
  const dispatch = useDispatch<AppDispatch>()

  if (LocSettings.olo.allow_ordering == true) {
    IsOnlineOrder = true
  }

  useEffect(() => {
    if(guest.isLoading == false && guest.auth.isAuthenticated != true && Object.keys(guest.data).length > 0) {
      dispatch(setLogOut())
    }

    let base_domain = Cookies.get(cookies_name.location_domain_name)
    if (base_domain && base_domain.trim() === '') {
      base_domain = LocSettings.location.id
    }

    if (pathname.split('/')[2] == 'menu' || pathname.split('/')[3] == 'menu') {
      console.log('LocSettings.olo.allow_ordering', LocSettings.olo.allow_ordering)
      !IsOnlineOrder && navigateTo(`/${base_domain}/`)
    }

    if(!taxRatesStore.isLoading && taxRatesStore.data.length == 0)
    {
      dispatch(getTaxRates(LocSettings));
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}><FormattedMessage id='olo.order_categories' defaultMessage='Order Categories'/></PageTitle>
      <MainMenuView />
    </>
  )
}

export default MainMenuController
