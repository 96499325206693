import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface ICodeActivationSteps {
    code_is_valid:boolean,
    guest_authenticated:boolean,
    confirmation:boolean,
}

export interface ICodeActivation {
    code: string|null,
    steps:ICodeActivationSteps,
    isLocked:boolean
}

const initialState: ICodeActivation = {
    code: null,
    steps:{
        code_is_valid:false,
        guest_authenticated:false,
        confirmation:false,
    },
    isLocked:false
}

export const CodeActivationSlice = createSlice({
    name: 'ActivateCode',
    initialState,
    reducers: {
        setActivateCode: (state, action:PayloadAction<string|null>) => {
            state.code = action.payload
        },
        setCodeVerifiedStepDone: (state) => {
            state.steps.code_is_valid = true
        },
        setGuestAuthenticatedStepDone: (state) => {
            state.steps.guest_authenticated = true
        },
        setConfirmationStepDone: (state) => {
            state.steps.confirmation = true
        },
        setActivationLock:(state, action:PayloadAction<boolean>)=>{
            state.isLocked = action.payload
        },
        resetKioskCheckinState: (state) => {
            state.code = null
            state.steps.code_is_valid = false
            state.steps.guest_authenticated = false
            state.steps.confirmation = false
            state.isLocked = false
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setActivateCode,
    setCodeVerifiedStepDone,
    setGuestAuthenticatedStepDone,
    setConfirmationStepDone,
    resetKioskCheckinState,
    setActivationLock} = CodeActivationSlice.actions
