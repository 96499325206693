import {combineReducers, configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import AppSettingsSlice from './services/AppSettingsSlice'
import UsersDataReducer from './services/UsersDataSlice'
import SourceReducer from './services/SourceSlice'
import {reservationSlice} from "./services/store/reservations";
import { stepTrackerSlice } from './services/PhoneAuthStepTracker';
import {guestSlice} from "./services/store/guests";
import {globalSettingsSlice} from "./services/store/globalSettings";
import {guestCCTokensSlice} from "./services/store/guests/credit_card_tokens";
import {ticketSlice} from "./services/store/ticket";
import {modticketSlice} from "./services/store/mod_ticket";
import { sidebarSlice } from './services/store/sidebar';
import { paymentSlice } from './services/store/ticket/payment';
import {registrationSlice} from "./services/store/registration/RegistrationSlice";
import {ScanDataSlice} from "./services/store/registration/ScanDataSlice";
import {refererSlice} from "./services/store/referer";
import { menuModifiersSlice } from './services/store/ordering/menu/modifiers';
import { menuModifierGroupsSlice } from './services/store/ordering/menu/modifiers_groups';
import { menuCategoriesSlice } from './services/store/ordering/menu/categories';
import { CodeActivationSlice } from "./services/store/checkin/kiosk_checkin/codeActivationSlice";
import { activeMenuCategorySlice } from './services/store/ordering/menu/categories';
import { itemDetailsSlice } from './services/store/ordering/item/itemDetailsSlice';
import { locationStateSlice } from './services/store/location_state/locationStateSlice';
import { selectedCategorySectionIdSlice } from './services/store/ordering/menu/categories/selectedCategorySectionIdSlice';
import {orderSlice} from "./services/store/ordering/GuestOrder/OrderSlice";
import {cartSlice} from "./services/store/ordering/cart/cartSlice";
import {cartButtonSlice} from "./services/store/ordering/cart/CartButtonSlice";
import {updateCartItemSlice} from "./services/store/ordering/cart/updateCartItemSlice"
import { LocationSchedualSlice } from './services/store/LocationSchedual';
import { selectedItemModifireInfoSlice } from './services/store/ordering/modifire/selectedItemModifireInfoSlice';
import {AuthModuleSlice} from "./services/store/AuthModuleSlice";
import { taxRatesSlice } from './services/store/ordering/menu/tax_rates/taxRatesSlice';
 
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
      'LocSettings', 'guest', 'guest_cc_tokens', 'Source', 'ticket', 'payment',  'referer','menuCategories',
    'checkingKioskCheckin', 'UsersData', 'activeMenuCategory', 'reservation', 'locationState', 'cartItems','cartItemIdToUpdate',
    'order','sidebarActiveMenu','locationSchedual','selectedItemModifireInfo', 'globalSettings', 'AuthModule'
  ]
}

const registrationTrackerConfig = {
  key: 'registrationSessionTracker',
  storage,
  whitelist: ['redirect_guest_id', 'guest_id', 'check_list', 'registration_id', 'ocr_data', 'pdf417_data', 'biometrics', 'cvv_stored_token']
}

const rootReducer = combineReducers({
  LocSettings: AppSettingsSlice,
  reservation: reservationSlice.reducer,
  guest: guestSlice.reducer,
  guest_cc_tokens: guestCCTokensSlice.reducer,
  UsersData: UsersDataReducer,
  Source: SourceReducer,
  ticket: ticketSlice.reducer,
  modticket: modticketSlice.reducer,
  payment: paymentSlice.reducer,
  cartItems:cartSlice.reducer,
  cartButton:cartButtonSlice.reducer,
  CartItemIdToUpdate:updateCartItemSlice.reducer,
  sidebarActiveMenu: sidebarSlice.reducer,
  StepTracker: stepTrackerSlice.reducer, //will implement to replace current prop drilling with tracking step in phone_auth flow
  registrationSessionTracker: persistReducer(registrationTrackerConfig, registrationSlice.reducer),
  referer: refererSlice.reducer,
  ScanData: ScanDataSlice.reducer,
  menuModifiers: menuModifiersSlice.reducer,
  menuModifierGroups: menuModifierGroupsSlice.reducer,
  menuCategories: menuCategoriesSlice.reducer,
  codeActivation: CodeActivationSlice.reducer,
  activeMenuCategory: activeMenuCategorySlice.reducer,
  itemDetails: itemDetailsSlice.reducer,
  locationState: locationStateSlice.reducer,
  selectedCategorySectionId: selectedCategorySectionIdSlice.reducer,
  order: orderSlice.reducer,
  locationSchedual: LocationSchedualSlice.reducer,
  selectedItemModifireInfo:selectedItemModifireInfoSlice.reducer,
  globalSettings:globalSettingsSlice.reducer,
  AuthModule:AuthModuleSlice.reducer,
  taxRates:taxRatesSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
