import {setGlobalDefaultIntegrations, startLoading} from "./globalSettingsSlice";
import axios from "axios";


export const getGlobalIntegrationsDefault = (LocSettings: any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())

        const API_URL = process.env.REACT_APP_SP_API_URL;
        const global_on_id = process.env.REACT_APP_GLOBAL_ON;
        const user_id = LocSettings?.location?.owner;

        axios.get(`${API_URL}/global/integrations?user_id=${user_id}&global_on_id=${global_on_id}&get_default_integrations=1`)
            .then((res) => {
                dispatch( setGlobalDefaultIntegrations(res.data) )
            })
            .catch((error) => {
                console.log("error update biometrics logs ==>", error)
            })
    }
}
