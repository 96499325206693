import { createSlice } from '@reduxjs/toolkit'

export interface MenuModifiersState {
    isLoading: boolean,
    data: object,
}

const initialState: MenuModifiersState = {
    isLoading: false,
    data: {},
}

export const menuModifiersSlice = createSlice({
    name: 'menuModifiers',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setMenuModifiers: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setMenuModifiers } = menuModifiersSlice.actions
