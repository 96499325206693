import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {
  LocationScheduleDayState,
  LocationScheduleState,
} from '../../../pages/online_ordering/state/LocationSchedualState'

export interface Source {
  locationScheduleToday: boolean
  isLocationScheduleAvailable: boolean
}

const initialState: Source = {
  locationScheduleToday: false,
  isLocationScheduleAvailable: false,
}

export const LocationSchedualSlice = createSlice({
  name: 'locationSchedual',
  initialState,
  reducers: {
    setLocationScheduleToday: (state, action: PayloadAction<boolean>) => {
      state.locationScheduleToday = action.payload
    },
    setIsLocationScheduleAvailable: (state, action: PayloadAction<boolean>) => {
      state.isLocationScheduleAvailable = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setLocationScheduleToday, setIsLocationScheduleAvailable} =
  LocationSchedualSlice.actions
