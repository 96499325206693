
const countries : { [key: string]: any } = {


"PK": { id:"1",primary:"Pakistan", secondary:"+92","pn_length":"10"},
"IN": { id:"2",primary:"India", secondary:"+91","pn_length":"10"},
"US": { id:"3",primary:"United States" , secondary:"+1","pn_length":"10"},
"CN": { id:"4",primary:"China", secondary:"+86","pn_length":"13"},
"ES": { id:"5",primary:"Spain", secondary:"+34","pn_length":"9"},
"JP": { id:"6",primary:"Japan", secondary:"+81","pn_length":"10"},
"BR": { id:"9",primary:"Brazil", secondary:"+55","pn_length":"11"},
"MA": { id:"11",primary:"Morocco", secondary:"+212","pn_length":"9"},
"MX": { id:"12",primary:"Mexico", secondary:"+52","pn_length":"10"},
"CA": { id:"13",primary:"Canada", secondary:"+1","pn_length":"10"},
"VG": { id:"17",primary:"British Virgin Islands", secondary:"+1","pn_length":"10"},
"VI": { id:"18",primary:"U.S Virgin Islands", secondary:"+1","pn_length":"10"},
"AE": { id:"20",primary:"United Arab Emirates", secondary:"+971","pn_length":"9"},
"SG": { id:"23",primary:"Singapore", secondary:"+65","pn_length":"8"},
"LB": { id:"24",primary:"Lebanon", secondary:"+961","pn_length":"7"},
"CH": { id:"25",primary:"Switzerland" , secondary:"+41","pn_length":"9"},
"PR": { id:"26",primary:"Puerto Rico" , secondary:"+1","pn_length":"10"},
"GB": { id:"27",primary:"United Kingdom", secondary:"+44","pn_length":"10"},
"DE": { id:"28",primary:"Germany", secondary:"+49","pn_length":"11"},
"AU": { id:"29",primary:"Australia", secondary:"+61","pn_length":"9"},
"AF": { id:"30",primary:"Afghanistan" , secondary:"+93","pn_length":"9"},
"AL": { id:"31",primary:"Albania", secondary:"+355","pn_length":"9"},
"DZ": { id:"32",primary:"Algeria", secondary:"+213","pn_length":"9"},
"AS": { id:"33",primary:"American Samoa", secondary:"+1","pn_length":"10"},
"AD": { id:"34",primary:"Andorra", secondary:"+376","pn_length":"10"},
"AO": { id:"35",primary:"Angola", secondary:"+244","pn_length":"9"},
"AI": { id:"36",primary:"Anguilla", secondary:"+1","pn_length":"10"},
"AQ": { id:"37",primary:"Antarctica" , secondary:"+672","pn_length":"10"},
"AR": { id:"38",primary:"Argentina", secondary:"+54","pn_length":"11"},
"AG": { id:"39",primary:"Antigua and Barbuda", secondary:"+1","pn_length":"10"},
"AM": { id:"40",primary:"Armenia", secondary:"+374","pn_length":"8"},
"AW": { id:"41",primary:"Aruba", secondary:"+297","pn_length":"7"},
"AT": { id:"42",primary:"Austria", secondary:"+43","pn_length":"10"},
"AZ": { id:"43",primary:"Azerbaijan" , secondary:"+994","pn_length":"9"},
"BS": { id:"44",primary:"Bahamas", secondary:"+1","pn_length":"10"},
"BH": { id:"45",primary:"Bahrain", secondary:"+973","pn_length":"8"},
"BD": { id:"46",primary:"Bangladesh" , secondary:"+880","pn_length":"10"},
"BB": { id:"47",primary:"Barbados", secondary:"+1","pn_length":"10"},
"BY": { id:"48",primary:"Belarus", secondary:"+375","pn_length":"9"},
"BE": { id:"49",primary:"Belgium", secondary:"+32","pn_length":"9"},
"BZ": { id:"50",primary:"Belize", secondary:"+501","pn_length":"7"},
"BJ": { id:"51",primary:"Benin", secondary:"+229","pn_length":"9"},
"BM": { id:"52",primary:"Bermuda", secondary:"+1","pn_length":"10"},
"BT": { id:"53",primary:"Bhutan", secondary:"+975","pn_length":"8"},
"BO": { id:"54",primary:"Bolivia", secondary:"+591","pn_length":"8"},
"BA": { id:"55",primary:"Bosnia and Herzegovina", secondary:"+387","pn_length":"8"},
"BW": { id:"56",primary:"Botswana", secondary:"+267","pn_length":"8"},
"BV": { id:"57",primary:"Bouvet Island" , secondary:"+47","pn_length":"10"},
"IO": { id:"58",primary:"British Indian Ocean Territory", secondary:"+246","pn_length":"7"},
"BN": { id:"59",primary:"Brunei Darussalam", secondary:"+673","pn_length":"7"},
"BG": { id:"60",primary:"Bulgaria", secondary:"+359","pn_length":"9"},
"BF": { id:"61",primary:"Burkina Faso" , secondary:"+226","pn_length":"8"},
"BI": { id:"62",primary:"Burundi", secondary:"+257","pn_length":"8"},
"KH": { id:"63",primary:"Cambodia", secondary:"+855","pn_length":"9"},
"CM": { id:"64",primary:"Cameroon", secondary:"+237","pn_length":"9"},
"CV": { id:"65",primary:"Cape Verde" , secondary:"+238","pn_length":"7"},
"KY": { id:"66",primary:"Cayman Islands", secondary:"+1","pn_length":"10"},
"CF": { id:"67",primary:"Central African Republic", secondary:"+236","pn_length":"8"},
"TD": { id:"68",primary:"Chad", secondary:"+235","pn_length":"8"},
"CL": { id:"69",primary:"Chile", secondary:"+56","pn_length":"9"},
"CX": { id:"70",primary:"Christmas Island", secondary:"+61","pn_length":"10"},
"CC": { id:"71",primary:"Cocos (Keeling) Islands", secondary:"+61","pn_length":"10"},
"CO": { id:"72",primary:"Colombia", secondary:"+57","pn_length":"10"},
"KM": { id:"73",primary:"Comoros", secondary:"+269","pn_length":"7"},
"CG": { id:"74",primary:"Congo (Republic)", secondary:"+242","pn_length":"7"},
"CK": { id:"75",primary:"Cook Islands" , secondary:"+682","pn_length":"5"},
"CR": { id:"76",primary:"Costa Rica" , secondary:"+506","pn_length":"8"},
"CI": { id:"77",primary:"Côte d'Ivoire" , secondary:"+225","pn_length":"10"},
"HR": { id:"78",primary:"Croatia", secondary:"+385","pn_length":"9"},
"CU": { id:"79",primary:"Cuba", secondary:"+53","pn_length":"8"},
"CY": { id:"80",primary:"Cyprus", secondary:"+357","pn_length":"8"},
"CZ": { id:"81",primary:"Czech Republic", secondary:"+420","pn_length":"9"},
"DK": { id:"82",primary:"Denmark", secondary:"+45","pn_length":"8"},
"DJ": { id:"83",primary:"Djibouti", secondary:"+253","pn_length":"6"},
"DM": { id:"84",primary:"Dominica", secondary:"+1","pn_length":"8"},
"DO": { id:"85",primary:"Dominican Republic", secondary:"+1","pn_length":"10"},
"TP": { id:"86",primary:"Timor-Leste" , secondary:"+670","pn_length":"7"},
"EC": { id:"87",primary:"Ecuador ", secondary:"+593","pn_length":"9"},
"EG": { id:"88",primary:"Egypt", secondary:"+20","pn_length":"10"},
"SV": { id:"89",primary:"El Salvador" , secondary:"+503","pn_length":"8"},
"GQ": { id:"90",primary:"Equatorial Guinea", secondary:"+240","pn_length":"6"},
"ER": { id:"91",primary:"Eritrea", secondary:"+291","pn_length":"7"},
"EE": { id:"92",primary:"Estonia", secondary:"+372","pn_length":"8"},
"ET": { id:"93",primary:"Ethiopia", secondary:"+251","pn_length":"9"},
"FK": { id:"94",primary:"Falkland Islands (Malvinas)", secondary:"+500","pn_length":"5"},
"FO": { id:"95",primary:"Faroe Islands" , secondary:"+298","pn_length":"5"},
"FJ": { id:"96",primary:"Fiji", secondary:"+679","pn_length":"7"},
"FI": { id:"97",primary:"Finland", secondary:"+358","pn_length":"12"},
"FR": { id:"98",primary:"France", secondary:"+33","pn_length":"9"},
"GF": { id:"100",primary:"French Guiana", secondary:"+594","pn_length":"9"},
"PF": { id:"101",primary:"French Polynesia", secondary:"+689","pn_length":"6"},
"GA": { id:"103",primary:"Gabon", secondary:"+241","pn_length":"7"},
"GE": { id:"104",primary:"Georgia", secondary:"+995","pn_length":"9"},
"GH": { id:"105",primary:"Ghana", secondary:"+233","pn_length":"9"},
"GI": { id:"106",primary:"Gibraltar" , secondary:"+350","pn_length":"8"},
"GR": { id:"107",primary:"Greece", secondary:"+30","pn_length":"10"},
"GL": { id:"108",primary:"Greenland " , secondary:"+299","pn_length":"6"},
"GD": { id:"109",primary:"Grenada", secondary:"+1","pn_length":"10"},
"GP": { id:"110",primary:"Guadeloupe" , secondary:"+590","pn_length":"9"},
"GU": { id:"111",primary:"Guam", secondary:"+1","pn_length":"10"},
"GT": { id:"112",primary:"Guatemala" , secondary:"+502","pn_length":"8"},
"GN": { id:"113",primary:"Guinea", secondary:"+224","pn_length":"8"},
"GW": { id:"114",primary:"Guinea-Bissau", secondary:"+245","pn_length":"7"},
"GY": { id:"115",primary:"Guyana", secondary:"+592","pn_length":"7"},
"HT": { id:"116",primary:"Haiti", secondary:"+509","pn_length":"8"},
"HN": { id:"118",primary:"Honduras", secondary:"+504","pn_length":"8"},
"HK": { id:"119",primary:"Hong Kong" , secondary:"+852","pn_length":"8"},
"HU": { id:"120",primary:"Hungary", secondary:"+36","pn_length":"9"},
"IS": { id:"121",primary:"Iceland", secondary:"+354","pn_length":"9"},
"ID": { id:"122",primary:"Indonesia" , secondary:"+62","pn_length":"9"},
"IR": { id:"123",primary:"Iran", secondary:"+98","pn_length":"10"},
"IQ": { id:"124",primary:"Iraq", secondary:"+964","pn_length":"10"},
"IE": { id:"125",primary:"Ireland", secondary:"+353","pn_length":"9"},
"IL": { id:"126",primary:"Israel", secondary:"+972","pn_length":"9"},
"IT": { id:"127",primary:"Italy", secondary:"+39","pn_length":"10"},
"JM": { id:"128",primary:"Jamaica", secondary:"+1","pn_length":"10"},
"JO": { id:"129",primary:"Jordan", secondary:"+962","pn_length":"9"},
"KZ": { id:"130",primary:"Kazakhstan" , secondary:"+7","pn_length":"10"},
"KE": { id:"131",primary:"Kenya", secondary:"+254","pn_length":"10"},
"KI": { id:"132",primary:"Kiribati", secondary:"+686","pn_length":"8"},
"KP": { id:"133",primary:"North Korea" , secondary:"+850","pn_length":"10"},
"KR": { id:"134",primary:"South Korea" , secondary:"+82","pn_length":"10"},
"KW": { id:"135",primary:"Kuwait", secondary:"+965","pn_length":"8"},
"KG": { id:"136",primary:"Kyrgyzstan" , secondary:"+996","pn_length":"9"},
"LA": { id:"137",primary:"Laos", secondary:"+856","pn_length":"11"},
"LV": { id:"138",primary:"Latvia", secondary:"+371","pn_length":"8"},
"LS": { id:"140",primary:"Lesotho", secondary:"+266","pn_length":"8"},
"LR": { id:"141",primary:"Liberia", secondary:"+231","pn_length":"7"},
"LY": { id:"142",primary:"Libya", secondary:"+218","pn_length":"10"},
"LI": { id:"143",primary:"Liechtenstein", secondary:"+423","pn_length":"9"},
"LT": { id:"144",primary:"Lithuania" , secondary:"+370","pn_length":"8"},
"LU": { id:"145",primary:"Luxembourg" , secondary:"+352","pn_length":"9"},
"MO": { id:"146",primary:"Macao", secondary:"+853","pn_length":"8"},
"MK": { id:"147",primary:"Macedonia" , secondary:"+389","pn_length":"8"},
"MG": { id:"148",primary:"Madagascar" , secondary:"+261","pn_length":"10"},
"MW": { id:"149",primary:"Malawi", secondary:"+265","pn_length":"8"},
"MY": { id:"150",primary:"Malaysia", secondary:"+60","pn_length":"7"},
"MV": { id:"151",primary:"Maldives", secondary:"+960","pn_length":"7"},
"ML": { id:"152",primary:"Mali", secondary:"+223","pn_length":"8"},
"MT": { id:"153",primary:"Malta", secondary:"+356","pn_length":"8"},
"MH": { id:"154",primary:"Marshall Islands", secondary:"+692","pn_length":"7"},
"MQ": { id:"155",primary:"Martinique" , secondary:"+596","pn_length":"9"},
"MR": { id:"156",primary:"Mauritania" , secondary:"+222","pn_length":"7"},
"MU": { id:"157",primary:"Mauritius" , secondary:"+230","pn_length":"8"},
"YT": { id:"158",primary:"Mayotte", secondary:"+262","pn_length":"10"},
"FM": { id:"159",primary:"Micronesia" , secondary:"+691","pn_length":"7"},
"MD": { id:"160",primary:"Moldova", secondary:"+373","pn_length":"8"},
"MC": { id:"161",primary:"Monaco", secondary:"+377","pn_length":"9"},
"MN": { id:"162",primary:"Mongolia " , secondary:"+976","pn_length":"8"},
"MS": { id:"163",primary:"Montserrat" , secondary:"+1","pn_length":"10"},
"MZ": { id:"164",primary:"Mozambique" , secondary:"+258","pn_length":"12"},
"MM": { id:"165",primary:"Myanmar", secondary:"+95","pn_length":"8"},
"NA": { id:"166",primary:"Namibia", secondary:"+264","pn_length":"10"},
"NR": { id:"167",primary:"Nauru", secondary:"+674","pn_length":"7"},
"NP": { id:"168",primary:"Nepal", secondary:"+977","pn_length":"10"},
"NL": { id:"169",primary:"Netherlands" , secondary:"+31","pn_length":"9"},
"NC": { id:"171",primary:"New Caledonia", secondary:"+687","pn_length":"6"},
"NZ": { id:"172",primary:"New Zealand" , secondary:"+64","pn_length":"9"},
"NI": { id:"173",primary:"Nicaragua" , secondary:"+505","pn_length":"8"},
"NE": { id:"174",primary:"Niger", secondary:"+227","pn_length":"8"},
"NG": { id:"175",primary:"Nigeria", secondary:"+234","pn_length":"8"},
"NU": { id:"176",primary:"Niue", secondary:"+683","pn_length":"4"},
"NF": { id:"177",primary:"Norfolk Island", secondary:"+672","pn_length":"6"},
"MP": { id:"178",primary:"Northern Mariana Islands", secondary:"+1","pn_length":"10"},
"NO": { id:"179",primary:"Norway", secondary:"+47","pn_length":"8"},
"OM": { id:"180",primary:"Oman", secondary:"+968","pn_length":"8"},
"PW": { id:"181",primary:"Palau", secondary:"+680","pn_length":"7"},
"PA": { id:"182",primary:"Panama", secondary:"+507","pn_length":"8"},
"PG": { id:"183",primary:"Papua New Guinea", secondary:"+675","pn_length":"11"},
"PY": { id:"184",primary:"Paraguay", secondary:"+595","pn_length":"9"},
"PE": { id:"185",primary:"Peru", secondary:"+51","pn_length":"9"},
"PH": { id:"186",primary:"Philippines" , secondary:"+63","pn_length":"10"},
"PN": { id:"187",primary:"Pitcairn", secondary:"+64","pn_length":"9"},
"PL": { id:"188",primary:"Poland", secondary:"+48","pn_length":"9"},
"PT": { id:"189",primary:"Portugal " , secondary:"+351","pn_length":"9"},
"QA": { id:"190",primary:"Qatar", secondary:"+974","pn_length":"8"},
"RE": { id:"191",primary:"Reunion", secondary:"+262","pn_length":"10"},
"RO": { id:"192",primary:"Romania", secondary:"+40","pn_length":"10"},
"RU": { id:"193",primary:"Russia", secondary:"+7","pn_length":"10"},
"RW": { id:"194",primary:"Rwanda", secondary:"+250","pn_length":"9"},
"KN": { id:"195",primary:"Saint Kitts and Nevis", secondary:"+1","pn_length":"10"},
"LC": { id:"196",primary:"Saint Lucia" , secondary:"+1","pn_length":"10"},
"VC": { id:"197",primary:"Saint Vincent and The Grenadines", secondary:"+1","pn_length":"10"},
"WS": { id:"198",primary:"Samoa", secondary:"+685","pn_length":"10"},
"SM": { id:"199",primary:"San Marino" , secondary:"+378","pn_length":"10"},
"ST": { id:"200",primary:"Sao Tome and Principe", secondary:"+239","pn_length":"7"},
"SA": { id:"201",primary:"Saudi Arabia" , secondary:"+966","pn_length":"9"},
"SN": { id:"202",primary:"Senegal", secondary:"+221","pn_length":"9"},
"SC": { id:"203",primary:"Seychelles" , secondary:"+248","pn_length":"6"},
"SL": { id:"204",primary:"Sierra Leone" , secondary:"+232","pn_length":"8"},
"SK": { id:"205",primary:"Slovakia", secondary:"+421","pn_length":"9"},
"SI": { id:"206",primary:"Slovenia", secondary:"+386","pn_length":"8"},
"SB": { id:"207",primary:"Solomon Islands", secondary:"+677","pn_length":"7"},
"SO": { id:"208",primary:"Somalia", secondary:"+252","pn_length":"8"},
"ZA": { id:"209",primary:"South Africa" , secondary:"+27","pn_length":"9"},
"LK": { id:"211",primary:"Sri Lanka" , secondary:"+94","pn_length":"7"},
"SH": { id:"212",primary:"Saint Helena" , secondary:"+290","pn_length":"4"},
"SD": { id:"214",primary:"Sudan", secondary:"+249","pn_length":"9"},
"SR": { id:"215",primary:"Suriname", secondary:"+597","pn_length":"7"},
"SZ": { id:"216",primary:"Swaziland" , secondary:"+268","pn_length":"8"},
"SE": { id:"217",primary:"Sweden", secondary:"+46","pn_length":"7"},
"SY": { id:"218",primary:"Syria", secondary:"+963","pn_length":"9"},
"TW": { id:"219",primary:"Taiwan", secondary:"+886","pn_length":"9"},
"TJ": { id:"220",primary:"Tajikistan" , secondary:"+992","pn_length":"9"},
"TZ": { id:"221",primary:"Tanzania", secondary:"+255","pn_length":"6"},
"TH": { id:"222",primary:"Thailand", secondary:"+66","pn_length":"9"},
"TG": { id:"223",primary:"Togo", secondary:"+228","pn_length":"8"},
"TK": { id:"224",primary:"Tokelau", secondary:"+690","pn_length":"4"},
"TO": { id:"225",primary:"Tonga", secondary:"+676","pn_length":"7"},
"TT": { id:"226",primary:"Trinidad and Tobago", secondary:"+1","pn_length":"10"},
"TN": { id:"227",primary:"Tunisia", secondary:"+216","pn_length":"8"},
"TR": { id:"228",primary:"Turkey", secondary:"+90","pn_length":"10"},
"TM": { id:"229",primary:"Turkmenistan" , secondary:"+993","pn_length":"8"},
"TC": { id:"230",primary:"Turks and Caicos Islands", secondary:"+1","pn_length":"10"},
"TV": { id:"231",primary:"Tuvalu", secondary:"+688","pn_length":"6"},
"UG": { id:"232",primary:"Uganda", secondary:"+256","pn_length":"9"},
"UA": { id:"233",primary:"Ukraine", secondary:"+380","pn_length":"9"},
"UY": { id:"234",primary:"Uruguay", secondary:"+598","pn_length":"11"},
"UZ": { id:"235",primary:"Uzbekistan" , secondary:"+998","pn_length":"9"},
"VU": { id:"236",primary:"Vanuatu", secondary:"+678","pn_length":"7"},
"VE": { id:"237",primary:"Venezuela" , secondary:"+58","pn_length":"7"},
"VN": { id:"238",primary:"Vietnam", secondary:"+84","pn_length":"9"},
"WF": { id:"239",primary:"Wallis and Futuna", secondary:"+681","pn_length":"6"},
"EH": { id:"240",primary:"Western Sahara", secondary:"+212","pn_length":"10"},
"YE": { id:"241",primary:"Yemen", secondary:"+967","pn_length":"9"},
"ZM": { id:"244",primary:"Zambia", secondary:"+260","pn_length":"9"},
"ZW": { id:"245",primary:"Zimbabwe", secondary:"+263","pn_length":"9"},
"CD": { id:"250",primary:"Congo (DRC)" , secondary:"+243","pn_length":"9"},
"GM": { id:"252",primary:"Gambia", secondary:"+220","pn_length":"7"},
"PS": { id:"266",primary:"Palestinian Territory, Occupied", secondary:"+970","pn_length":"10"},
"PM": { id:"268",primary:"Saint Pierre and Miquelon", secondary:"+508","pn_length":"6"},
"CS": { id:"270",primary:"Serbia and Montenegro", secondary:"+381","pn_length":"8"},
"SJ": { id:"274",primary:"Svalbard and Jan Mayen", secondary:"+47","pn_length":"10"},
"TB": { id:"284",primary:"Saint Barthélemy", secondary:"+590","pn_length":"10"},
"CW": { id:"286",primary:"Curacao", secondary:"+599","pn_length":"10"},
"GK": { id:"287",primary:"Guernsey", secondary:"+44","pn_length":"10"},
"IM": { id:"288",primary:"Isle of Man" , secondary:"+44","pn_length":"10"},
"JE": { id:"289",primary:"Jersey", secondary:"+44","pn_length":"10"},
"MJ": { id:"290",primary:"Montenegro" , secondary:"+382","pn_length":"8"},
"RN": { id:"291",primary:"Saint Martin" , secondary:"+590","pn_length":"10"},
"RI": { id:"292",primary:"Serbia", secondary:"+381","pn_length":"8"},
"OD": { id:"293",primary:"South Sudan" , secondary:"+211","pn_length":"9"},
"NN": { id:"294",primary:"Sint Maarten" , secondary:"+1","pn_length":"10"},
"KV": { id:"296",primary:"Kosovo", secondary:"+381","pn_length":"10"},
};
const baseCountries : { [key: string]: any } =  {
1: { id:"PK"},
2: { id:"IN"},
3: { id:"US"},
4: { id:"CN"},
5: { id:"ES"},
6: { id:"JP"},
9: { id:"BR"},
11: { id:"MA"},
12: { id:"MX"},
13: { id:"CA"},
17: { id:"VG"},
18: { id:"VI"},
20: { id:"AE"},
23: { id:"SG"},
24: { id:"LB"},
25: { id:"CH"},
26: { id:"PR"},
27: { id:"GB"},
28: { id:"DE"},
29: { id:"AU"},
30: { id:"AF"},
31: { id:"AL"},
32: { id:"DZ"},
33: { id:"AS"},
34: { id:"AD"},
35: { id:"AO"},
36: { id:"AI"},
37: { id:"AQ"},
38: { id:"AR"},
39: { id:"AG"},
40: { id:"AM"},
41: { id:"AW"},
42: { id:"AT"},
43: { id:"AZ"},
44: { id:"BS"},
45: { id:"BH"},
46: { id:"BD"},
47: { id:"BB"},
48: { id:"BY"},
49: { id:"BE"},
50: { id:"BZ"},
51: { id:"BJ"},
52: { id:"BM"},
53: { id:"BT"},
54: { id:"BO"},
55: { id:"BA"},
56: { id:"BW"},
57: { id:"BV"},
58: { id:"IO"},
59: { id:"BN"},
60: { id:"BG"},
61: { id:"BF"},
62: { id:"BI"},
63: { id:"KH"},
64: { id:"CM"},
65: { id:"CV"},
66: { id:"KY"},
67: { id:"CF"},
68: { id:"TD"},
69: { id:"CL"},
70: { id:"CX"},
71: { id:"CC"},
72: { id:"CO"},
73: { id:"KM"},
74: { id:"CG"},
75: { id:"CK"},
76: { id:"CR"},
77: { id:"CI"},
78: { id:"HR"},
79: { id:"CU"},
80: { id:"CY"},
81: { id:"CZ"},
82: { id:"DK"},
83: { id:"DJ"},
84: { id:"DM"},
85: { id:"DO"},
86: { id:"TP"},
87: { id:"EC"},
88: { id:"EG"},
89: { id:"SV"},
90: { id:"GQ"},
91: { id:"ER"},
92: { id:"EE"},
93: { id:"ET"},
94: { id:"FK"},
95: { id:"FO"},
96: { id:"FJ"},
97: { id:"FI"},
98: { id:"FR"},
100: { id:"GF"},
101: { id:"PF"},
103: { id:"GA"},
104: { id:"GE"},
105: { id:"GH"},
106: { id:"GI"},
107: { id:"GR"},
108: { id:"GL"},
109: { id:"GD"},
110: { id:"GP"},
111: { id:"GU"},
112: { id:"GT"},
113: { id:"GN"},
114: { id:"GW"},
115: { id:"GY"},
116: { id:"HT"},
118: { id:"HN"},
119: { id:"HK"},
120: { id:"HU"},
121: { id:"IS"},
122: { id:"ID"},
123: { id:"IR"},
124: { id:"IQ"},
125: { id:"IE"},
126: { id:"IL"},
127: { id:"IT"},
128: { id:"JM"},
129: { id:"JO"},
130: { id:"KZ"},
131: { id:"KE"},
132: { id:"KI"},
133: { id:"KP"},
134: { id:"KR"},
135: { id:"KW"},
136: { id:"KG"},
137: { id:"LA"},
138: { id:"LV"},
140: { id:"LS"},
141: { id:"LR"},
142: { id:"LY"},
143: { id:"LI"},
144: { id:"LT"},
145: { id:"LU"},
146: { id:"MO"},
147: { id:"MK"},
148: { id:"MG"},
149: { id:"MW"},
150: { id:"MY"},
151: { id:"MV"},
152: { id:"ML"},
153: { id:"MT"},
154: { id:"MH"},
155: { id:"MQ"},
156: { id:"MR"},
157: { id:"MU"},
158: { id:"YT"},
159: { id:"FM"},
160: { id:"MD"},
161: { id:"MC"},
162: { id:"MN"},
163: { id:"MS"},
164: { id:"MZ"},
165: { id:"MM"},
166: { id:"NA"},
167: { id:"NR"},
168: { id:"NP"},
169: { id:"NL"},
171: { id:"NC"},
172: { id:"NZ"},
173: { id:"NI"},
174: { id:"NE"},
175: { id:"NG"},
176: { id:"NU"},
177: { id:"NF"},
178: { id:"MP"},
179: { id:"NO"},
180: { id:"OM"},
181: { id:"PW"},
182: { id:"PA"},
183: { id:"PG"},
184: { id:"PY"},
185: { id:"PE"},
186: { id:"PH"},
187: { id:"PN"},
188: { id:"PL"},
189: { id:"PT"},
190: { id:"QA"},
191: { id:"RE"},
192: { id:"RO"},
193: { id:"RU"},
194: { id:"RW"},
195: { id:"KN"},
196: { id:"LC"},
197: { id:"VC"},
198: { id:"WS"},
199: { id:"SM"},
200: { id:"ST"},
201: { id:"SA"},
202: { id:"SN"},
203: { id:"SC"},
204: { id:"SL"},
205: { id:"SK"},
206: { id:"SI"},
207: { id:"SB"},
208: { id:"SO"},
209: { id:"ZA"},
211: { id:"LK"},
212: { id:"SH"},
214: { id:"SD"},
215: { id:"SR"},
216: { id:"SZ"},
217: { id:"SE"},
218: { id:"SY"},
219: { id:"TW"},
220: { id:"TJ"},
221: { id:"TZ"},
222: { id:"TH"},
223: { id:"TG"},
224: { id:"TK"},
225: { id:"TO"},
226: { id:"TT"},
227: { id:"TN"},
228: { id:"TR"},
229: { id:"TM"},
230: { id:"TC"},
231: { id:"TV"},
232: { id:"UG"},
233: { id:"UA"},
234: { id:"UY"},
235: { id:"UZ"},
236: { id:"VU"},
237: { id:"VE"},
238: { id:"VN"},
239: { id:"WF"},
240: { id:"EH"},
241: { id:"YE"},
244: { id:"ZM"},
245: { id:"ZW"},
250: { id:"CD"},
252: { id:"GM"},
266: { id:"PS"},
268: { id:"PM"},
270: { id:"CS"},
274: { id:"SJ"},
284: { id:"TB"},
286: { id:"CW"},
287: { id:"GK"},
288: { id:"IM"},
289: { id:"JE"},
290: { id:"MJ"},
291: { id:"RN"},
292: { id:"RI"},
293: { id:"OD"},
294: { id:"NN"},
296: { id:"KV"},
}
export {countries,baseCountries}
