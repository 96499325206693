import {IOloSettings} from '../../../services/AppSettingsSlice'
import {LocationScheduleState} from '../state/LocationSchedualState'

const initSettings: IOloSettings = {
  curbside: null,
  disclaimer: null,
  display_customer_cubside: 0,
  display_customer_name: 0,
  display_customer_phone: 0,
  display_customer_pick_up_time: 0,
  intervals: null,
  min_pre_time: null,
  order_type: null,
  ordering_employee: null,
  orders_before_open: 0,
  orders_per_interval: null,
  pay_by_card: 0,
  pay_by_gift_card: 0,
  pay_in_store: 0,
  pay_by_room: 0,
  revenue_center: null,
  schedule_orders_days: null,
  scheduled_orders: 0,
  tip_selections: null,
  tips: 0,
}

const initSchedual: LocationScheduleState = {
  monday: {
    status: null,
    start_time: null,
    end_time: null,
  },
  tuesday: {
    status: null,
    start_time: null,
    end_time: null,
  },
  wednesday: {
    status: null,
    start_time: null,
    end_time: null,
  },
  thursday: {
    status: null,
    start_time: null,
    end_time: null,
  },
  friday: {
    status: null,
    start_time: null,
    end_time: null,
  },
  saturday: {
    status: null,
    start_time: null,
    end_time: null,
  },
  sunday: {
    status: null,
    start_time: null,
    end_time: null,
  },
}
/**
 * The method attempts to parse a IOloSettings object from the locationSettings parameter.
 * If it is unsuccessful it will return a IOloSettings object with default values.
 * @param locationSettings is either a LocationSettings object or a LocationSettings.value object.
 * @return A IOloSettings object
 */
export const getOloSettings = (locationSettings: any): IOloSettings => {
  if (
    locationSettings.value != undefined &&
    'olo' in locationSettings.value &&
    locationSettings.value.olo != undefined
  ) {
    return locationSettings.value.olo as IOloSettings
  } else if ('olo' in locationSettings && locationSettings.olo != undefined) {
    return locationSettings.olo as IOloSettings
  } else {
    return initSettings
  }
}

export const getOloSchedual = (locationSettings: any): LocationScheduleState => {
  if (
    locationSettings.value != undefined &&
    'schedule' in locationSettings.value &&
    locationSettings.value.schedule != undefined
  ) {
    return locationSettings.value.schedule as LocationScheduleState
  } else if ('schedule' in locationSettings && locationSettings.schedule != undefined) {
    return locationSettings.schedule as LocationScheduleState
  } else {
    return initSchedual
  }
}
