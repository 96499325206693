import moment from 'moment'
import {FormattedMessage, useIntl} from 'react-intl'
import {ITicketItem,} from '../components/ItemizedTab'
import {constants} from '../../../config/constants'
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {IAPILocSettings} from "../../../services/ApiCallService";
export interface ITicketDetails {
    transaction_type_name?:string|null,
    emp_name:string|null,
    opened_date:string,
    ticket_number:string,
    location_name:string|null,
    opened_time:string,
    voided:number,
    ReceiptType?:string
    created_on?: number
    order_type?:string
    customer_name?:string
}

const GetDetailsOloName = (item_menu_id:number|null, locationSettings: IAPILocSettings) => {
    let name = '';
    if(item_menu_id == locationSettings.olo_item_id_guest_name) {
        name = 'Customer Name';
    } else if(item_menu_id == locationSettings.olo_item_id_phone) {
        name = 'Customer Phone';
    } else if(item_menu_id == locationSettings.olo_item_id_pickup_time) {
        name = 'Pickup Time';
    }

    return name;
}

const GetDetailsName = (name:string) => {
    switch(name){
        case 'OLO NAME':
        case 'Client Name':
            name = 'Customer Name';
        break;
        case 'OLO PHONE':
        case 'Phone Number':
            name = 'Customer Phone';
        break;
        case 'OLO PICKUP TIME':
            name = 'Pickup Time';
        break;
    }
    return name;
}

export const ReceiptTicketDetails = ({props,items}: {props: ITicketDetails,items:[ITicketItem]|null}) => {
   const locationSettings = useSelector((state:RootState) => state.LocSettings.value )
   const intl = useIntl()
   const openedAtMoment = moment(
      props.opened_date + ' ' + props.opened_time,
      'YYYY-MM-DD HH:mm:ss',
      true
   )
   const openedDateStamp =
      openedAtMoment.format('YYYY-MM-DD') == 'Invalid date'
         ? props.opened_date
         : openedAtMoment.format('MM-DD-YYYY')
   const openedTimeStamp =
      openedAtMoment.format('hh:mm a') == 'Invalid date'
         ? props.opened_date
         : openedAtMoment.format('hh:mm A')

   let badge_label = ''
   if (props.voided == 1) {
      badge_label = `<span class="badge badge-light-danger fs-7 fw-bold">${intl.formatMessage({
         id: 'receipt.receipt_detail.voided',
      })}</span>`
   }

   const created_on_kiosk = constants.global_on.kiosk_web === props.created_on
   const clearName = (name: string) => name.split('_')[0]

   return (
      <table
         border={0}
         className='text-gray-600'
         cellPadding='5'
         id='receiptTicketDetailsTable'
         cellSpacing='0'
         width='100%'
      >
         <tbody>
            <tr>
               <td className='card-label fw-bold text-dark'>
                  {props.location_name ? props.location_name : ''}
               </td>
               <td className='text-end'>{openedDateStamp}</td>
            </tr>
            {props.ReceiptType == 'Payment' ? (
               <tr>
                  <td className='card-label fw-bold text-dark'>{props.transaction_type_name}</td>

                  <td className='text-end'>{openedTimeStamp}</td>
               </tr>
            ) : (
               <tr>
                  <td>
                     <FormattedMessage id='profile.receipt.ticket' defaultMessage='Ticket' />{' '}
                     <b className='text-start text-dark'>#{props.ticket_number}</b>{' '}
                     <span dangerouslySetInnerHTML={{__html: badge_label}}></span>
                  </td>
                  <td className='text-end'>{openedTimeStamp}</td>
               </tr>
            )}
            {
                items?.map((item) => {
                    if( item.menu_item == locationSettings.olo_item_id_guest_name ||
                        item.menu_item == locationSettings.olo_item_id_phone ||
                        item.menu_item == locationSettings.olo_item_id_pickup_time
                    )
                    {
                        return <tr><td colSpan={2}>{GetDetailsOloName(item.menu_item, locationSettings)} <b className='text-start text-dark'>{item.comment}</b></td></tr>
                    } else {
                        return <></>
                    }
                })
            }
            {created_on_kiosk && props.customer_name &&
               <tr>
                  <td colSpan={2}>{GetDetailsName('Client Name')} <b className='text-start text-dark'>{clearName(props.customer_name)}</b></td>
               </tr>
            }
            {created_on_kiosk && props.order_type &&
               <tr>
                  <td colSpan={2}>{GetDetailsName('Order Type')} <b className='text-start text-dark'>{props.order_type}</b></td>
               </tr>
            } 
         </tbody>
      </table>
   )
}
