import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface ICartItem {
    id: number,
    itemId: number
}

const initialState: ICartItem = {
    id: 0,
    itemId: 0
}

export const updateCartItemSlice = createSlice({
    name: 'cartItemIdToUpdate',
    initialState,
    reducers: {

        setCartItemIdToUpdate: (state, action: PayloadAction<ICartItem>) => {
            state.itemId = action.payload.itemId
            state.id = action.payload.id
        },

        resetCartItemToUpdate: (state) => {
            state.id = 0
            state.itemId = 0
        }

    },
})

// Action creators are generated for each case reducer function
export const {setCartItemIdToUpdate, resetCartItemToUpdate} = updateCartItemSlice.actions
