import { createSlice } from '@reduxjs/toolkit'
import { IStandardError } from '../../../../common/api_interfaces/Errors'

export interface IItemDetailState {
    isLoading: boolean
    data: any
    error?: IStandardError
}

const initialState: IItemDetailState = {
    isLoading: false,
    data: {},
}

export const itemDetailsSlice = createSlice({
    name: 'itemDetails',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setItemDetails: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
        setError: (state, action) => {
          state.error = action.payload
          state.isLoading = false
        },
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setItemDetails, setError } = itemDetailsSlice.actions
