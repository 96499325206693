import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import moment from "moment";

export interface IRefererState{
    referer: string,
    current: string,
}

const initialState: IRefererState = {
    referer: '',
    current: '',
}

export const refererSlice = createSlice({
    name: 'referer',
    initialState,
    reducers: {
        setReferer: (state, action) => {
            if(action.payload != state.current) {
                state.referer = state.current
                state.current = action.payload
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { setReferer } = refererSlice.actions
