import React, {useContext, useEffect, useState} from 'react'
import {StoreCard} from "../../../../modules/wizards/components/steps/StoreCard";
import {Step5} from "../../../../modules/wizards/components/steps/Step5";
import * as ApiCallService from "../../../../services/ApiCallService";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {StoredCard} from "./StoredCard";
import {AddNewCard} from "./AddNewCard";
import {LoadingPage} from "../../../../modules/widgets/Loaders/LoadingPage";
import {constants} from "../../../../config/constants";
import {useNavigate} from "react-router-dom";
import {HeaderTitleContext} from "../../../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore";
import { useIntl } from 'react-intl';

export const SelectPaymentMethod = () => {
    const intl = useIntl();
    const navigateTo = useNavigate()
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    const guest = useSelector((state: any) => state.guest);
    const SourceFlow = useSelector((state: RootState) => state.Source.flow);
    const [displayStoreCard, setDisplayStoreCard] = useState(true);
    const [loading, setLoading] = useState(true);
    const base_domain = LocSettings.base_domain;
    const { setPageTitle } = useContext(HeaderTitleContext);
    setPageTitle(intl.formatMessage({id:constants.staticPageTitle.add_payment_method}))

    useEffect(() => {
        let data={
            guest_id:guest.data.id,
            flow: SourceFlow
        }
        ApiCallService.ApiCall(data, `/guests/${guest.data.id}/guest_cc_token`, 'GET', LocSettings)
            .then((response: any) => {
                setLoading(false)
                if(response.data.length <= 0) {
                    setDisplayStoreCard(false);
                }
            })
            .catch((err) => {
                setDisplayStoreCard(false);
                setLoading(false)
            })
    }, [])

    if(loading) {
        return <LoadingPage />
    }

    const submitNextStepHandle = () => {
        navigateTo(`${base_domain}/${constants.routes.private.pre_checkin_confirmation_done}`);
    }

    return (
        <div className='d-flex justify-content-center h-100 pt-5 px-5 p-lg-20'>
            <div style={{maxWidth: '500px'}}>
                <div data-kt-stepper-element='content'>
                    {
                        displayStoreCard ? (
                            <>
                                <div className='card p-5' data-kt-stepper-element='content'>
                                    <StoredCard
                                        submitNextStepHandle={submitNextStepHandle}
                                        displayStoreCard={displayStoreCard}
                                        setDisplayStoreCard={setDisplayStoreCard}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div data-kt-stepper-element='content'>
                                    <AddNewCard submitNextStepHandle={submitNextStepHandle} />
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
