import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IGlobalIntegrations{
    id:number,
    status:number,
    code:string,
    name:string,
    [key: string]: any
}

export interface GlobalSettingsState {
    isLoading: boolean,
    global_default_integrations: null|IGlobalIntegrations,
}

const initialState: GlobalSettingsState = {
    isLoading: false,
    global_default_integrations: null,
}

export const globalSettingsSlice = createSlice({
    name: 'globalSettings',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setGlobalDefaultIntegrations: (state, action:PayloadAction<IGlobalIntegrations>) => {
            state.global_default_integrations = action.payload
            state.isLoading = false
        },
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setGlobalDefaultIntegrations } = globalSettingsSlice.actions
