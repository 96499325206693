export enum FOI_OPTION {
   PASSPORT = 'id.passport',
   PASSPORT_CARD = 'id.passport_card',
   PASSPORT_CARD_BACK = 'id.passport_card_back',
   OTHER_ID = 'id.other_id',
   OTHER_ID_BACK = 'id.other_id_back',
   LICENSE = 'id.driver_license',
   LICENSE_BACK = 'id.driver_license_back',
   STATE_ID = 'id.government_issued_id',
   STATE_ID_BACK = 'id.government_issued_id_back',
   MX_VOTER_ID = 'id.mexican_voter_id',
   MX_VOTER_ID_BACK = 'id.mexican_voter_id_back',
   EMPLOYMENT_AUTHORIZATION = 'id.employment_authorization',
   EMPLOYMENT_AUTHORIZATION_BACK = 'id.employment_authorization_back',
   PERMANENT_RESIDENT = 'id.permanent_resident',
   PERMANENT_RESIDENT_BACK = 'id.permanent_resident_back',
   SELFIE = 'id.selfie',
}

export enum RESERVATION_REVIEW_STEPS {
   PREVIEW = 'Preview Your Reservation',
   VERIFY_IDENTITY = 'Verify Your Identity',
   ADD_GUEST = 'Add Guest',
   ADD_PAYMENT_METHOD = 'Add Payment Method',
}

export enum GUEST_TYPE {
   DEPENDANT = 'Dependant Guest',
   OTHER = 'Guest',
}

export enum DEPENDANT_TYPE {
   CHILD = 1,
   TEEN = 2,
   SPOUSE = 3,
   OTHER = 4,
}

export const API_URL = process.env.REACT_APP_SP_API_URL
export const APP_GLOBAL_ON = process.env.REACT_APP_GLOBAL_ON

export const genderTypes = {
   prefer_not_to_answer: 'gender.prefer_not_to_answer',
   male: 'gender.male',
   female: 'gender.female',
}

export const constants = {
   session: {
      life_time: 1800, //seconds - 1800 = 30 minutes
   },
   mobile_environments: {
      //Values from: .env => REACT_APP_MOBILE_ENV
      local: 'Local',
      dev: 'Development',
      staging: 'Staging',
      sandbox: 'Sandbox',
      live: 'Live',
   },
   flow: {
      register: 'register',
      profile: 'profile',
      pre_checkin: 'checkin',
      kiosk_checkin: 'kiosk_checkin',
      order: 'order',
      checkout: 'checkout',
      reservations: 'reservations',
      public_receipt: 'public_receipt',
      payment_receipt: 'payment_receipt',
      tsys_form: 'tsys_form',
      olo_checkout: 'OLO_Checkout',
      qr_badge: 'qr_badge',
      table: 'table',
   },
   paymentMethod: {
      instore: 'In-Store',
      giftCard: 'Gift Card',
      creditCard: 'Credit Card',
      roomCharge: 'Room Charge',
   },
   CardOperation: {
      addCart: 'Add Card',
      editCard: 'Edit Card',
   },
   cookies_name: {
      temp_access_token: 'TAT_API',
      access_token: 'AT_API',
      location_domain_name: 'mobile.location_domain_name',
      location_id: 'mobile.location_id',
      reservation_id: 'mobile.reservation_id',
      guest_id: 'mobile.register.guest_id',
      guest_registration_id: 'mobile.register.guest_registration_id',
      sourceFlow: 'mobile.register.sourceFlow',
      source_of_business: 'source_of_business',
      register: {
         phone_number: 'mobile.register.phone',
         global_country_id: 'mobile.register.global_country_id',
         register_done: 'mobile.register.register_done',
         selfie_image_temp_path: 'mobile.register.selfie_image_temp_path',
         id_image_temp_path: 'mobile.register.id_image_temp_path',
         id_me: 'user_redirect_IDME',
      },
      my_account: {
         my_account: 'mobile.my_account.active',
      },
   },
   global_on: {
      dispenser_kiosk: 14,
      created_on_tb: 14,
      online_ordering: 4,
      kiosk_web: 3,
   },
   processors: {
      tsys: {
         global_processor_id: 8,
      },
      evertec: {
        global_processor_id: 30,
      },
    softpoint: {
         global_processor_id: 28,
         global_processor_code: 'SOFTPOINT',
      },
      cybersource: {
        global_processor_id: 24,
      },
   },
   products: {
      biometrics: {
         global_product_id: 7,
      },
      id: {
         global_product_id: 8,
      },
      qr_code: {
         global_product_id: 4,
      },
      olo: {
         global_product_id: 3,
      },
   },
   integrations_activity_logs: {
      types: {
         IFRAME_REQUEST: {
            id: 9,
            code: 'IFRAME_REQUEST',
            name: 'Iframe Request',
         },
      },
   },
   integrations: {
      id_me: {
         code: 'IDME',
         auth_path: 'oauth/authorize',
         callback_path: 'idme/callback',
         global_integration_id: 6,
      },
      clear: {
         code: 'CLEAR',
         global_integration_id: 56,
      },
      facial_recognition: {
         true_face: {
            global_integration_id: 2,
            code: 'TF',
         },
      },
      qr_code: {
         global_integration_id: 26,
      },
      olo: {
         global_integration_id: 25,
      },
      ocr: {
         global_integration_id: 46,
      },
      pdf417: {
         global_integration_id: 47,
      },
      checkin: {
         global_ids: [24, 41],
         providers: {
            softpoint: {
               global_integration_id: 24,
            },
            vidanta: {
               global_integration_id: 41,
            },
         },
      },
      reservations: {
         global_ids: [27],
         providers: {
            softpoint: {
               global_integration_id: 27,
            },
         },
      },
   },
   errors: {
      auth: {
         access_token_invalid: 10759,
         id_integration_profile_found_but_not_reservation_code: 10689,
         biometrics_profile_not_found_code: 10690,
         id_integration_profile_not_found_message: `No profile found, would you like to be verified by phone?`,
         no_upcoming_reservations_code: 10689,
         biometrics_face_mask_detected_code: 10833,
         biometrics_glasses_detected_code: 10887,
         biometrics_not_face_detected_code: 10685,
         biometrics_obstruction_detected_code: 10890,
         biometrics_face_not_centered_code: 10828,
         biometrics_face_too_close_code: 10829,
         biometrics_low_quality_code: 10827,
         biometrics_face_too_far_code: 10830,
         biometrics_eyes_close_code: 10831,
         biometrics_many_guests_found: 10904,
         biometrics_minimum_match_supported: 11056,
         biometrics_server_offline: 10416,
         guest_doesnt_exist: 10225,
         no_guest_registration: 10686, //Guest Exists but is not registered in this location. Not more steps necessary to finish the registration
         no_guest_registration_missing_steps: 10871, //Guest Exists but is not registered in this location. Missing Steps to finish register
         images_not_similar: 10836,
         guest_already_exist_in_location: 10351,
         pending_registration_approval: 10980,
         biometric_image_too_dark: 10834,
         biometric_image_too_light: 10835,
         selfie_too_blurry: 11020,
         selfie_extreme_yaw: 11028,
         selfie_extreme_pitch: 11027,
         two_fa_not_found: 10663,
      },
      static: {
         unexpected_contact_support: 'error.alert.unexpected_contact_support',
         unexpected_redirect: 'error.alert.unexpected_redirect',
         default_error: 'error.alert.default_error',
         biometrics_server_offline: 'error.alert.biometrics_server_offline',
         request_timeout_message: 'error.alert.request_timeout_message',
         vital_parse_error: 'error.alert.vital_parse_error',
      },
      price_check: {
         feature_not_supported: 10659,
      },
      ocr_errors: {
         id_reader_first_name_not_identified: 10960,
         id_reader_last_name_not_identified: 10961,
         id_reader_dob_not_identified: 10962,
         id_reader_country_not_identified: 10963,
         id_reader_state_not_identified: 10964,
         id_reader_expiration_not_identified: 10965,
         id_reader_data_not_identified: 10966,
         id_reader_document_not_supported: 10967,
         id_reader_request_timeout: 408, // error code for request timeout
      },
      reservations: {
         guest_not_found_reservation: 10678,
      },
   },
   staticPageTitle: {
      login: 'pagetitle.login',
      verify_number: 'pagetitle.verify_number',
      verify_id: 'pagetitle.verify_id',
      landing_screen: 'pagetitle.landing_screen',
      take_a_photo: 'pagetitle.take_a_photo',
      identity: 'pagetitle.identity',
      consent: 'pagetitle.consent',
      checkin: 'pagetitle.checkin',
      confirmation: 'pagetitle.confirmation',
      reservation: 'pagetitle.reservation',
      confirmed: 'pagetitle.confirmed',
      registered: 'pagetitle.registered',
      add_guests: 'pagetitle.add_guests',
      add_payment_method: 'pagetitle.add_payment_method',
      two_fa: 'pagetitle.two_fa',
      Authenticated: 'pagetitle.Authenticated',
      Activate: 'pagetitle.Activate',
      my_profile: 'pagetitle.my_profile',
      dashboard: 'pagetitle.dashboard',
      my_account: 'pagetitle.my_account',
      receipt: 'pagetitle.receipt',
      receipt_payment: 'pagetitle.receipt_payment',
      findTicket: 'pagetitle.findTicket',
      payment: 'pagetitle.payment',
      select_stored_card: 'pagetitle.select_stored_card',
      add_new_card: 'pagetitle.add_new_card',
      form_of_identity: 'pagetitle.form_of_identity',
      registration_review: 'pagetitle.registration_review',
      camera_permissions: 'pagetitle.camera_permissions',
      order_checkout: 'pagetitle.order_checkout',
      connected_accounts: 'pagetitle.connected_accounts',
      delete_account: 'pagetitle.delete_account',
   },
   status: {
      ticket: {
         open: 'OPEN',
      },
      payments: {
         paid: 1,
         declined: 2,
         refunded: 3,
         auth: 4,
      },
      location: {
         appearance: {
            lightMode: 1,
            darkMode: 0,
         },
      },
      guests: {
         location_subscribed: 2,
      },
      ON: 1,
      OFF: 0,
      iframe_register_session: {
         awaiting_user_input: 1,
         success: 2,
         pending_registration: 3,
         manual_rejected: 4,
      }
   },
   payments: {
      actions: {
         sale: 'sale',
         token: 'token',
      },
   },
   session_storage_items: {
      images: {
         selfie_thumbnail: 'selfie_thumbnail',
         id_form_thumbnail: 'id_form_thumbnail',
      },
   },
   theme: {
      lightMode: '#fffff',
      darkMode: '#151521',
   },
   routes: {
      public: {
         olo: 'olo',
         olo_menu: 'olo/menu',
         olo_checkout: 'olo/checkout',
      },
      private: {
         auth: 'auth',
         payment_receipt: 'payment-receipt',
         pre_checkin: 'pre-checkin',
         pre_checkin_review: 'pre-checkin/review',
         pre_checkin_preview_reservation: 'pre-checkin/preview-reservation',
         pre_checkin_verify_your_identity: 'pre-checkin/verify-your-identity',
         pre_checkin_add_guests: 'pre-checkin/add-guests',
         pre_checkin_select_payment_method: 'pre-checkin/select-payment-method',
         pre_checkin_confirmation_done: 'pre-checkin/confirmation-done',
         kiosk_checkin: 'activate',
         my_account: 'my-account',
         register: 'register',
         submission_review: 'register/submission_review',
         share_guest: '/register/share_guest',
         registration_terms: '/register/terms',
         registration_forms: '/register/identity_forms',
         registration_verification: '/register/identity_verification',
         registration_pay_method: '/register/add_pay_method',
         loyalty_enrollment: '/register/loyalty_enrollment',
         card_review: '/register/card_review'
      },
   },
   relationTypes: [
      {id: DEPENDANT_TYPE.CHILD, name: 'relation.child'},
      {id: DEPENDANT_TYPE.TEEN, name: 'relation.teen'},
      {id: DEPENDANT_TYPE.SPOUSE, name: 'relation.spouse'},
      {id: DEPENDANT_TYPE.OTHER, name: 'relation.other'},
   ],

   GenderTypes: [
      {id: 0, name: genderTypes.prefer_not_to_answer},
      {id: 1, name: genderTypes.male},
      {id: 2, name: genderTypes.female},
   ],

   bioMetricDataType: [
      {id: 1, type: FOI_OPTION.SELFIE, lowerId: 'selfie'},
      {id: 2, type: FOI_OPTION.OTHER_ID, lowerId: 'id'},
      {id: 3, type: FOI_OPTION.OTHER_ID_BACK, lowerId: 'id_back'},
      {id: 4, type: FOI_OPTION.PASSPORT, lowerId: 'passport'},
      {id: 5, type: FOI_OPTION.PASSPORT_CARD, lowerId: 'passport_card_front'},
      {id: 6, type: FOI_OPTION.PASSPORT_CARD_BACK, lowerId: 'passport_card_back'},
      {id: 7, type: FOI_OPTION.LICENSE, lowerId: 'driver_license_front'},
      {id: 8, type: FOI_OPTION.LICENSE_BACK, lowerId: 'driver_license_back'},
      {id: 9, type: FOI_OPTION.STATE_ID, lowerId: 'state_id_front'},
      {id: 10, type: FOI_OPTION.STATE_ID_BACK, lowerId: 'state_id_back'},
      {id: 11, type: FOI_OPTION.MX_VOTER_ID, lowerId: 'mx_voter_id_front'},
      {id: 12, type: FOI_OPTION.MX_VOTER_ID_BACK, lowerId: 'mx_voter_id_back'},
      {id: 13, type: FOI_OPTION.EMPLOYMENT_AUTHORIZATION, lowerId: 'employment_authorization'},
      {
         id: 14,
         type: FOI_OPTION.EMPLOYMENT_AUTHORIZATION_BACK,
         lowerId: 'employment_authorization_back',
      },
      {id: 15, type: FOI_OPTION.PERMANENT_RESIDENT, lowerId: 'permanent_resident'},
      {id: 16, type: FOI_OPTION.PERMANENT_RESIDENT_BACK, lowerId: 'permanent_resident_back'},
   ],

   validations: {
      guests: {
         min_age_for_adult: 16,
      },
   },
   global_guests_pending_registration_reasons: {
      selfie_and_id_not_match: 1,
      ocr_invalid_data: 2,
      pdf4_invalid_data: 3,
      other_id_selected: 4,
      front_and_back_id_doesnt_match: 5,
   },
   averageCCExpireYear: 8
}
