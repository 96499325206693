import {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import {Link} from 'react-router-dom'
import { getSoftPointLogo } from '../../../common/Utilities'

const Error500: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      {/*begin::Authentication - Error 500 */}
      <div className='d-flex flex-column flex-column-fluid'>
        {/*begin::Content*/}
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-15'>
          {/*begin::Logo*/}
          <Link to='/' className='mb-10 pt-lg-10'>
            <img
              alt='Logo'
              src={getSoftPointLogo()}
              className='h-40px mb-5'
            />
          </Link>
          {/*end::Logo*/}
          {/*begin::Wrapper*/}
          <div className='pt-lg-10 mb-10'>
            {/*begin::Logo*/}
            <h1 className='fw-bolder fs-2qx text-gray-800 mb-10'><FormattedMessage id='error.500.system_error' defaultMessage='System Error' /></h1>
            {/*end::Logo*/}
            {/*begin::Message*/}
            <div className='fw-bold fs-3 text-muted mb-15'>
            <FormattedMessage id='error.500.something_went_wrong' defaultMessage='Something went wrong!'/>
              <br />
              <FormattedMessage id='error.500.please_try_later' defaultMessage='Please try again later'/>.
            </div>
            {/*end::Message*/}
            {/*begin::Action*/}
            <div className='text-center'>
              <Link to='/' className='btn btn-lg btn-primary fw-bolder'>
              <FormattedMessage id='error.500.go_to_homepage' defaultMessage='Go to homepage' />
              </Link>
            </div>
            {/*end::Action*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Illustration*/}
          <div
            className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
            style={{
              backgroundImage: `url('${'/media/illustrations/sketchy-1/17.png'}')`,
            }}
          ></div>
          {/*end::Illustration*/}
        </div>
        {/*end::Content*/}
        {/*begin::Footer*/}
        <div className='d-flex flex-center flex-column-auto p-10'>
          {/*begin::Links*/}
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a href='#' className='text-muted text-hover-primary px-2'>
            <FormattedMessage id='error.500.about' defaultMessage='About' />
            </a>
            <a href='#' className='text-muted text-hover-primary px-2'>
            <FormattedMessage id='error.500.contact' defaultMessage='Contact' />
            </a>
            <a href='https://1.envato.market/EA4JP' className='text-muted text-hover-primary px-2'>
            <FormattedMessage id='error.500.contact_us' defaultMessage='Contact Us' />
            </a>
          </div>
          {/*end::Links*/}
        </div>
        {/*end::Footer*/}
      </div>
      {/*end::Authentication - Error 500*/}
    </div>
  )
}

export {Error500}
