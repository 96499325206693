import React, {useEffect, useRef} from "react";
import {ApiCall} from "../../../services/ApiCallService";
import {IPayReviewPayload} from "../requests/PostTicketReview";

export interface IHeartRating{
    currentRating:number,
    setRating:React.Dispatch<React.SetStateAction < number >>,
    setHeartClick:React.Dispatch<React.SetStateAction < boolean >>,
    ticket_review:number
}

export const HeartRating = (props:IHeartRating) => {

    const currentRating = props.currentRating
    const setRating = props.setRating
    const setHeartClick = props.setHeartClick
    const rating1 = useRef<null|HTMLInputElement>(null)
    const rating2 = useRef<null|HTMLInputElement>(null)
    const rating3 = useRef<null|HTMLInputElement>(null)
    const rating4 = useRef<null|HTMLInputElement>(null)
    const rating5 = useRef<null|HTMLInputElement>(null)
    const ratingPoints = [
        {id:'kt_rating_2_input_1', ref:rating1},
        {id:'kt_rating_2_input_2', ref:rating2},
        {id:'kt_rating_2_input_3', ref:rating3},
        {id:'kt_rating_2_input_4', ref:rating4},
        {id:'kt_rating_2_input_5', ref:rating5}
    ]

    //RATING INITIALIZER
    useEffect(()=>{
        if(props.ticket_review > 0 && props.ticket_review <= ratingPoints.length){
            for(let index = 0; index <= props.ticket_review-1; index++){
                const ratingPoint = ratingPoints[index]
                if(ratingPoint.ref.current){
                    ratingPoint.ref.current.checked = true
                }
            }
            //setRating(props.ticket_review)
        }
    })

    // RATING LISTENER INITIALIZER
    useEffect(()=>{
        const ratingLabels = document.querySelectorAll('.rating-label')
        if(ratingLabels.length > 0){
            ratingLabels.forEach((labelNode)=>{
                labelNode.addEventListener('click', ratingPointHandler)
            })
        }
        return () => {
            const ratingLabels = document.querySelectorAll('.rating-label')
            if(ratingLabels.length > 0){
                ratingLabels.forEach((labelNode)=>{
                    labelNode.removeEventListener('click', ratingPointHandler)
                })
            }
        }
    }, [])

    useEffect(()=>console.log(currentRating),[currentRating])

    const ratingPointHandler = (event:Event) => {
        const label = event.currentTarget as HTMLLabelElement
        const labelsInputID = label.getAttribute('for')
        if(labelsInputID == null){
            throw new Error('Label missing for value.')
        }
        // console.log(labelsInputID)
        const selectedPointIdx = ratingPoints.findIndex(point => {
            return point.id == labelsInputID
        })
        if(selectedPointIdx == -1){
            throw new Error("Label missing corresponding input.")
        }
        // console.log(selectedPointIdx)
        const selectedPoint = ratingPoints[selectedPointIdx]
        const selectedRatingRef = selectedPoint.ref.current
        if(selectedRatingRef == null){
            throw new Error("Missing input.")
        }


        ratingPoints.forEach((point, index) => {
            const ratingPointRef = point.ref.current
            if(ratingPointRef == null){
                throw new Error("Missing input.")
            }
            //Input check true if index is less than or equal to the selected index
            ratingPointRef.checked = index <= selectedPointIdx;
        })

        //TODO: POST TICKET REVIEW
        let postBody:IPayReviewPayload
        setRating(parseInt(selectedRatingRef.value))
        setHeartClick(true);
    }


    return (
        <div className='rating'>
            {/*RESET BUTTON*/}
            {/*<label*/}
            {/*    className="btn btn-light fw-bolder btn-sm rating-label me-3"*/}
            {/*    htmlFor="kt_rating_input_0"*/}
            {/*>*/}
            {/*</label>*/}
            {/* <input*/}
            {/*    className="rating-input"*/}
            {/*    name="rating2"*/}
            {/*    value="0"*/}
            {/*    checked*/}
            {/*    onChange={() => { }}*/}
            {/*    type="radio"*/}
            {/*    id="kt_rating_2_input_0"*/}
            {/*/> */}
            <input
                ref={rating1}
                className="rating-input"
                name="rating2"
                value="1"
                type="radio"
                checked
                id="kt_rating_2_input_0"
            />
            <label className="rating-label me-2" htmlFor="kt_rating_2_input_1">
                <i className={`bi bi-suit-heart fs-1`}/>
            </label>
            <input
                ref={rating1}
                className="rating-input"
                name="rating2"
                value="1"
                type="radio"
                id="kt_rating_2_input_1"
            />

            <label className="rating-label me-2" htmlFor="kt_rating_2_input_2">
                <i className='bi bi-suit-heart fs-1'/>
            </label>
            <input
                ref={rating2}
                className="rating-input"
                name="rating2"
                value="2"
                type="radio"
                id="kt_rating_2_input_2"
            />

            <label className="rating-label me-2" htmlFor="kt_rating_2_input_3">
                <i className='bi bi-suit-heart fs-1'/>
            </label>
            <input
                ref={rating3}
                className="rating-input"
                name="rating2"
                value="3"
                type="radio"
                id="kt_rating_2_input_3"
            />

            <label className="rating-label me-2" htmlFor="kt_rating_2_input_4">
                <i className='bi bi-suit-heart fs-1'/>
            </label>
            <input
                ref={rating4}
                className="rating-input"
                name="rating2"
                value="4"
                type="radio"
                id="kt_rating_2_input_4"
            />

            <label className="rating-label me-2" htmlFor="kt_rating_2_input_5">
                <i className='bi bi-suit-heart fs-1'/>
            </label>
            <input
                ref={rating5}
                className="rating-input"
                name="rating2"
                value="5"
                type="radio"
                id="kt_rating_2_input_5"
            />
            {/*<span>{currentRating}</span>*/}
        </div>
    )
}
