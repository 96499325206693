import {Outlet, useLocation, useMatch, useNavigate, useOutletContext} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../store'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useLocationDomain} from '../../common/hooks/CommonHooks'
import {useResetOrder} from './hooks/order_session'
import {getOloSettings} from './common/parsers'
import {resetOrder} from '../../services/store/ordering/GuestOrder/OrderSlice'

type SessionIntervalContext = {
   sessionId: number | null
   updateSessionId: (newId: number) => void
}

const SESSION_DURATION = 60
export const MILLI_SESSION_DURATION = SESSION_DURATION * 60000

export const OloController = () => {
   const locationDomain = useLocationDomain()
   const baseURL = '/' + locationDomain
   const navigateTo = useNavigate()
   const appDispatch = useDispatch()
   const OLO_BASE_ROUTE = '/:location_id/olo'
   const isBaseOloRoute = useMatch(OLO_BASE_ROUTE)
   const locSettings = useSelector((state: RootState) => state.LocSettings.value)
   const orderSession = useSelector((state: RootState) => state.order.session)
   const oloSettings = getOloSettings(locSettings)

   const [sessionId, updateSessionId] = useState<number | null>(null)
   const resetOrderSession = useResetOrder({
      resetType: 'Timer',
      sessionDuration: SESSION_DURATION,
   })

   const resetOrderCartFunction = () => {
      if (
         window.location.pathname.indexOf('olo') < 0 &&
         window.location.pathname.indexOf('my-wallet') < 0
      ) {
         resetOrderSession(locSettings.location.id)
      } else {
         const expirationStamp = moment().utc().add(SESSION_DURATION, 'minutes').format()
         appDispatch(
            resetOrder({
               location_id: locSettings.location.id,
               expiration_datetime: expirationStamp,
            })
         )
      }
   }

   //SESSION MONITOR
   useEffect(() => {
      //init order session
      if (!orderSession) {
         resetOrderSession(locSettings.location.id)

         const id = window.setInterval(
            resetOrderCartFunction,
            MILLI_SESSION_DURATION,
            locSettings.location.id
         )
         updateSessionId(id)
      }
      //Check time to live and if user switched location
      else {
         if (locSettings.location.id != orderSession.location_id) {
            resetOrderSession(locSettings.location.id)
            const id = window.setInterval(
               resetOrderCartFunction,
               MILLI_SESSION_DURATION,
               locSettings.location.id
            )
            updateSessionId(id)
         } else {
            const expirationMoment = moment.utc(orderSession.expiration_datetime)
            const currentMoment = moment().utc()
            const minutesToLive = moment.duration(expirationMoment.diff(currentMoment)).asMinutes()
            //If positive session still has time to live
            if (minutesToLive > 0) {
               const milliSecondsToLive = minutesToLive * 60000

               //30 seconds

               setTimeout(() => {
                  resetOrderCartFunction()
                  const id = window.setInterval(
                     resetOrderCartFunction,
                     MILLI_SESSION_DURATION,
                     locSettings.location.id
                  )
                  updateSessionId(id)
               }, milliSecondsToLive)
            } else {
               resetOrderSession(locSettings.location.id)
               const id = window.setInterval(
                  resetOrderCartFunction,
                  MILLI_SESSION_DURATION,
                  locSettings.location.id
               )
               updateSessionId(id)
            }
         }
      }
      document.title = `${locSettings.location.location_name} | Online Ordering`
      return () => {
         document.title = 'SoftPoint | Mobile'
         if (sessionId) {
            window.clearInterval(sessionId)
         }
      }
   }, [])

   useEffect(() => {
      if (isBaseOloRoute) {
         if (oloSettings.scheduled_orders) {
            navigateTo(baseURL + '/olo/menu')
         }
      }
   }, [])

   return <Outlet context={{sessionId, updateSessionId}} />
}

export const useOrderInterval = () => {
   return useOutletContext<SessionIntervalContext>()
}
