// CommonService.jsx
import axios from 'axios';
import {setupAxios} from '../modules/auth'
// @ts-ignore
import Cookies from 'js-cookie'
import {constants} from "../config/constants";

export interface IAPILocSettings{
    location:{
        id:string,
        owner:number|string
    },
    appearance: number,
    background_color: string,
    primary_color: string,
    [key: string]: any
}

export async function ApiCall(formData: any = {}, url: any, type: string, LocSettings: IAPILocSettings|any, url_without_location = false) {
    const {cookies_name} = constants;

    function encodeQueryData(fdata:any) {
        const ret = [];
        for (let d in fdata)
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(fdata[d]));
        return ret.join('&');
    }

    const API_URL = process.env.REACT_APP_SP_API_URL;
    const GLOBAL_ON = process.env.REACT_APP_GLOBAL_ON
    let location_id = LocSettings.location.id;
    let user_id = LocSettings.location.owner;
    const postData = {
        ...formData,
        global_on_id: GLOBAL_ON,
        user_id: user_id,
    };

    let instance = axios.create({
        baseURL:`${API_URL}/locations/${location_id}/`
    });

    if(url_without_location) {
        instance = axios.create({
            baseURL:`${API_URL}/`
        });
    }

    setupAxios(instance);
    let resStatus:any="";
    if(type=="POST"){
        const data=instance.post(url, postData).catch(function (error) {
            if(error?.response?.data?.error?.code === constants.errors.auth.access_token_invalid) {
                Cookies.remove(cookies_name.temp_access_token)
                Cookies.remove(cookies_name.access_token)
            }
            return error.response.data;
        });
        await data.then(val=>{resStatus=val}).catch((error) => {
            throw error;
        });

        return data;
    }else if(type=="IMAGE"){
            let config1 = {
                headers: {
                    ...axios.defaults.headers.common,
                    'Content-Type': 'multipart/form-data',
                }
            }
            const data=instance.post(url, formData, config1).catch(function (error) {
                if(error?.response?.data?.error?.code === constants.errors.auth.access_token_invalid) {
                    Cookies.remove(cookies_name.temp_access_token)
                    Cookies.remove(cookies_name.access_token)
                }
                return error.response.data;
            });        
            await data.then(val=>{resStatus=val}).catch((error) => {
                throw error;
            });
    
            return data;        
    }else if(type=="PUT"){
        const data=instance.put(url, postData).catch(function (error) {
            if(error?.response?.data?.error?.code === constants.errors.auth.access_token_invalid) {
                Cookies.remove(cookies_name.temp_access_token)
                Cookies.remove(cookies_name.access_token)
            }
            return error.response.data;
        });
        await data.then(val=>{resStatus=val}).catch((error) => {
            throw error;
        });
        return data;
    }else if(type=="GET"){
        const data=instance.get(`${url}?${encodeQueryData(postData)}`).catch(function (error) {
            if(error?.response?.data?.error?.code === constants.errors.auth.access_token_invalid) {
                Cookies.remove(cookies_name.temp_access_token)
                Cookies.remove(cookies_name.access_token)
            }
            return error?.response?.data;
        });
        await data.then(val=>{resStatus=val}).catch((error) => {
            throw error;
        });
        return data;
    }else if(type=="DELETE"){
        const data=instance.delete(`${url}?${encodeQueryData(postData)}`).catch(function (error) {
            if(error?.response?.data?.error?.code === constants.errors.auth.access_token_invalid) {
                Cookies.remove(cookies_name.temp_access_token)
                Cookies.remove(cookies_name.access_token)
            }
            return error?.response?.data;
        });
        await data.then(val=>{resStatus=val}).catch((error) => {
            throw error;
        });
        return data;
    }
}
