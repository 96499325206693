import { useState, useEffect } from 'react';
import { useErrorAlert, useLoadingAlert } from '../../../../utilities/alerts';
import * as ApiCallService from '../../../../services/ApiCallService'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom';
import { constants } from '../../../../config/constants';
import { setIframeSessionId } from '../../../../services/SourceSlice';
import {useAddParameterToUrl} from "../../../../common/hooks/CommonHooks";

interface IframeSessionProps {
    loading: boolean;
    activateRegistrationSession: (iframe_register_status?:any) => (void);
}

const useIframeSession = (): IframeSessionProps => {
   const intl = useIntl()
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    const iframe_session_id = useSelector((state: RootState) => state.Source.iframe_session_id)
    const guest: any = useSelector((state: RootState) => state.guest)
    const [loading, setLoading] = useState<boolean>(false);
    const fireErrorAlert = useErrorAlert()
    const fireLoadingAlert = useLoadingAlert()
    const navigateTo = useNavigate()
    const {addParameterToUrl} = useAddParameterToUrl()
    const base_domain = LocSettings.base_domain
    const {routes} = constants;
    const dispatch = useDispatch<AppDispatch>()

    const activateRegistrationSession = (iframe_register_status = constants.status.iframe_register_session.success) => {
        if(iframe_session_id == null) {
            console.error('activateRegistrationSession() called when session is null');
            return false;
        }
        if(guest?.data?.id == undefined) {
            console.error('guest id is not available');
            return false;
        }

        setLoading(true);
        fireLoadingAlert("Loading...")
        
        let payload = {
            "session_id": iframe_session_id,
            "location_id": LocSettings.location.id,
            "status": iframe_register_status,
            "guest_id": guest.data.id,
        }

        ApiCallService.ApiCall(payload, `/auth-iframe/update-session`, 'PUT', LocSettings, true)
        .then((res: any) => {
            setLoading(false);
            Swal.close()
            dispatch(setIframeSessionId(undefined))
            console.log("response", res)
            let callback_url = res?.data?.callback_url
            if (callback_url){
                callback_url = addParameterToUrl(callback_url, 'sp_session', iframe_session_id)
                window.open(callback_url, '_self')
                return
            }else if (res.error !== undefined) {
                fireErrorAlert(res.error.message)
            }else{
                fireErrorAlert(intl.formatMessage({id:"common.alert.error_sending_data"}))
            }
            navigateTo(`${base_domain}/${routes.private.submission_review}`)
        })
        .catch((err) => {
            console.log("error?", err)
            setLoading(false);
            Swal.close()
            dispatch(setIframeSessionId(undefined))
            fireErrorAlert(intl.formatMessage({id:"common.alert.error_sending_data"}))
            navigateTo(`${base_domain}/${routes.private.submission_review}`)
        })
    };

    return { loading, activateRegistrationSession };
}

export default useIframeSession;
