import React, {CSSProperties, useEffect, useState} from 'react'

export type SecureImageAttributes = {
    src:string,
    alt?:string,
    style?:CSSProperties,
    className?:string,
}

export type SecureImgProps = {
    imageAttributes:SecureImageAttributes
    placeholder?:JSX.Element,
    headers:HeadersInit,
    handlePreProcessing?:(image:HTMLImageElement)=>Promise<HTMLCanvasElement>
}


export const SecureImg = ({imageAttributes, handlePreProcessing, headers, placeholder}:SecureImgProps) =>
{
    const {src, alt='', style = {}, className = ''} = imageAttributes
    const [imageSrc, setImageSrc] = useState<string|null>(null);

    useEffect(() => {
        async function fetchImage() {
            try {
                const response = await fetch(src, { headers });
                if (!response.ok) {
                    throw new Error(`Unable to fetch image, status: ${response.status}`);
                }
                const blob = await response.blob();

                if(handlePreProcessing){
                    const tempImage = new Image()
                    tempImage.src = URL.createObjectURL(blob)
                    tempImage.onload = async () => {
                        const canvas = await handlePreProcessing(tempImage)
                        setImageSrc(canvas.toDataURL())
                    }
                }
                else{
                    setImageSrc(URL.createObjectURL(blob))
                }
                // setImageSrc(URL.createObjectURL(blob))
            } catch (error) {
                console.error(error);
            }
        }

        fetchImage();
    }, [src, headers]);

    if(placeholder && imageSrc == null) {
        return placeholder;
    }



    return <img src={imageSrc??''} alt={alt} style={style} className={className} />;
}
