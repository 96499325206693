import {constants} from '../config/constants'
import {IGuest} from '../services/store/guests'
import moment from 'moment/moment'
import {doc} from 'prettier'
import axios, {AxiosError} from 'axios'
import {ValidationError} from 'yup'
import {instanceOfSpError, IStandardError} from './api_interfaces/Errors'

/**
 * This method returns the error codes associated to errors that are correctable by users.
 */
export const getCorrectableBiometricErrors = () => {
  const authErrors = constants.errors.auth
  return [
    authErrors.biometrics_eyes_close_code,
    authErrors.biometrics_face_too_close_code,
    authErrors.biometrics_not_face_detected_code,
    authErrors.biometric_image_too_dark,
    authErrors.biometric_image_too_light,
    authErrors.biometrics_glasses_detected_code,
    authErrors.biometrics_face_mask_detected_code,
    authErrors.biometrics_obstruction_detected_code,
    authErrors.biometrics_low_quality_code,
    authErrors.biometrics_face_too_far_code,
    authErrors.biometrics_face_not_centered_code,
    authErrors.selfie_too_blurry,
    authErrors.selfie_extreme_pitch,
    authErrors.selfie_extreme_yaw,
  ]
}

export const getOcrPdf417Errors = () => {
  const errors = constants.errors.ocr_errors
  return [
    errors.id_reader_first_name_not_identified,
    errors.id_reader_last_name_not_identified,
    errors.id_reader_dob_not_identified,
    errors.id_reader_country_not_identified,
    errors.id_reader_state_not_identified,
    errors.id_reader_expiration_not_identified,
    errors.id_reader_data_not_identified,
    errors.id_reader_document_not_supported,
  ]
}

export const getSoftPointLogo = (): string => {
  let darkModeLogo = '/media/logos/darkModeFullSoftPointLogo.svg'
  let lightModeLogo = '/media/logos/lightModeFullSoftPointLogo.svg'
  return localStorage.getItem('kt_theme_mode_value') === 'light' ? lightModeLogo : darkModeLogo
}

/**
 * Returns the number of seconds left on the current guest session.
 * The method will return 0 if guest is not authenticated or session ttl is negative.
 * @param guest
 */
export const getGuestSessionTTL = (guest: IGuest) => {
  if (!guest.auth.isAuthenticated || guest.auth.startedAt == null) return 0
  const now = moment()
  const ttlInSeconds = constants.session.life_time - now.diff(guest.auth.startedAt, 'seconds')

  if (ttlInSeconds <= 0) return 0

  return ttlInSeconds
}

/**
 * True if undefined or empty string or null
 * @param variable
 */
export const isEmpty = (variable: any) => {
  if (variable === undefined) return true
  else if (variable === '') return true
  else return variable === null
}

/**
 * The method attempts to crop a square from the center of an image.
 * If successful the method will return a data URL of the new cropped image.
 * @param tempImage image
 */
export const cropSquareImage = (tempImage: HTMLImageElement) => {
  return new Promise<HTMLCanvasElement>((resolve, reject) => {
    const virtualCanvas = document.createElement('canvas')
    const canvasContext = virtualCanvas.getContext('2d')

    if (!canvasContext) {
      return reject(null)
    }

    //Check if image is within an acceptable ratio range
    const ratio = tempImage.width / tempImage.height
    const isOnetoOne = ratio >= 9.9 && ratio <= 1.1
    if (isOnetoOne) {
      virtualCanvas.height = tempImage.height
      virtualCanvas.width = tempImage.width
      canvasContext.drawImage(tempImage, 0, 0)
      return resolve(virtualCanvas)
    }

    const imageInLandscape = tempImage.height / tempImage.width < 1
    if (imageInLandscape) {
      virtualCanvas.height = tempImage.height
      virtualCanvas.width = tempImage.height
      const diff = tempImage.width - tempImage.height
      canvasContext.drawImage(
        tempImage,
        0,
        diff / 2,
        tempImage.height,
        tempImage.height,
        0,
        0,
        tempImage.height,
        tempImage.height
      )
      return resolve(virtualCanvas)
    } else {
      virtualCanvas.height = tempImage.width
      virtualCanvas.width = tempImage.width
      const diff = tempImage.height - tempImage.width
      canvasContext.drawImage(
        tempImage,
        0,
        diff / 2,
        tempImage.width,
        tempImage.width,
        0,
        0,
        tempImage.width,
        tempImage.width
      )
      return resolve(virtualCanvas)
    }
  })
}

/**
 * The method attempts to parse out an API response error returned by an Axios request.
 * @param error
 */
export const parseRequestError = (error: any | AxiosError | ValidationError): IStandardError => {
  if (error instanceof ValidationError) {
    return {
      code: -2,
      message: constants.errors.static.vital_parse_error,
      details: null,
    }
  }

  if (axios.isAxiosError(error)) {
    const response = error.response
    if (response && instanceOfSpError(response.data)) {
      return response.data.error
    }

    return {
      code: -1,
      message: constants.errors.static.unexpected_contact_support,
      details: null,
    }
  } else {
    return {
      code: -1,
      message: constants.errors.static.unexpected_contact_support,
      details: null,
    }
  }
}

/**
 * Attempts to convert the object provided into a query string.
 * The question mark component is omitted from the final return value of the method.
 * If a value of a key value pair in the object is not of type string or number or boolean
 *  it will be omitted from the final return value.
 * @param queryParameters
 * @return uri encoded string
 */
export const toQueryString = (queryParameters: Object) => {
  let queryString = ''
  Object.entries(queryParameters).forEach(([key, value], index) => {
    if (typeof value == 'number' || typeof value == 'string' || typeof value == 'boolean') {
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      if (index != Object.entries(queryParameters).length - 1) {
        queryString += '&'
      }
    }
  })
  return queryString
}
