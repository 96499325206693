import { createSlice } from '@reduxjs/toolkit'

export interface SidebarState {
    value: number,
}

const initialState: SidebarState = {
    value: 1,
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSidebarActiveMenu: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSidebarActiveMenu } = sidebarSlice.actions
