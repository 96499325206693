/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLayout } from '../../core'
import Inputmask from 'inputmask'
import { RootState, AppDispatch } from '../../../../app/store'
import { capitalizeWords, useLocationBasedBtnColor } from '../../../../app/utilities/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { removeItemFromCart } from '../../../../app/services/store/ordering/cart'
import { setCartItemIdToUpdate } from '../../../../app/services/store/ordering/cart/updateCartItemSlice'
import StaticRoutePath from '../../../../app/routing/StaticRoutePath'
import { useAppAlerts, useWarningConfirmAlert } from '../../../../app/utilities/alerts'
import { Field } from 'formik'
import {
  setFinalTipAmount,
  setGuestCardDetails,
  setGuestDraftCardDetails,
  setPaymentMethod,
  setPhoneNo,
  setSelectedItemId,
} from '../../../../app/services/store/ordering/GuestOrder/OrderSlice'
import { ICartItemState } from '../../../../app/services/store/ordering/cart/cartSlice'
import { IModifierGroup } from '../../../../app/pages/online_ordering/state/CartState'
import { setCartButtonDisplay } from '../../../../app/services/store/ordering/cart/CartButtonSlice'
import { KTSVG } from '../../../helpers'
import './CartbarItem.scss'
import { setSelectedCardToPay } from '../../../../app/services/store/ticket/payment'
import { useIntl } from 'react-intl'

const CartBarItem = ({ cartItem }: { cartItem: ICartItemState }) => {
  const intl = useIntl();
  const { classes } = useLayout()
  const cbarRef = useRef<HTMLDivElement | null>(null)
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  const guest = useSelector((state: RootState) => state.guest)
  const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON
  const dynamicBtnColor = useLocationBasedBtnColor()
  const dispatch = useDispatch<AppDispatch>()
  const fireWarningAlert = useWarningConfirmAlert()
  const navigateTo = useNavigate()
  const base_domain = LocSettings.base_domain
  const [itemModTotal, setItemModTotal] = useState(0)
  const capitalizedWord = capitalizeWords(cartItem.itemName)
  const [showAll, setShowAll] = useState(false)

  const cartItems = useSelector((state: RootState) => state.cartItems.data)

  const sortItems = (items: IModifierGroup[]): IModifierGroup[] => {
    const itemMap = new Map<number, IModifierGroup>();
    items.forEach(item => itemMap.set(item.id, item));

    const sortedItems: IModifierGroup[] = [];

    const addedItems = new Set<number>();

    const addItemAndChildren = (item: IModifierGroup) => {
      if (!addedItems.has(item.id)) {
        sortedItems.push(item);
        if(item.mod_groups)
          addedItems.add(item.id);
        items.forEach(child => {
          if (child.parent_modifier_id === item.id) {
            addItemAndChildren(child);
          }
        });
      }
    };

    items.forEach(item => {
      if (!item.parent_modifier_id) {
        addItemAndChildren(item);
      }
    });

    return sortedItems; 
  };

  let itemMods = sortItems(cartItem.mods)
 
  useEffect(() => {
    const modTotal =
      itemMods.reduce((acc, curr) => {
        return acc + curr.price
      }, 0) / 100
    setItemModTotal(modTotal)
    itemMods.length > 3 ? setShowAll(false) : setShowAll(true)
  }, [cartItem])

  if (Object.keys(LocSettings).length === 0) {
    return <></>
  }

  const removeItemFromCartClickHandler = (item: ICartItemState) => {
    dispatch(removeItemFromCart(item.id))
  }

  const handleclick = (item: ICartItemState) => {
    let categorySectionId = ''
    cartItems.forEach((e) => {
      if (e.itemId == item.itemId) categorySectionId = e.categorySectionId
    })

    dispatch(setCartItemIdToUpdate({ id: item.id, itemId: item.itemId }))

    let itemId = item.itemId
    let categoryName = item.categoryName
    dispatch(setSelectedItemId(item.itemId))
    dispatch(setCartButtonDisplay(false))

    navigateTo(base_domain + StaticRoutePath.OLO_ITEM_DETAILS, {
      state: { itemId, categoryName, categorySectionId },
    })
    const cartToggleRef: HTMLElement | null = document.getElementById('kt_cbar_mobile_toggle')
    if (cartToggleRef) {
      //Toggles cart render state
      cartToggleRef.click()
    }
  }
  const fontColorName = 'text-gray-700 fw-semibold fs-6'
  const fontColorPrice = 'text-gray-400 fs-6 fw-bold'
  const capitalizeItemTitle = capitalizeWords(cartItem.itemName)

  const handleCheckoutClick = () => {
    dispatch(setFinalTipAmount('0.00'))
    const cartToggleRef: HTMLElement | null = document.getElementById('kt_cbar_mobile_toggle')
    dispatch(setCartButtonDisplay(false))
    if (cartToggleRef) {
      //Toggles cart render state
      cartToggleRef.click()
      if (!guest.auth.isAuthenticated) {
        dispatch(setSelectedCardToPay({}))
        navigateTo(`${base_domain}/auth`)
        dispatch(setPaymentMethod(null))
        dispatch(setGuestCardDetails(null))
        dispatch(setGuestDraftCardDetails(null))
      } else {
        dispatch(setPaymentMethod('Credit Card'))
        navigateTo(`${base_domain}/olo/checkout`, { state: { checkoutPageAccess: true } })
      }
    }
  }
  const toggleShowAll = () => {
    setShowAll(!showAll)
  }
  return (
    <>
      <div className='row'>
        <div className='col-10'>
          <div className='p-0 m-0'>
            <table border={0} id='' cellPadding='5' cellSpacing='0' width='100%'>
              <tbody>
                <tr>
                  <td style={{ cursor: 'pointer' }} className={`text-start ps-0 py-0 m-0`}>
                    <span
                      className={`${fontColorName} d-flex`}
                      onClick={(e) => handleclick(cartItem)}
                    >
                      <span style={{ marginRight: '10px', marginLeft: '5px' }}>
                        {cartItem.itemQty}
                      </span>
                      <span>{capitalizeItemTitle}</span>
                    </span>
                    {/* will need to make this more dynamic -- wont know how many modifiers an item will have */}
                    {!showAll
                      ? itemMods.slice(0, 3).map((modName, index) => (
                        <div key={index} className={`col ${modName.parent_modifier_group_id ? "offset-5" : "offset-3"}`}>
                          <span className={`${fontColorName}`}>{modName.name}</span>
                        </div>
                      ))
                      : itemMods.map((modName, index) => (
                        <div key={index} className={`col ${modName.parent_modifier_group_id ? "offset-5" : "offset-3"}`}>
                          <span className={`${fontColorName}`}>{modName.name}</span>
                        </div>
                      ))}
                    <div
                      onClick={toggleShowAll}
                      className={`${itemMods.length > 3 ? 'd-block' : 'd-none'} col offset-3`}
                    >
                      <span className={`${fontColorName}`}>
                        {showAll ? intl.formatMessage({ id: "olo.show_less" }) : `+${itemMods.length - 3} ${intl.formatMessage({ id: "olo.checkout.more_choices" })}`}
                      </span>
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'top' }} className={`text-end p-0 ${fontColorPrice}`}>
                    $
                    {(
                      (cartItem.itemQty *
                        (cartItem.itemPrice +
                          itemMods.reduce((acc, curr) => {
                            return acc + curr.price
                          }, 0))) /
                      100
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className='col-2 ps-0 pe-8 text-end'
          key={Math.random()}
          style={{ display: 'inline-block', cursor: 'pointer' }}
        >
          <div
            className='btn-sm btn-icon btn-active-light-primary'
            onClick={(e) => removeItemFromCartClickHandler(cartItem)}
            id='kt_drawer_chat_close'
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr061.svg'
              className='removeCartItem svg-icon-2 text-primary'
            />
          </div>
        </div>
        {
          // cartItem.mods.map((m:ItemInfoModState)=>{
          //     return (
          //         <>
          //         <div className='col-12 w-100'>
          //             <div className='col-6' style={{display:'inline-block'}}>
          //             <small>{m.name}</small>
          //             </div>
          //             <div className='col-6' style={{display:'inline-block'}}>
          //             <small>{(m.price/100).toFixed(2)}</small>
          //             </div>
          //         </div>
          //         </>
          //     )
          // } )
        }
      </div>
    </>
  )
}

export { CartBarItem }
