import React, { useState, useEffect, useContext } from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import * as ApiCallService from '../../services/ApiCallService';
import { useSelector, useDispatch } from 'react-redux';
import type {AppDispatch, RootState} from '../../store';
import { StoreLocationSettings } from '../../services/AppSettingsSlice';
import { constants } from "../../config/constants";
import {useAppAlerts} from "../../utilities/alerts";


// @ts-ignore
import Cookies from 'js-cookie'
import CustomButton from "../../common/button/CustomButton";
import {clearReservation, getReservationByGuestId,setisTempReg,intReservationGuest} from "../../services/store/reservations";
import { HeaderTitleContext } from "../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore";
import { setSidebarActiveMenu } from "../../services/store/sidebar";
import {MenuComponent} from "../../../_spc/assets/ts/components";
import {LoadingPage} from "../../modules/widgets/Loaders/LoadingPage";
import {setFlow} from "../../services/SourceSlice";
import { FormattedMessage } from "react-intl";

const LandingPreCheckinPage = () => {
    const reservation = useSelector((state: any) => state.reservation);
    const location = useLocation();
    const themeModeLSKey = 'kt_theme_mode_value'
    const themeMenuModeLSKey = 'kt_theme_mode_menu'
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    const guest:any = useSelector((state: any) => state.guest);
    const SourceFlow = useSelector((state: RootState) => state.Source.flow)
    const { setPageTitle } = useContext(HeaderTitleContext);
    const dispatch = useDispatch<AppDispatch>()
    let navigateTo = useNavigate()
    const [fetchLocationLogo, setFetchLocationLogo] = useState('')
    const [locationName, setLocationName] = useState('')
    //default images for light and dark mode if no image is provided
    let lightModeLogo = '/media/logos/lightModeFullSoftPointLogo.svg';
    let darkModeLogo = '/media/logos/darkModeFullSoftPointLogo.svg';
    const base_domain = LocSettings.base_domain;
    const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON;
    const { location_id: location_id_or_name } = useParams();
    const { cookies_name,flow,routes } = constants;
    const {fireSuccessAlert, fireErrorAlert, fireLoadingAlert, fireConfirmAlert, fireMessageAlert} = useAppAlerts()
    let LocationLogo = '';
    if (LocSettings?.location?.logo) {
        LocationLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.location.logo}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`;
    }

    let LocationIcon = '';
    if (LocSettings?.icon) {
        LocationIcon = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.icon}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`;
    }
    const locationLogoInConfigurationProfile =
        localStorage.getItem('kt_theme_mode_value') === 'light'
            ? lightModeLogo
            : darkModeLogo
    const [fetchBackGroundMultiMedia, setFetchBackGroundMultiMedia] = useState('')
    
    const redirectToPage = () => {
        if(guest?.auth?.isAuthenticated==true){
            navigateTo(base_domain + '/' + routes.private.pre_checkin_preview_reservation)
        }else{
            dispatch(setFlow(constants.flow.pre_checkin))
            navigateTo(base_domain + '/auth'); //if biometrics = 0
        }
    }

    useEffect(() => {
        if(reservation.isTempReg==false){
            dispatch(clearReservation())
        }        
    }, [])

    useEffect(() => {        
        if(guest.isLoading === false && guest?.auth?.isAuthenticated==true && reservation.isTempReg==false) {
            dispatch(getReservationByGuestId(guest.data.id, LocSettings))
        }
    }, [guest])

    useEffect(() => {
        if(reservation.isLoading === false) {
            if (Object.keys(reservation.error).length > 1) {
                if(reservation.error.code == 10905)
                    fireMessageAlert(reservation.error.message)
                else
                    fireErrorAlert(reservation.error.message)
                dispatch(clearReservation())
                navigateTo(base_domain + '/', {replace: true});
            }else{
                if(reservation && reservation.data){
                    const reservation_guests:intReservationGuest[] = reservation?.data?._embedded?.guests                    
                    if (reservation_guests) {                        
                        if (reservation?.data?.status_pre_checkin == 1) {                                                
                            navigateTo(base_domain + '/' + routes.private.pre_checkin_preview_reservation)
                        }
                    }
                }
            }
        }
    }, [reservation])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])


    useEffect(() => {
        if(LocSettings && setPageTitle)
            setPageTitle(LocSettings?.location?.location_name)
    },[LocSettings])

    useEffect(() => {
        if(guest?.auth?.isAuthenticated!=true){
            navigateTo(base_domain + '/auth');
        }
        // if(guest?.auth?.isAuthenticated==true){
        //     dispatch(clearReservation())
        // }
        if (LocationLogo || LocationIcon) {
            setFetchLocationLogo(LocationIcon ? LocationIcon : LocationLogo);
        } else {
            fetch(locationLogoInConfigurationProfile)
                .then(response =>
                    setFetchLocationLogo(response.url),
                )
        }
        if (LocSettings.idle_image) {
            setFetchBackGroundMultiMedia(`${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.idle_image}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`)
        }
        if (LocSettings.location.location_name) {
            setLocationName(LocSettings.location.location_name)
        }
        if (LocSettings.primary_color) {
            const root = document.documentElement;
            root.style.setProperty('--kt-primary-active', LocSettings.primary_color);
            root.style.setProperty('--kt-primary', LocSettings.primary_color);
        }
        if (typeof LocSettings.appearance != "undefined") {
            let mode = 'dark';
            if (LocSettings.appearance == 1) {
                mode = 'light';
            }
            if (localStorage) {
                localStorage.setItem(themeModeLSKey, mode);
                localStorage.setItem(themeMenuModeLSKey, mode);
                document.documentElement.setAttribute('data-theme', mode);
            }
        }
        dispatch(setSidebarActiveMenu(4));
        ApiCallService.ApiCall({flow:'checkin'}, 'mobile/settings', 'GET', LocSettings)
        .then((res: any) => {
            let  base_domain = `/${location_id_or_name}`;
            if (res?.data.domain_name && res?.data.domain_name.trim() !== '') {
                Cookies.set(cookies_name.location_domain_name, res?.data.domain_name)
                base_domain = `/${res?.data.domain_name}`;
            }
            dispatch(StoreLocationSettings({
            ...res?.data,
            'base_domain': base_domain
            }));
        });
    }, []);

    if (guest.isLoading) {
        return <LoadingPage/>;
    }

    if (reservation.isLoading) {
        return <LoadingPage/>;
    }

    return (
        <div className='container-fluid mw-500px px-0 pt-10 pt-lg-20'>
        <div className='d-flex flex-column align-items-center'>
            <div className='w-100'>
                {/* begin::Content */}
                <div className='d-flex flex-center flex-column mx-auto'>
                    {/* begin::Logo */}
                    <div className='mx-20px mx-md-0'>
                        <div className='logo'>
                        <img
                         src={fetchLocationLogo} className='h-100px w-100px symbol'
                         onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.alt="";
                            }} />
                        </div>
                        {/* end::Logo */}
                        <div className='fs-6 form-label fw-bold pt-8 w-100 card p-5' >
                            <div className='card text-center'>
                                {messageIfNoBackgroundImage(locationName)}
                            </div>
                            <div className='text-center mt-5 '>
                                <CustomButton onClick={() => redirectToPage()} title="Check In"  id="kt_sign_in_submit" extraClass="w-100 "/>
                            </div>
                        </div>
                        <Outlet />
                    </div>
                </div>
                {/* end::Content */}
            </div>
        </div>
        </div>
    );
};

export const messageIfNoBackgroundImage = (locationName:string) => {
    return (<div className='m-0'>
        <p><FormattedMessage id='checkin.landing.ready_to_checkin' defaultMessage='Ready to check in to your reservation at' /> {locationName}?</p>
        <p><FormattedMessage id='checkin.landing.navigate_through_process' defaultMessage='Please navigate through the check in process to get you pre-comfirmed prior to your arrival.' /></p>
        <p className='m-0'><FormattedMessage id='checkin.landing.we_look_forward' defaultMessage='We look forward to your visit!' /></p>
    </div>)
}

export default LandingPreCheckinPage
