export const sliderSettings = {
    lazyload: true,
    items: 6,
    controls: true,
    nav: false,
    gutter:10,
    loop: false,
    controlsText: ['<', '>'],
    arrowKeys: true,
    mouseDrag:true,
    nonce:true,
    responsive: {
        320: {
            items: 1.75,
        },
        640: {
            items: 3,
        },
        768: {
            items: 3.50,
        },
        820: {
            items: 3.75,
        },
        1024: {
            items: 4.50,
        },
        1100: {
            items: 5,
        }
    }
}