import { createSlice } from '@reduxjs/toolkit'

export interface ModTicketState {
    isLoading: boolean,
    data: {},    
}

const initialState: ModTicketState = {
    isLoading: false,
    data: {}
}

export const modticketSlice = createSlice({
    name: 'modticket',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setModTicket: (state, action) => {            
            console.log('action.payload=>',action.payload);
            state.data = action.payload
            state.isLoading = false
        },        
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setModTicket} = modticketSlice.actions
