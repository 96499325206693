import React, {Dispatch, FC,ReactElement,  SetStateAction, SyntheticEvent, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Swal, {SweetAlertResult} from 'sweetalert2'
import './CameraPageStyles.scss'
// @ts-ignore
import Cookies from 'js-cookie'
import {constants} from "../../../config/constants";
import {setUpLoadFormId, setUpLoadSelfie} from "../../../services/PhoneAuthStepTracker";
import {Buffer} from 'buffer';
import { useIntl } from 'react-intl';

interface IdTakePhotoProps {
    forCheckin: boolean
    setOpenCamera: Dispatch<SetStateAction<boolean>>
    setStep: Dispatch<SetStateAction<number>>;
    setOpenIdCamera: Dispatch<SetStateAction<boolean>>
    setOpenSelfieCamera: Dispatch<SetStateAction<boolean>>
}

export const IdTakePhoto: FC<IdTakePhotoProps> = ({ setOpenIdCamera, setOpenSelfieCamera, forCheckin, setOpenCamera }) => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    const [instructionTitle, setInstructionTitle] = useState(intl.formatMessage({id:'common.center_your_id'}))
    const [submitDisabled, setSubmitDisabled] = useState(true)
    let videoRef = useRef<HTMLVideoElement|null>(null);
    let photoRef = useRef<HTMLCanvasElement|null>(null);
    const { cookies_name, session_storage_items } = constants;
    const [videoStream, setVideoStream] = useState<undefined|MediaStream>();
    const orientationMQL = window.matchMedia('(orientation: portrait)')
    const locationAppearance = LocSettings.appearance

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger btn-sm fs-5',
        },
        buttonsStyling: false,
        icon: 'error',
        background: LocSettings.appearance === 1 //if 1 or light mode set white background
            ? '#fffff'
            : '#151521',
        confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
        width: '29rem',
        heightAuto: false,
    })
    const fireErrorMessage = async (_errorMessage: string): Promise<SweetAlertResult<ReactElement<any, any>>> =>
        swalWithBootstrapButtons.fire({
            html: `<h6 class="swal2-html-container fs-5 mx-0 gray-800-dark">${_errorMessage}</h6>`,
        })

    const removeUseCamera = () => {
        // @ts-ignore
        document.getElementById('kt_header').style.visibility = 'visible';
        // @ts-ignore
        document.getElementById('kt_footer').style.visibility = 'visible';
        // @ts-ignore
        try{ videoStream.getTracks().forEach((track: { stop: () => void; }) => {
            track.stop();
        });}catch(e){}
    }

    const getUserCamera = () => {
        // @ts-ignore
        document.getElementById('kt_header').style.visibility = 'hidden';
        // @ts-ignore
        document.getElementById('kt_footer').style.visibility = 'hidden';
        navigator.mediaDevices.getUserMedia({
            audio:false,
            video: {
                facingMode: 'environment'
            }
        }).then((stream) => {
            let video = videoRef.current
            if (video !== null){
                video.srcObject = stream
                video.play()
                setVideoStream(stream);
            }
            else{
                console.error('Video ref was null!')
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const dataURLtoFile = (data_url: string, filename: string) => {
        const arr = data_url.split(',')
        // @ts-ignore
        const mime = arr[0].match(/:(.*?);/)[1]
       const bstr = atob(arr[1])
       // @ts-ignore
       //  const bstr = Buffer.from(arr[1]).toString('Base64') //new Buffer(arr[1], 'base64').toString('binary');
       //  const bstr = Buffer.from(arr[1], 'base64').toString("utf8");
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
            u8arr[n - 1] = bstr.charCodeAt(n - 1)
            n -= 1 // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime })
    }

    const takePicture = (event: SyntheticEvent) => {
        event.preventDefault()
        let width = 900;
        let height = width / (3 / 2);
        let photo = photoRef.current as HTMLCanvasElement
        let video = videoRef.current as HTMLVideoElement

        const alertBackground = `${locationAppearance === 1 ?  '#fffff' : '#151521'}`
        const alertTextColor = `${locationAppearance === 1 ? '' : 'text-white'}`
        Swal.fire({
            icon: 'warning',
            html: `<h1 class="swal2-html-container fs-5 ${alertTextColor}">${intl.formatMessage({id:'common.saving_image_wait'})}</h1>`,
            didOpen(popup: HTMLElement) {
                Swal.showLoading(null)
            },
            background: alertBackground
        })

        if(photo === null){
            console.log('canvas null!')
        }

        photo.width = width;
        photo.height = height;

        let ctx = photo.getContext('2d')
        if(ctx === null){
            console.log("Unable to set ctx")
        }
        ctx!.drawImage(video, 0, 0, photo.width, photo.height)

        let image_base64 = photo.toDataURL();

        const file = dataURLtoFile(image_base64, 'id-form-image');
        let data = new FormData()
        data.append('image_type', 'id_form')
        data.append('location_id', LocSettings.location.id)
        data.append('image_file', file)
        const API_URL = process.env.REACT_APP_SP_API_URL;
        const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON

        axios.post(`${API_URL}/trueface/template/validate?global_on_id=${GLOBAL_ON_ID}`, data)
            .then(response => {
                if (response.data?.image_name.trim() !== '') {
                    Cookies.set(cookies_name.register.id_image_temp_path, response.data.image_name)
                    sessionStorage.setItem(session_storage_items.images.id_form_thumbnail, image_base64)
                    removeUseCamera()
                    dispatch(setUpLoadFormId(true));
                    if(typeof(setOpenCamera) ==='function'){
                        setOpenCamera(false);
                    }
                    if(typeof(setOpenIdCamera) ==='function'){
                        setOpenIdCamera(false);
                    }
                } else {
                    // fireErrorMessage('Error uploading the image.')
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({id:"login.selfie.alert.oops"}),
                        background: LocSettings.appearance === 1 //if 1 or light mode set white background
                            ? '#fffff'
                            : '#151521',
                        text: intl.formatMessage({id:"login.selfie.alert.error_uploading_image"})
                    })
                }
                Swal.close()
            })
            .catch((e) => {
                Swal.close()
                console.log(e)
                Swal.fire({
                    icon: 'error',
                    title: intl.formatMessage({id:"login.selfie.alert.oops"}),
                    background: LocSettings.appearance === 1 //if 1 or light mode set white background
                        ? '#fffff'
                        : '#151521',
                    text: intl.formatMessage({id:"login.selfie.alert.error_uploading_image"})
                })
            })
    }

    useEffect(() => {
        const inPortraitMode = orientationMQL.matches
        console.log('inPortrait', inPortraitMode)
        if (inPortraitMode) {
            setInstructionTitle(intl.formatMessage({id:'camera.overlays.rotate_device.please_rotate_device'}))
            setSubmitDisabled(true);
        } else {
            setInstructionTitle(intl.formatMessage({id:'common.center_your_id'}))
            setSubmitDisabled(false);
        }
    },[orientationMQL.matches])

    useEffect(() => {
        getUserCamera()
    }, [videoRef])



    return (
        <div id='CameraPageId'>
            <video className='video-selfie' 
                autoPlay={true}
                playsInline={true}
                ref={videoRef}>

            </video>


            <div className="d-flex" style={{marginTop:'-79px'}}>
            {
                !submitDisabled ?
                    <div className='d-flex flex-column w-100'>
                        <h1 className='centerIdTakePhotoText'>{instructionTitle}</h1>
                        <i className="button fa fa-circle-dot" onClick={takePicture}></i>
                    </div>
                    :
                    <div className='d-flex flex-column'>
                        <h1 className='RotateDeviceTakePhotoText'>{instructionTitle}</h1>
                    </div>
            }
                 <span
                    id='xCloseIdTakePhoto'
                    onClick={() => {
                        Cookies.set(cookies_name.register.id_image_temp_path, '')
                        removeUseCamera()
                        dispatch(setUpLoadFormId(true));
                    }}
                >
                   X
                </span>
            </div>
            <div className="svgClass">
                <svg width="100%" height="100%">
                    <mask id="mask">
                        <rect fill="white" width="100%" height="100%" fillOpacity="1" />
                        <rect width="58%" height="88%" x="22%" y="10%" />
                    </mask>
                    <rect mask="url(#mask)" width="100%" height="100%" fillOpacity="0.5" />
                </svg>
            </div>

          

            <div className='d-flex justify-content-center'>
                <div >
                    <canvas id='id-canvas' style={{ display: "none" }} ref={photoRef}></canvas>
                </div>
            </div>
        </div>
    );
};
