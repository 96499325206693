import { createContext, ReactNode, useContext, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../store"
import { useErrorAlert } from "../../../utilities/alerts"
import { ILocalModifier } from "../common/utilities"
import { IModifierGroup, ItemInfoState } from "../state/CartState"
import { IModifiers } from "../state/ModGroupState"


type SelectedModifiersUI = {
    selected_modifiers: IModifierGroup[]
    initModifiers: (itemInfo: ItemInfoState) => void
    setSelectedModifiers: (modifiers: IModifierGroup[]) => void
    addSelectedMod: (group: IModifierGroup, modifier: IModifierGroup, parentModifierId?: null | number, parentModGroupId?: null | number) => boolean
    removeSelectedMod: (group: IModifierGroup, modifier: IModifierGroup) => void
}

const SelectedModifiersContext = createContext<SelectedModifiersUI | undefined>(undefined)

export const useSelectedModifiers = () => {
    const context = useContext(SelectedModifiersContext)
    if (!context) {
        throw new Error('[useSelectedModifiers] can only be used under the [SelectedModifiersProvider]')
    }
    return context
}

export const ItemModifiersProvider = ({ children }: { children: ReactNode }) => {
    const [selectedItems, setSelectedItems] = useState<Array<IModifierGroup>>([])
    const fireErrorAlert = useErrorAlert()
    let itemIdToUpdate = useSelector((state: RootState) => state.CartItemIdToUpdate.itemId)
    let cartIdToUpdate = useSelector((state: RootState) => state.CartItemIdToUpdate.id)
    let cartItems = useSelector((state: RootState) => state.cartItems.data)

    const setSelectedModifiers = (modifiers: IModifierGroup[]) => {
        setSelectedItems(modifiers)
    }

    const initModifiers = (itemInfo: ItemInfoState) => {
        if (cartIdToUpdate > 0 && itemIdToUpdate > 0) {
            cartItems.forEach((e) => {
                if (e.id == cartIdToUpdate && e.itemId == itemIdToUpdate) {
                    setSelectedItems(e.mods)
                }
            })
        } else {
            const itemInfoFilter = itemInfo.modgroups?.filter(
                (modifierGroup: IModifierGroup) =>
                    modifierGroup.modifiers && Object.keys(modifierGroup.modifiers).length > 0 && modifierGroup.olo
            )
            const temp: IModifierGroup[] = []
            itemInfoFilter.map((modifierGroup: IModifierGroup, index: number) => {
                modifierGroup.modifiers &&
                    modifierGroup.modifiers.filter((modifier) => !modifier.mod_groups).map((modifier: ILocalModifier) => {
                        if (modifier.default_status) {
                            const modGroupIdAdded: ILocalModifier = {
                                ...modifier,
                                modGroupId: modifierGroup.id,
                            }
                            if (!temp.some((obj) => obj.id === modGroupIdAdded.id)) {
                                temp.push(modGroupIdAdded)
                            }
                        }
                    })
            })
            setSelectedItems(temp)
        }
    }

    const getRangeValues = (modGroupinfo: IModifierGroup, parentModifierId?: null | number, parentModGroupId?: null | number) => {
        const matchingItems = selectedItems.filter(
            (item) =>
                modGroupinfo.modifiers &&
                modGroupinfo?.modifiers.some(
                    (i: IModifiers) => i.id === item.id && item.modGroupId === modGroupinfo.id && item.parent_modifier_id == parentModifierId && item.parent_modifier_group_id == parentModGroupId
                )

        )

        let count = matchingItems.length
        let minimum = modGroupinfo.minimum ?? 0
        let maximum =
            modGroupinfo.maximum && minimum < modGroupinfo.maximum ? modGroupinfo.maximum : minimum

        return [count, maximum, minimum]
    }

    const addSelectedMod = (group: IModifierGroup, modifier: IModifierGroup, parentModifierId?: null | number, parentModGroupId?: null | number) => {
        const [count, maximum, minimum] = getRangeValues(group, parentModifierId, parentModGroupId)
        if (group.minimum == 0 && group.maximum == 0) {
            const modGroupIdAdded = {
                ...modifier,
                modGroupId: group.id,
                parent_modifier_group_id: parentModGroupId,
                parent_modifier_id: parentModifierId
            }
            setSelectedItems((selectedItems) => [
                ...selectedItems,
                modGroupIdAdded,
            ])
        } else if (group.required == 1) {
            if (count >= maximum && maximum != 0) {
                fireErrorAlert(`Maximum ${maximum} allow`)
                return false
            } else {
                const modGroupIdAdded = {
                    ...modifier,
                    modGroupId: group.id,
                    parent_modifier_group_id: parentModGroupId,
                    parent_modifier_id: parentModifierId
                }

                setSelectedItems((selectedItems) => [
                    ...selectedItems,
                    modGroupIdAdded,
                ])
            }
        } else if (group.required == 0) {
            if (count >= maximum && maximum != 0) {
                fireErrorAlert(`Maximum ${maximum} allow`)
                return false
            } else {
                const modGroupIdAdded = {
                    ...modifier,
                    modGroupId: group.id,
                    parent_modifier_group_id: parentModGroupId,
                    parent_modifier_id: parentModifierId
                }

                setSelectedItems((selectedItems) => [
                    ...selectedItems,
                    modGroupIdAdded,
                ])
            }
        } else {
            const modGroupIdAdded = {
                ...modifier,
                modGroupId: group.id,
                parent_modifier_group_id: parentModGroupId,
                parent_modifier_id: parentModifierId
            }
            setSelectedItems((selectedItems) => [
                ...selectedItems,
                modGroupIdAdded,
            ])
        }
        return true
    }

    const removeSelectedMod = (group: IModifierGroup, modifier: IModifierGroup) => {
        let selectedModifiers = [...selectedItems];
        if (modifier.mod_groups) {
            selectedModifiers = selectedModifiers.filter((item) => !(item.parent_modifier_id == modifier.id && item.parent_modifier_group_id == group.id))
        }
        const isAlreadyAdded = selectedModifiers.filter(
            (item) => item.id == modifier.id && item.modGroupId === group.id
        )

        if (isAlreadyAdded != null && isAlreadyAdded.length > 0) {
            let removingItem = selectedModifiers.findIndex((item) => item.id == modifier.id && item.modGroupId === group.id)// && item.parent_modifier_id == parentModifierId && item.parent_modifier_group_id == parentModGroupId)
            const list = selectedModifiers.filter(
                (item, index) => !(index === removingItem)
            )

            setSelectedItems(list)
        } else {
            setSelectedItems(selectedModifiers)
        }
    }

    return (
        <SelectedModifiersContext.Provider
            value={{ selected_modifiers: selectedItems, initModifiers, setSelectedModifiers, addSelectedMod, removeSelectedMod }}
        >
            {children}
        </SelectedModifiersContext.Provider>
    )
}