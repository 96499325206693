import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {IModgroups} from '../../../../pages/online_ordering/state/ModGroupState'
import {IModifierGroup} from '../../../../pages/online_ordering/state/CartState'

export interface selectedItemModifireInfoState {
  isLoading: boolean
  modGroups: IModgroups[] | []
  selectedModifire: Array<IModifierGroup> | []
}

const initialState: selectedItemModifireInfoState = {
  isLoading: false,
  modGroups: [],
  selectedModifire: [],
}

export const selectedItemModifireInfoSlice = createSlice({
  name: 'ItemModifireInfo',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    setModgroup: (state, action: PayloadAction<IModgroups[] | []>) => {
      state.modGroups = action.payload
    },
    setSelectedModifire: (state, action: PayloadAction<Array<IModifierGroup> | []>) => {
      state.selectedModifire = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {startLoading, setModgroup, setSelectedModifire} =
  selectedItemModifireInfoSlice.actions
