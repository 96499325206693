// @ts-ignore
import Cookies from 'js-cookie'
import {constants} from "../../config/constants";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {useEffect, useState} from "react";


export const useLocationDomain = () => {
    const LocSettings = useSelector((state:RootState)=>state.LocSettings.value)
    let base_domain = Cookies.get(constants.cookies_name.location_domain_name)
    if (base_domain?.trim() === '') {
        base_domain = LocSettings.location.id;
    }
    return base_domain
}

export const useRegistrationChecker = () => {
    let missingStepURL = null
    const routes = constants.routes.private
    const registrationTracker = useSelector((state:RootState) => state.registrationSessionTracker)
    const locationDomain = useLocationDomain()


    const stepRouteMap:{[key:string]:string} = {
        terms: '/' + locationDomain + routes.registration_terms,
        identity_forms:'/' + locationDomain + routes.registration_forms,
        identity_verified:'/' + locationDomain + routes.registration_verification,
        add_cof:'/' + locationDomain + routes.registration_pay_method
    }
    for(const [key, value] of Object.entries(registrationTracker)){
        console.log(key)
        if(!value){
            missingStepURL = stepRouteMap[key]
            break
        }
    }


    return missingStepURL
}

export const useAddParameterToUrl = () => {
    const addParameterToUrl = (url: string, paramName: string, paramValue: string): string => {
        const hasParams = url.includes('?');
        const newParam = `${paramName}=${paramValue}`;
        const separator = hasParams ? '&' : '?';
        return `${url}${separator}${newParam}`;
    };

    return { addParameterToUrl };
};
