import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import moment from "moment";

export interface IGuestAuthState{
    isAuthenticated:boolean,
    authMethod:'TWO_FA'|'BIOMETRICS'|null,
    startedAt:string|null,
    biometricsGuestsFound:any,
    biometricsLogSyncId:string|null,
    biometricsLoginTempImg?:string|null,
    childGuestId?:string|number|null,
}

export interface GuestState {
    isLoading: boolean,
    data: any,
    auth:IGuestAuthState
}

const initialState: GuestState = {
    isLoading: false,
    data: {},
    auth:{
        isAuthenticated:false,
        authMethod:null,
        startedAt:null,
        biometricsGuestsFound: [],
        biometricsLogSyncId: null,
        childGuestId: null,//Filled only if authentication is a child/related guest from a guest parent.
    }
}

export const guestSlice = createSlice({
    name: 'guest',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setGuest: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
        setGuestAuthState:(state:GuestState, action:PayloadAction<IGuestAuthState>) => {
            state.auth  = action.payload
        },
        setLoginBiometricsGuestsFound: (state, action:PayloadAction<object>) => {
            state.auth.biometricsGuestsFound = action.payload
        },
        setAuthBiometricsLogSyncId: (state, action:PayloadAction<string>) => {
            state.auth.biometricsLogSyncId = action.payload
        },
        setAuthBiometricsLoginTempImg: (state, action:PayloadAction<string>) => {
            state.auth.biometricsLoginTempImg = action.payload
        },
        logOutGuest: (state:GuestState) => {
            state.data = {}
            state.isLoading = false
            state.auth = {
                isAuthenticated:false,
                authMethod:null,
                startedAt:null,
                biometricsGuestsFound:[],
                biometricsLogSyncId:null,
                biometricsLoginTempImg:null,
                childGuestId:null,
            }
        },
        logInGuest: (state:GuestState, action:PayloadAction<'TWO_FA'|'BIOMETRICS'>) => {
            state.auth = {
                isAuthenticated:true,
                authMethod:action.payload,
                startedAt:moment().format(),
                biometricsGuestsFound:[],
                biometricsLogSyncId:null,
                childGuestId:null,
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setGuest, setGuestAuthState, logOutGuest, logInGuest, setLoginBiometricsGuestsFound, setAuthBiometricsLogSyncId, setAuthBiometricsLoginTempImg } = guestSlice.actions
