/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { MixedWidget2 } from '../../../_spc/partials/widgets'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { DashboardLandingPage } from './DashboardLandingPage'
import { PageDataProvider } from '../../../_spc/layout/core'
import { ThemeModeProvider } from '../../../_spc/partials'
import { HeaderTitleProvider } from '../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore'
import { HeaderWrapper } from '../../../_spc/layout/components/header/HeaderWrapper'
import { Footer } from '../../../_spc/layout/components/Footer'
import { ScrollTop } from '../../../_spc/layout/components/ScrollTop'
import { Outlet, useLocation } from 'react-router-dom'
import { MenuComponent } from '../../../_spc/assets/ts/components'
import { Content } from '../../../_spc/layout/components/Content'

const HomeWrapper: FC = () => {
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <>
      <PageDataProvider>
        <ThemeModeProvider>
          <HeaderTitleProvider>
            <div className='page d-flex flex-row flex-column-fluid'>
              <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                <HeaderWrapper />
                <div className='h-100 pt-5'>
                  <Outlet />
                </div>
                <Footer />
              </div>
            </div>
            <ScrollTop />
          </HeaderTitleProvider>
        </ThemeModeProvider>
      </PageDataProvider>
    </>
  );
};

export default HomeWrapper
function componentDidMount() {
  throw new Error('Function not implemented.')
}

