import React, {useEffect} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {constants} from "../../config/constants";
// @ts-ignore
import Cookies from 'js-cookie'
import {setFlow} from "../../services/SourceSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store";

export const CheckinRedirect = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigateTo = useNavigate()
    const {cookies_name} = constants;
    let [searchParams, setSearchParams] = useSearchParams();
    let query_params = searchParams.get('q');
    // @ts-ignore
    query_params = atob(query_params);
    let query_params_split = query_params.split('&')
    let reservation_id = query_params_split[0].split('=')[1];
    let location_id = query_params_split[1].split('=')[1];

    Cookies.set(cookies_name.reservation_id, reservation_id)
    dispatch(setFlow(constants.flow.pre_checkin));

    useEffect(() => {
        navigateTo('/' + location_id + '/' + constants.routes.private.pre_checkin);
    }, [])

    return (
        <>
        </>
    )
}
