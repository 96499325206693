import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface ICartBtnFooter {
    cartButtonDisplay: boolean | null,
    cartItemAddedMsg: string | null,
    cartItemCategoryId:number
}


const initialState: ICartBtnFooter = {
    cartButtonDisplay: null,
    cartItemAddedMsg: null,
    cartItemCategoryId:0
}

export const cartButtonSlice = createSlice({
    name: 'cartButton',
    initialState,
    reducers: {
        setCartButtonDisplay: (state, action: PayloadAction<boolean | null>) => {
            state.cartButtonDisplay = action.payload;
        },
        setCartItemAddedMsg: (state, action: PayloadAction<string | null>) => {
            state.cartItemAddedMsg = action.payload;
        },
        setCartItemCategoryId: (state, action: PayloadAction<number>) => {
            state.cartItemCategoryId = action.payload;
        },
        resetCartButton: (state) => {
            state.cartButtonDisplay = null
            state.cartItemAddedMsg = null
        }
    },
})

// Action creators are generated for each case reducer function
export const {setCartButtonDisplay, setCartItemAddedMsg, setCartItemCategoryId,resetCartButton} = cartButtonSlice.actions
