import { createSlice } from '@reduxjs/toolkit'

export interface LocationState {
    isLoading: boolean,
    data: object,
    error: object;
    
}

const initialState: LocationState = {
    isLoading: false,
    data: {},
    error: {}
}

export const locationStateSlice = createSlice({
    name: 'locationState',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setError: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        setLocationState: (state, action) => {
            // console.log('action.payload ---->', action.payload);
            state.data = action.payload
            state.isLoading = false
        },
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setError, setLocationState } = locationStateSlice.actions