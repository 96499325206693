/**
 * Context provider for the App's language settings
 */
import React, {FC, createContext, useContext} from 'react'
import {WithChildren} from '../helpers'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
const I18N_CONFIG_USER_KEY = 'i18nConfigUser'
const ES_COUNTRIES = [5, 12, 26, 38, 54, 69, 72, 76, 79, 85, 87, 89, 112, 118, 173, 184, 185, 182, 237];

type Props = {
  selectedLang: 'en' | 'es'
}
const initialState: Props = {
  selectedLang: 'en',
}

const currentLang = {
  'en': {
    lng: 'en',
    display_name: 'English',
    flag_path: '/media/flags/united-states.svg',
  },
  'es': {
    lng: 'es',
    display_name: 'Español',
    flag_path: '/media/flags/mexico.svg',
  }
}

function getConfigByKey(ls: string|null)
{
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  return getConfigByKey(ls);
}

export function getSupportedLanguages()
{
  return currentLang
}

export function getI18nName()
{
  let lang = getConfig().selectedLang
  return currentLang[lang].display_name
}

export function getI18nFlag()
{
  let lang = getConfig().selectedLang
  return currentLang[lang].flag_path
}

export function setLanguageManually(lang: string) {
  localStorage.setItem(I18N_CONFIG_USER_KEY, JSON.stringify({selectedLang: lang}))
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

export function setLanguage(lang: string) {
  const ls_user = localStorage.getItem(I18N_CONFIG_USER_KEY)
  if(ls_user) {
    return false; //Skip change language automatically if user already choose a language
  }
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  setTimeout(() => {
    window.location.reload()
  }, 350);
}

interface IGuestData {
  phone_global_country_id: number|null,
  global_country_id: number,
  [key: string]: any
}

export function setLanguageAutomaticallyByGuestData(guest_data: IGuestData) {
  let current_lang = getConfig().selectedLang

  let global_country = guest_data.global_country_id;
  if(guest_data.phone_global_country_id != null) {
    global_country = guest_data.phone_global_country_id;
  }

  let es_country_found = ES_COUNTRIES.find((country) => country == global_country)
  let language_to_set = 'en'
  if(es_country_found != undefined) {
    language_to_set = 'es'
  }

  if(language_to_set != current_lang) {
    setLanguage(language_to_set);
  }
}

export function getI18n() {
  return getConfig().selectedLang
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {I18nProvider, useLang}
