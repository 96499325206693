import { Dispatch, FC, memo, SetStateAction, useEffect, useRef } from "react";
import ReactFlagsSelect from "react-flags-select";
import { countries } from "../../common/country_state/Countries";

//define types for props passed down
interface FlagSelectionProps {
    selected: string
    previousSelected: string
    disabled?:boolean
    setSelected: Dispatch<SetStateAction<string>>;
    setPreviousSelected: Dispatch<SetStateAction<string>>;
}
const ReactFlagsSelection: FC<FlagSelectionProps> = ({ selected, previousSelected, setSelected, setPreviousSelected, disabled=false }) => {
    //attempts to assign selected element to ref hook to persist styling after dropdown list is hidden
    let liRef = useRef<HTMLLIElement | HTMLElement | null>(document.getElementById(`rfs-${selected}`));
    //search for call code and append className to selected call code
    const addSyles = () => {
        liRef.current = document.getElementById(`rfs-${selected}`)
        let appendSelectedList =  document?.querySelector('#countryList ul div');
        if(appendSelectedList && liRef.current){
            appendSelectedList.after(liRef.current)
        }
        liRef.current?.classList.add('activeCallCodeID')
        setTimeout(() => {
            liRef.current = document.getElementById(`rfs-${previousSelected}`);
            liRef.current?.classList.remove('activeCallCodeID');
            //clearInterval(persist)
        }, 5000)
    };
    //needed to clearInternal for perisist styling li element after selection
    let persist = setInterval(addSyles, 700);
    useEffect(() => {
        //onmount runs after page is rendered
        setPreviousSelected(selected)
        clearInterval(persist)
        liRef.current = document.getElementById(`rfs-${previousSelected}`);
        liRef.current?.classList.remove('activeCallCodeID');
        return (() => {
            //cleanup - unmount side effects
            clearInterval(persist)
            liRef.current = document.getElementById(`rfs-${previousSelected}`);
            liRef.current?.classList.remove('activeCallCodeID');
            setPreviousSelected(selected)
        })
    }, [previousSelected]);

    const getCountryCodes = () => {
        const countriesArray = Object.entries(countries).map(([key, value]) => ({ countryCode: key, ...value }));
        countriesArray.sort((a, b) => a.primary.localeCompare(b.primary));
        const sortedKeys = countriesArray.map(country => country.countryCode);
        sortedKeys.unshift(...["US","CA","MX"]);
        return sortedKeys;
    }
    //prevents excessive calls
    setTimeout(() => {
        clearInterval(persist)
    }, 20000) //clear after 20 seconds- will cause all selected styles to stop persisting
    return (
        <ReactFlagsSelect
            className="w-110px"
            selectButtonClassName={`w-110px overflow-hidden dropdownFlagSelectButton react-select-flags-bg-color ${disabled ? 'dropdown-caret-hide' : ''}`}
            fullWidth={false}
            searchable={true}
            selected={selected}
            disabled={disabled}
            countries={getCountryCodes()}
            customLabels={countries}
            showSelectedLabel={false}
            showSecondarySelectedLabel={true}
            showOptionLabel={true}
            showSecondaryOptionLabel={true}
            id="countryList"
            onSelect={(code) => {
                clearInterval(persist)
                setSelected(code)
            }}
        />
    );
};
export default memo(ReactFlagsSelection)
