import { useState, useEffect, FC,ReactElement } from "react";
import { KTSVG } from '../../../_spc/helpers'
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import CustomButton from '../../common/button/CustomButton';
import { Field, Form, Formik} from "formik";
import ReactFlagsSelection from "../../pages/login/ReactFlagSelection";
import {PhoneSearchSchema} from "../../modules/auth/utils/ValidationSchemas";
import { useKeyboardScrollLock } from "../../pages/login/hooks/KeyBordScrollLock";
import Inputmask from "inputmask";
import {baseCountries,countries} from "../../common/country_state/Countries";
import * as ApiCallService from "../../services/ApiCallService";
import Swal, {SweetAlertResult} from "sweetalert2";
import { FormattedMessage, useIntl } from "react-intl";
import { useSuccessAlert } from "../../utilities/alerts";

interface ShareTicketModalProps {
    toggleModal: () => void,
    ticketId:number
}

export const ShareTicketModal: FC<ShareTicketModalProps> = ({ toggleModal,ticketId }) => {
    const intl = useIntl();
    const locationSettings = useSelector((state:RootState) => state.LocSettings.value )
    
    const [selected, setSelected] = useState("");
    const [previousSelected, setPreviousSelected] = useState("");
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const locationCountryID = locationSettings.location.global_country_id;
    const fireSuccessAlert = useSuccessAlert()
    const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    const fireErrorMessage = async (
        _errorMessage: string,
        _errorTitle?: string,
    ): Promise<SweetAlertResult<ReactElement<any, any>>> => swalWithBootstrapButtons.fire({
        title: `${_errorTitle ?? ''}`,
        html: `<h6 class="swal2-html-container fs-5 mx-0 gray-800-dark mt-0">${_errorMessage}</h6>`,
    })
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger btn-sm fs-5',
        },
        buttonsStyling: false,
        icon: 'error',
        background: locationSettings.appearance === 1 //if 1 or light mode set white background
            ? '#fffff'
            : '#151521',
        confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
        width: '29rem',
        heightAuto: false,
    })
 
    useEffect(() => {        
        if (locationCountryID > 0) {
            Object.keys(baseCountries).map(function (globalID) {
                if (globalID == locationCountryID) {
                    setSelected(
                        baseCountries[globalID]['id'] ? baseCountries[globalID]['id'] : "US"
                    )
                }
            })
        }
    }, [])

    //Field Mask Plugin
    const [pn_place,setpn_place] = useState('(000) 000-0000');    
    const [mask_format,setmask_format] = useState('(999) 999-9999');

    useEffect(() => {        
        let pn_length = 10;
        if(countries[selected]?.pn_length>0){
            pn_length = countries[selected]?.pn_length;
        }
        let Format = "";
        let placeHolder = "";
        for(let i=0;i<pn_length;i++){
            if(i==0){
                Format = Format + '(';   
                placeHolder = placeHolder + '('; 
            }
            if(i==3){
                Format = Format + ') ';
                placeHolder = placeHolder + ') ';    
            }
            if(i==6){
                Format = Format + '-'; 
                placeHolder = placeHolder + '-';   
            }
            Format = Format + '9';
            placeHolder = placeHolder + '0';
        }        
        setpn_place(placeHolder);
        setmask_format(Format);
    },[selected])
    Inputmask({
        mask: mask_format,
        removeMaskOnSubmit: true,
    }).mask('#phone_number')

    function handleFocus() {
        setIsFocused(true);
    }
    function handleBlur() {
        setIsFocused(false);
    }
    const HandleSubmit = (values:{phoneNumber:string}, selected:any) => { 
        const errors = {};
        if (!values.phoneNumber) {
            return errors
        }
        if (phoneRegExp.test(values.phoneNumber)) {
            let data: any = {};
            let pn = countries[selected]['secondary']?.replace('+','')+values.phoneNumber.replace(/[^0-9]/g, "");
            data = {
                phone:pn
            }

            ApiCallService.ApiCall(data, `/tickets/${ticketId}/digitalreceipt`, 'POST', locationSettings)
            .then((res: any) => {                
                if (res?.error?.message != undefined) {
                    fireErrorMessage(res?.error?.message)
                } else {
                    fireSuccessAlert(res?.data?.message).then((result) => {
                        toggleModal();
                    });
                }
            })
            .catch((err) => {
                Swal.close();
                fireErrorMessage(intl.formatMessage({id:"receipt.share_ticket.alert.error_occured"}))
            })
            
        }
        return;
    }
    useKeyboardScrollLock(isFocused);
    return (
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog-centered w-100 set-manager-login-modal h-auto px-5'
            show={true}
            onHide={toggleModal} >
            <div className='modal-content shadow-none'>
                <div className="modal-header">
                    <h2 className="modal-title text-center"><FormattedMessage id='receipt.share_ticket' defaultMessage='Share Ticket' /></h2>
                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={toggleModal} aria-label="Close" >
                        <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-2x" />
                    </div>
                </div>
                <div className='container-xxl w-lg-100'>
                    <div className='modal-body p-0'>
                    <div className='card pb-5 pt-5 mx-20px'>
                        <div className='text-dark fs-6 fw-bold text-left pb-5'>
                        <FormattedMessage id='receipt.share_ticket.enter_phone' defaultMessage='Enter Phone Number' /> 
                        </div>
                        <div className="input-group">
                            <Formik
                                validationSchema={PhoneSearchSchema}
                                initialValues={{phoneNumber: ''}}
                                onSubmit={(values) => {
                                    HandleSubmit(values, selected)
                                }}
                            >
                                {
                                    ({errors, touched}) => (
                                        <Form className='p-0 w-100 mw-500px' id='kt_create_account_form'>
                                            <div className='card p-0'>
                                                <div className="d-flex p-0 m-0 form-control form-control-solid"
                                                    id="basic-addon1">
                                                    <ReactFlagsSelection
                                                        selected={selected}
                                                        setSelected={setSelected}
                                                        previousSelected={previousSelected}
                                                        setPreviousSelected={setPreviousSelected}
                                                    />
                                                    <Field
                                                        onFocus={handleFocus}
                                                        onBlur={handleBlur}
                                                        id='phone_number'
                                                        type='tel'
                                                        className='form-control form-control-lg form-control-solid p-2'
                                                        name='phoneNumber'
                                                        placeholder={pn_place}
                                                        inputMode='tel'
                                                    />
                                                </div>
                                            </div>
                                            {touched.phoneNumber && errors.phoneNumber && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{intl.formatMessage({id:errors.phoneNumber})}</div>
                                                </div>
                                            )}
                                            <div className='container px-0'>
                                                <div className='pt-10 ps-1'>
                                                    <CustomButton type="submit" extraClass="w-100" title={intl.formatMessage({id:"common.continue"})}/>
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
