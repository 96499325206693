interface ICanvasDimensions{
    width:number,
    height:number
}

export const displayHeaderAndFooter = () => {
    const header = document.getElementById('kt_header')
    const footer = document.getElementById('kt_footer')
    if(header !== null){
        header.style.visibility = 'visible'
    }
    if(footer !== null){
        footer.style.visibility = 'visible'
    }
}

export const stopAllCamStreams = (videoStream:undefined|MediaStream) =>{
    if (videoStream){
        console.log('Closing all streams...')
        videoStream.getVideoTracks().forEach(track => track.stop())
    }
}

/**
 * Attempts to crop out a thumbnail from the provided canvas.
 * The method identifies what orientation the canvas is in by, calculating the aspect ratio of the canvas.
 * Then the method crops a square from the center of the image based on the shortest canvas dimension.
 * @param canvas
 * @return HTMLCanvasElement
 */
export const getThumbnail = (canvas:HTMLCanvasElement) => {
    const tempCanvas = document.createElement('canvas')
    const tempCtx = tempCanvas.getContext('2d')
    const originalCtx = canvas.getContext('2d')

    const isThumbnail = (canvas.width / canvas.height) < 1.1 && (canvas.width / canvas.height) > 0.9
    if(isThumbnail) return canvas

    const isPortrait = (canvas.height / canvas.width) > 1
    if(isPortrait){
        tempCanvas.width = canvas.width
        tempCanvas.height = canvas.width
        const sourceY = (canvas.height / 2) - (canvas.width / 2)
        const imageData = originalCtx!.getImageData(0, sourceY, canvas.width, canvas.width)
        tempCtx!.putImageData(imageData, 0, 0)
    }
    else{
        tempCanvas.width = canvas.height
        tempCanvas.height = canvas.height
        const sourceX = (canvas.width / 2) - (canvas.height / 2)
        const imageData = originalCtx!.getImageData(sourceX, 0, canvas.height, canvas.height)
        tempCtx!.putImageData(imageData, 0, 0)
    }

    return tempCanvas
}

export const shrinkDimensions = (width:number, height:number, maxLength:number=800):ICanvasDimensions => {
    const aspectRatio = width/height
    const isLandscape = aspectRatio > 1

    if(isLandscape){
        if(width > maxLength){
            const newWidth = maxLength
            const newHeight = newWidth * (height/width)
            return {width:newWidth, height:newHeight}
        }
        else{
            return {width, height}
        }
    }
    else{
        if(height > maxLength){
            const newHeight = maxLength
            const newWidth = newHeight * (width/height)
            return {width:newWidth, height:newHeight}
        }
        else{
            return {width, height}
        }
    }

}
