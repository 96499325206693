import {createSlice} from '@reduxjs/toolkit'
import {ISpError} from '../../../../../common/api_interfaces/Errors'
import { ITaxRate } from '../tax_rates'

export type mod_group = {
  id: number
  id_external: string
  max: number
  min: number
  required: number
  option: number
}

export type itemObject = {
  //shapes MenuItemCard component to map with
  id: number //output: 2369
  image: string | null //output: "locations/279685/menus/items/kldDd62uGjx6oJQZUV21yiJphj28MlAec8m2f6RU.png"
  name: string //output: "MozzaSticks"
  description?: string //output: "Fried stick of mozzarella cheese"
  id_external: string, 
  name_info?: string //output: "MozzaSticks"
  price_per_unit: number //output: 939
  _embedded: {
    //output: { mod_groups: Array(2), printers: Array(0), tax_rate: {… } }
    mod_groups: Array<mod_group>
    tax_rate?: ITaxRate
  }
}

export type menuCategory = {
  //shapes MainMenuView component to map with
  id: number //output: 321
  name: string //output: "Appetizers"
  maximum: number | null
  available_dow: string,
  start_time: string | null,
  end_time: string | null
  _embedded: {
    //output: { categories: Array(0), items: Array(5) }
    categories?: Array<menuCategory>
    items: Array<itemObject>
  }
}

export interface MenuCategoriesState {
  isLoading: boolean
  data: Array<menuCategory>
  error: ISpError
  locationSettingsId: Number | null
}

const initialState: MenuCategoriesState = {
  isLoading: false,
  locationSettingsId: null,
  data: [
    {
      id: 0,
      name: '',
      maximum: null,
      start_time: null,
      end_time: null,
      available_dow: '',
      _embedded: {
        items: [
          {
            id: 0,
            image: '',
            name: '',
            id_external: '',
            price_per_unit: 0,
            _embedded: {
              mod_groups: [
                {
                  id: 0,
                  id_external: '',
                  max: 0,
                  min: 0,
                  required: 0,
                  option: 0
                },
              ],
            },
          },
        ],
      },
    },
  ],
  error: {
    error: {
      code: 0,
      message: '',
      details: null,
    },
  },
}

export const menuCategoriesSlice = createSlice({
  name: 'menuCategory',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    setError: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },
    setMenuCategory: (state, action) => {
      state.data = action.payload
      state.isLoading = false
    },
    setMenuLocationSettingsId: (state, action) => {
      state.locationSettingsId = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {startLoading, setMenuCategory, setError, setMenuLocationSettingsId} =
  menuCategoriesSlice.actions
