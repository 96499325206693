import React, {isValidElement, ReactElement, ReactNode, useState} from "react";
import { Form } from "react-bootstrap";

interface ICustomDropDownMenuProps {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    labeledBy?: string;  
}
  
export const CustomDropDownMenu = React.forwardRef(
    (props:ICustomDropDownMenuProps, ref: React.Ref<HTMLDivElement>) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={props.style}
                className={props.className}
                aria-labelledby={props.labeledBy}
             >
                <Form.Control
                    id='genderForm'
                    autoFocus
                    className="ps-3 m-4"
                    placeholder=""
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>) => {

                            setValue(event.target.value)
                        }
                    }
                    value={value}
                />
                <ul className="list-unstyled w-100 state-ul-dropdown">
                    {React.Children.toArray(props.children).filter(
                        (child) =>{
                            if(isValidElement(child)){
                                return !value || child.props.children.toLowerCase().startsWith(value.toLowerCase())
                            }
                        }
                    )}
                </ul>
            </div>
        );
    },
);