import {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import { useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
// @ts-ignore
import Cookies from 'js-cookie'
import { RootState } from '../../../../../app/store'
import { constants } from '../../../../../app/config/constants'
import { setFlow } from '../../../../../app/services/SourceSlice'
import { setUnableToUseBiometricsLogin } from '../../../../../app/services/PhoneAuthStepTracker'
import './Sidebar.scss';
import { setSidebarActiveMenu } from '../../../../../app/services/store/sidebar'
import StaticRoutePath from '../../../../../app/routing/StaticRoutePath'
import { useContinueRegistration } from '../../../../../app/pages/guest/hooks/useContinueRegistration'
import { useIntl } from 'react-intl'
import { useAppAlerts } from '../../../../../app/utilities/alerts'
type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  redirectTo?: boolean
  id?:number
  setActiveMenu: Dispatch<SetStateAction<number>>;
  activeMenu?:number
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  redirectTo=true,
  activeMenu,
  id,
  setActiveMenu,
}) => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
  let navigateTo = useNavigate()
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value); //gets location setttings
  const { cookies_name, flow } = constants;
  const StoreDispatch = useDispatch();
  const guest = useSelector((state: any) => state.guest);
  const isRegistrationCompleted = guest.data._embedded?.registration?.completed == 1
  const [registrationLoading, continueRegistration] = useContinueRegistration();
  const {fireConfirmAlert} = useAppAlerts()

  let base_domain = Cookies.get(cookies_name.location_domain_name)
  if (base_domain && base_domain.trim() === '') {
    base_domain = LocSettings.location.id;
  }
const handelRouting = (route:any, activeMenuid:any) =>{
    if (route == '/order') {
      StoreDispatch(setFlow(flow.order));
      StoreDispatch(setUnableToUseBiometricsLogin(false));
      navigateTo(`/${base_domain}/`);
    }
    if (route == StaticRoutePath.OLO_MENU) {
      StoreDispatch(setFlow(flow.order));
      navigateTo(`/${base_domain}${StaticRoutePath.OLO_MENU}`);
    }
     
    if (route == '/reservations') {
      StoreDispatch(setFlow(flow.reservations));
      StoreDispatch(setUnableToUseBiometricsLogin(false));
      navigateTo(`/${base_domain}/`);
    }
    setActiveMenu(activeMenuid)
    StoreDispatch(setSidebarActiveMenu(activeMenuid))

    if(route == 'profile'){
      //console.log('path-name',pathname);
        StoreDispatch(setFlow(flow.profile));

      navigateTo(`/${base_domain}/my-account`);
      
      /*if(pathname == `/${base_domain}/my-account`){
        navigateTo(`/${base_domain}/my-account`); 
      }else{

        StoreDispatch(setFlow(flow.profile));
        StoreDispatch(setUnableToUseBiometricsLogin(false));
        navigateTo(`/${base_domain}/auth`);
      }*/
    }
    if(route == 'pre-checkin'){
      if (
        guest.isLoading == false &&
        (guest.auth.isAuthenticated != true || Object.keys(guest.data).length == 0))
      {
        continueRegistration(true)
        return
      }
      if(isRegistrationCompleted)
          doPreCheckin()
      else
          fireConfirmAlert(intl.formatMessage({id: 'dashboard.alert.feature_available_to_registered_user'}), intl.formatMessage({id: "alert.no"}), intl.formatMessage({id: "alert.yes"})).then((result) => {
              if (result.isConfirmed) {
                  continueRegistration(true)
              }
          })
    }
  }

  const doPreCheckin = () => {
    StoreDispatch(setFlow(flow.pre_checkin));
    StoreDispatch(setUnableToUseBiometricsLogin(false));
    navigateTo(`/${base_domain}/${constants.routes.private.pre_checkin}`);
  }

  return (
    redirectTo
    ?
    <div className='menu-item'>
      <Link  className={clsx('menu-link without-sub', {active: id == activeMenu })} to= {{ pathname: `/${base_domain}${to}` }} onClick= {() => StoreDispatch(setSidebarActiveMenu((id??1))) }>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className={clsx('menu-title', {'text-primary': id == activeMenu })}>{title}</span>
      </Link>
      {children}
    </div>
    :
    <div className='menu-item'>
      <div className={clsx('menu-link without-sub', {active: id == activeMenu })} onClick={() =>handelRouting(to, id)}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className={clsx('menu-title', {'text-primary': id == activeMenu })}>{title}</span>
      </div>
      {children}
    </div>
  )
}

export {AsideMenuItem}
