import { createSlice } from '@reduxjs/toolkit'

export interface ITaxRate {
    id: number,
    status: number,
    name: string,
    exclusive: number,
    rate: number,
    rate_dec: number,
    default: number
 }
 
export interface TaxRatesState {
    isLoading: boolean,
    data: Array<ITaxRate>,
}

const initialState: TaxRatesState = {
    isLoading: false,
    data: [],
}

export const taxRatesSlice = createSlice({
    name: 'taxRates',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setTaxRates: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
        
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setTaxRates } = taxRatesSlice.actions
