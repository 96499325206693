import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {LoadingPage} from "../../modules/widgets/Loaders/LoadingPage";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {HeartRating} from "./components/HeartRating";
import {HorizontalRuler} from "./components/HorizontalRuler";
import * as ApiCallService from "../../services/ApiCallService";
import Swal from "sweetalert2";
import {setSelectedCardToPay} from "../../services/store/ticket/payment";
import { getTicket } from '../../services/store/ticket';
import { constants } from '../../config/constants';
// @ts-ignore
import Cookies from 'js-cookie'

import CustomButton from '../../common/button/CustomButton';

import { useTextColor } from '../../services/hooks/useTextColor';
import { HeaderTitleContext } from '../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore';
import {setModTicket} from "../../services/store/mod_ticket";
import { FormattedMessage, useIntl } from 'react-intl';



export const PaymentDone = () => {
    const intl = useIntl();
    const dispatch = useDispatch<AppDispatch>();
    const {setPageTitle} = useContext(HeaderTitleContext)
    const navigateTo = useNavigate()
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const ticket:any = useSelector((state: RootState) => state.ticket);
    const lastPayment:any = useSelector((state: RootState) => state.payment.lastPayment);
    const SplitData:any = useSelector((state: RootState) => state.payment.SplitData);
    console.log('lastPayment',lastPayment);
    const loggedInGuest:any = useSelector((state:RootState)=>state.guest)
    const [currentRating, setRating] = useState(0)
    const [heartClick, setHeartClick] = useState(false);
    const [loading, setLoading] = useState(true);
    const { cookies_name } = constants;
    let location = ticket.data._embedded.location_info  
    const themeText = LocSettings.appearance === 1 ? 'text-white' : 'text-black'
    const [textBgColor] = useTextColor();

    let base_domain = Cookies.get(cookies_name.location_domain_name)
    if (base_domain && base_domain.trim() === '') {
        base_domain = LocSettings.location.id;
    }
    useEffect(() => {
        setPageTitle(intl.formatMessage({id:constants.staticPageTitle.receipt}));
    })
    useEffect(() => {
        
        dispatch(setSelectedCardToPay({}))
        let ticketData = ticket.data;
        console.log('ticketData',ticketData);
        let ticketPayments = ticket?.data?._embedded?.payments;
        if(!loading && ticketData.average_score != currentRating && heartClick){
            var RevData = {
                location_id: LocSettings.location.id,
                guest_id: ticketData!.guest_id?.id>0?ticketData!.guest_id?.id:loggedInGuest.data.id,
                ticket_id: ticketData.id,
                payment_id: ticketPayments[0].payment_id,
                score: currentRating
            }
            ApiCallService.ApiCall({...RevData}, `payment_guest_review`, 'POST', LocSettings, true).then((res: any) => {
                if (res.error) {
                    Swal.fire({
                        customClass: {
                            confirmButton: 'btn btn-danger btn-sm fs-5',
                        },
                        icon: 'error',
                        background: LocSettings.appearance === 1 ? '#fffff' : '#151521',
                        text: res.error.message,
                        confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
                        width: '29rem',
                        heightAuto: false,
                    })
                } else {
                    Swal.fire({
                        customClass: {
                            confirmButton: 'btn btn-primary btn-sm fs-5',
                        },
                        icon: 'success',
                        background: LocSettings.appearance === 1 ? '#fffff' : '#151521',
                        text: intl.formatMessage({id:"receipt.paymentdone.alert.review_submitted"}),
                        confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
                        width: '29rem',
                        heightAuto: false,
                    })
                    ticketData.average_score = currentRating;
                }
                setHeartClick(false);
            })
        }
    }, [loading, currentRating])

    if(ticket.isLoading) {
        return <LoadingPage />
    }

    if(Object.keys(ticket.data).length == 0 || ticket?.data?._embedded?.payments == undefined) {
        navigateTo(`/${base_domain}`);
        return <></>;
    }

    console.log("ticket data", ticket)
    let payments = ticket.data._embedded.payments;

    let last_payment = payments[payments.length - 1];

    if(lastPayment != undefined && Object.keys(lastPayment).length > 0) {
        last_payment = lastPayment;
    }
   

    let SignatureImage = '';
    const API_URL = process.env.REACT_APP_SP_API_URL;    
    const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON
    if (last_payment?.signature_url) {
        SignatureImage = `${API_URL}/download-file?path=${last_payment?.signature_url}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`;        
    }


    if(loading) {
        setLoading(false)
    }
    const handleOpenReceipt = (ticketId: any) => {
        dispatch(setModTicket({}));
        dispatch(getTicket(ticketId, LocSettings))
        navigateTo(`/${base_domain}/receipt`);
    }
    return (
        <div className='container-fluid mw-500px px-0 mt-n5 '>
            <div className='mx-20px mx-md-0'>
                <div className='w-100'>
                <div className=''>
                        <div className='text-center d-flex flex-column align-items-center logo'>
                            <div className='text-center d-flex flex-column'>
                                <div ref={(el) => el?.style.setProperty('border-color', 'rgb(80, 205, 137, 0.3)', 'important')} className='swal2-icon swal2-success my-0'>
                                    <span
                                        ref={(el) => el?.style.setProperty('background-color', '#50cd89', 'important')} className='swal2-success-line-tip'></span>
                                    <span ref={(el) => el?.style.setProperty('background-color', '#50cd89', 'important')} className='swal2-success-line-long'></span>
                                </div>
                            </div>
                            {/* <p className='fw-bold fs-6 m-0'>Payment processed successfully</p> */}
                        </div>
                        <div className='w-100 card p-5 card card-flush h-lg-100'>
                            <div className="card card-flush h-lg-100">
                                <div className="mb-5">
                                    <div className='text-center text-dark' style={{ fontSize: '17px', textAlign: 'center' }}>
                                        <div><strong><FormattedMessage id='receipt.paymentdone.thank_you' defaultMessage='Thank you' />, {loggedInGuest.data.first_name?`${loggedInGuest.data.first_name} ${loggedInGuest.data.last_name}`:''} </strong></div>
                                        <div className="d-flex justify-content-center mt-3 fs-7">
                                            {
                                                SplitData.SplitOpt>0? intl.formatMessage({id:"receipt.paymentdone.your_split_payment_paid"}):intl.formatMessage({id:"receipt.paymentdone.your_table_paid_in_full"})
                                            }
                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="separator separator-dashed my-3"></div>
                                <div className="">
                                    <div className='text-center text-dark' style={{ fontSize: '17px', textAlign: 'center' }}>
                                        <div><strong><FormattedMessage id='receipt.paymentdone.how_was_experience' defaultMessage='How was your experience?'/> </strong></div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <HeartRating setHeartClick={setHeartClick} setRating={setRating} currentRating={currentRating} ticket_review={ticket.data.average_score} />
                                        </div>
                                    </div>
                                </div>
                                <div className="separator separator-dashed my-3"></div>



                               
                                <div className="card-header p-0">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-dark">{location.name}</span>
                                        <span className="text-gray-400 mt-1 fw-semibold fs-6"><FormattedMessage id='profile.receipt.ticket' defaultMessage='Ticket'/> <span className='fw-bold text-dark'>#{ticket.data.ticket_number}</span> </span>
                                    </h3>
                                    <div className="card-toolbar flex-column align-items-end">
                                        <span className="text-gray-400 mt-1 fw-semibold fs-6">{moment(ticket.data.opened_at_local).format('YYYY-MM-DD')}</span>
                                        <span className="text-gray-400 mt-1 fw-semibold fs-6">{moment(ticket.data.opened_at_local).format('h:mm A')}</span>
                                    </div>
                                    <div className='w-100'>
                                        <div className="separator separator-dashed my-3"></div>
                                    </div>
                                </div>


                                <div className="card-body  p-0">
                                    <div className="d-flex flex-stack">
                                        {/* <div className="text-start fw-semibold fs-6 me-2" >You've Paid</div> */}
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="text-start card-label fw-bold text-dark  me-2"><FormattedMessage id='receipt.paymentdone.you_have_paid' defaultMessage="You've Paid"/></span>
                                        </h3>
                                        <h3  className="card-title align-items-start flex-column">
                                            <div className="d-flex align-items-senter">
                                                <span className="text-start card-label fw-bold text-dark">
                                                    ${SplitData?.total_amount>0?(SplitData?.total_amount/100).toFixed(2):(last_payment?.total_amount / 100.00).toFixed(2)}
                                                </span>
                                            </div>
                                        </h3>
                                    </div>
                                </div>
                                {
                                    SplitData.SplitOpt>0 && ticket.data?.totals?.grand_due>0?
                                    <div className="card-body  p-0">
                                        <div className="d-flex flex-stack">                                        
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="text-start card-label fw-bold text-dark  me-2"><FormattedMessage id='profile.receipt.balance_due' defaultMessage='Balance Due' /></span>
                                            </h3>
                                            <h3  className="card-title align-items-start flex-column">
                                                <div className="d-flex align-items-senter">
                                                    <span className="text-start card-label fw-bold text-dark">
                                                        ${(ticket.data?.totals?.grand_due / 100.00).toFixed(2)}
                                                    </span>
                                                </div>
                                            </h3>
                                        </div>
                                    </div>:''
                                }
                                {
                                    SignatureImage!=""?
                                    <div className="card-body  p-0">
                                        <div className=' mx-20px mx-md-0'>
                                            <div className='logo'>
                                                <img 
                                                    src={SignatureImage}
                                                    className='h-100px w-100 symbol'
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.alt='';
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>:''
                                }
                                <div className='text-center mt-5 '>
                                    <CustomButton title={intl.formatMessage({id:"receipt.paymentdone.view_receipt"})} extraClass='w-100' onClick={() => handleOpenReceipt(ticket.data.id)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
