import React, {useState} from 'react'
import axios from "axios";
import Swal from "sweetalert2";
import {instanceOfSpError, ISpError} from "../../common/api_interfaces/Errors";
import {constants} from "../../config/constants";

export interface IntIntegrationActivityLog {
    global_integrations_activity_types_id: number,
    global_processors_id?: number|null,
    global_integrations_id?: number|null,
    status: 0|1,
    url: string,
    response?: string,
}

export const useIntegrationActivityLog = () => {
    const API_URL = process.env.REACT_APP_SP_API_URL;

    const sendIntegrationActivityLog = async (LocSettings:any, params: IntIntegrationActivityLog) => {
        console.log("params", params)
        let payload = {
            global_on_id: process.env.REACT_APP_GLOBAL_ON,
            user_id: LocSettings.location.owner,
            global_integrations_activity_types_id: params.global_integrations_activity_types_id,
            global_processors_id: params.global_processors_id,
            status: params.status,
            url: params.url,
            response: params.response,
        }

        try {
            const response = await axios.post(`${API_URL}/locations/${LocSettings.location.id}/integrations_activity_logs`, payload);
            return response.data
        } catch (error) {
            return  error
        }
    }

    const processorIframeDefaultParams = (LocSettings:any) => {
        let global_processors_id = LocSettings.processor.global_processor_id;
        let global_integrations_activity_types_id = constants.integrations_activity_logs.types.IFRAME_REQUEST.id;

        console.log("global_integrations_activity_types_id", global_integrations_activity_types_id)

        return {
            global_processors_id: global_processors_id,
            global_integrations_activity_types_id: global_integrations_activity_types_id,
        }
    }

    return {sendIntegrationActivityLog, processorIframeDefaultParams};
}
