import * as Yup from 'yup'
const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
export const PhoneSearchSchema = Yup.object().shape({
    phoneNumber: Yup
        .string()
        .required('form.error.phone_required')
        .matches(phoneRegExp, 'form.error.enter_valid_phone')
})

export const PhoneShemma = (length:number=10) => {
    let phoneRegExpL = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
    if(length>6){
        let lastno = length - 6;
        phoneRegExpL =  new RegExp("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{"+lastno+"}$");
    }else if(length>3){
        let lastno = length - 6;
        phoneRegExpL =  new RegExp("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{"+lastno+"}$");
    }

    console.log(phoneRegExpL);


    return Yup.object().shape({
        phoneNumber: Yup
            .string()
            .required('form.error.phone_required')
            .matches(phoneRegExpL, 'form.error.enter_valid_phone')
    });
}