import {SyntheticEvent, useEffect, useState} from 'react'
import {isEmpty} from 'lodash'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import {capitalizeWords} from '../../../services/hooks/useCapitalizeWords'
import {TextColorBasedBgLoction, useLocationBasedBtnColor} from '../../../utilities/helpers'
import {StoreClosedBanner} from './StoreClosedBanner'
import {FormattedMessage} from 'react-intl'
import './OloStoreHeader.css'

export const HeroImage = () => {
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  const locationState = useSelector((state: RootState) => state.locationState.data)
  const capitalizeLocationName = capitalizeWords(LocSettings?.location?.location_name)
  const [imageSrc, setImageSrc] = useState<string>('')
  const dynamicBtnColor = useLocationBasedBtnColor()
  const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON
  const SP_API_URL = process.env.REACT_APP_SP_API_URL
  const locationLogo = LocSettings.location.logo
  const backgroundImage = `${SP_API_URL}/download-file?path=${locationLogo}&global_on_id=${GLOBAL_ON_ID}`
  const guest = useSelector((state: RootState) => state.guest)
  const locationWebsite = LocSettings.location.website

  let profileIcon = null
  if (!isEmpty(guest.data) && guest.data.image) {
    profileIcon = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${guest.data.image}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
  }

  let LocationIcon = ''
  if (LocSettings?.icon) {
    LocationIcon = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.icon}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
  }

  let reservation_banner = ''
  if (LocSettings?.reservation_banner) {
     reservation_banner = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.reservation_banner}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
  }

  // useEffect(() => {
  //   const img = new Image()
  //   img.crossOrigin = 'anonymous' // Make sure the image can be loaded from a different origin
  //   img.src = backgroundImage
  //   const canvas = document.createElement('canvas')
  //   const ctx = canvas.getContext('2d')
  //   img.onload = () => {
  //     canvas.width = img.width
  //     canvas.height = img.height
  //     if (ctx) {
  //       ctx.drawImage(img, 0, 0)
  //       const imageUrl = canvas.toDataURL()
  //       setImageSrc(`url(${imageUrl})`)
  //     }
  //   }
  // }, [])

  return (
    <>
      <div className='heroImageStyles no-box-shadow position-relative'>
        <div id="StoreBanner" style={{height:reservation_banner ? '250px' : '50px'}}>
          {reservation_banner && (
            <img src={reservation_banner} alt={capitalizeLocationName ?? ""} height="250" id="ImgStoreBanner" />
          )}
        </div>
        <div id="StoreLogo" style={{top:reservation_banner ? '208px' : '10px'}}>
          <img src={LocationIcon} alt={capitalizeLocationName ?? ""} id="ImgStoreLogo" />
        </div>
        <div
          className={`d-flex flex-column pt-14 col-sm-12 col-md-10 p-5 symbol h-190px`}
        >
          <div className={`col-12 pt-2`}>
            <h1 className={`text-start mt-3`}>
              <FormattedMessage id='olo.ordering_from' defaultMessage='Ordering From' />{' '}
              {capitalizeLocationName}
            </h1>
            <div className='form-group d-flex fs-4'>
              <p className='p-0 m-0 fs-5'>
                {`${LocSettings?.location?.city}, ${Object.keys(locationState).length !== 0 ? `${locationState}` : ''
                  }`}
              </p>
            </div>
            <div className='form-group d-flex mt-1'>
              <p className='p-0 m-0 fs-6'>
                <StoreClosedBanner />
              </p>
            </div>
            <div className='form-group d-flex fs-4'>
              {locationWebsite === null ? null : (
                <a
                  className={`p-0 m-0 fs-5`}
                  target={'_blank'}
                  href={LocSettings.location.website}
                >
                  {LocSettings.location.website.replace('https://', '')}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
