import {setGuest, startLoading, setGuestAuthState, IGuestAuthState, logOutGuest} from "./guestSlice";
import * as ApiCallService from "../../ApiCallService";
import moment from "moment";
import {constants} from "../../../config/constants";
import {setPayInitialState} from "../ticket/payment";
import {setReservation} from "../reservations";
import {setLanguageAutomaticallyByGuestData} from "../../../../_spc/i18n/I18n";
import {setGuestCCTokens} from "./credit_card_tokens";
import {setTicketInitialState} from "../ticket";
import {clearRegistrationChecklist, setRegistrationID} from "../registration/RegistrationSlice";

export interface IGuestAuth{
    isAuthenticated:boolean,
    authMethod:'TWO_FA'|'BIOMETRICS'|null,
    childGuestId?:string|number|null,
    biometricsLogSyncId:string|null
}

export interface IGuest {
    isLoading: boolean,
    data: object,
    auth:IGuestAuthState
}

export const getGuest = (guest_id: number, LocSettings: any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch( setGuest({}) )
        dispatch(startLoading())
        ApiCallService.ApiCall({location_id: LocSettings.location.id}, `guests/${guest_id}`, 'GET', LocSettings, true)
            .then((res: any) => {
                dispatch( setGuest(res.data) )
                setLanguageAutomaticallyByGuestData(res.data);
            }).catch((err) => {
            dispatch( setGuest({}) )
        });
    }
}

export const setGuestAuth = (data:IGuestAuth) => {
    return (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())
        const fullData:IGuestAuthState = {
            ...data,
            startedAt: moment().format(),
            biometricsGuestsFound: [],
        }

        dispatch( setGuestAuthState(fullData) )
    }
}

export const setLogOut = () => {
    return (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(logOutGuest())
        dispatch(setPayInitialState())
        dispatch(setReservation({}))
        dispatch(setGuestCCTokens({}))
        dispatch(setTicketInitialState())
        dispatch(clearRegistrationChecklist())
    }
}

//Verify and clear the session if it has been expired.
export const verifyGuestSession = (guest:IGuest) => {
    return (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        if(guest.auth.startedAt != null && moment().diff(guest.auth.startedAt, 'seconds') > constants.session.life_time) {
            console.log("going to clear session!!!")
            dispatch(logOutGuest())
            dispatch(setPayInitialState())
        }
    }
}
