export interface ISpError {
    error: {
        code: number,
        message: string,
        details: [] | null
    }
}

export interface IStandardError{
    code:number,
    message:string,
    details:{[key: string|number]: any}|null
}

export const instanceOfSpError = (object:any): object is ISpError => {
    if(!('error' in object)) return false
    const hasMsg = (object as ISpError).error.message !== undefined
    const hasCode = (object as ISpError).error.code !== undefined
    return hasMsg && hasCode
}
