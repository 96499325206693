import {setError, setReservation, startLoading} from './reservationSlice'
import * as ApiCallService from '../../ApiCallService'

export const getReservation = (reservation_id: number, LocSettings: any) => {
   return async (dispatch: (arg0: {payload: any; type: string}) => void, state: any) => {
      dispatch(startLoading())

      ApiCallService.ApiCall({with: 'guests'}, `reservations/${reservation_id}`, 'GET', LocSettings)
         .then((res: any) => {
            if (res?.error?.message != undefined) {
               dispatch(setError(res.error))
            } else {
               dispatch(setReservation(res.data))
            }
         })
         .catch((err) => {
            dispatch(setReservation({}))
         })
   }
}

export const getReservationByGuestId = (guest_id: number, LocSettings: any) => {
   return async (dispatch: (arg0: {payload: any; type: string}) => void, state: any) => {
      dispatch(startLoading())

      ApiCallService.ApiCall({}, `guests/${guest_id}/next_reservation`, 'GET', LocSettings)
         .then((res: any) => {
            if (res?.error?.message != undefined) {
               dispatch(setError(res.error))
            } else {
               dispatch(setReservation(res.data))
            }
         })
         .catch((err) => {
            dispatch(setReservation({}))
         })
   }
}
