import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Dropdown, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomButton from '../../../../common/button/CustomButton'
import { GUEST_TYPE } from '../../../../config/constants';


export interface IAddNewGuestDropdown {
	show: boolean,
	onHide: () => void,
	onClick: (type:GUEST_TYPE) => void,
}

const NewGuestDropDown = (props: IAddNewGuestDropdown) => {
	const intl = useIntl();
	return (
		<Modal show={props.show} aria-labelledby="contained-modal-title-vcenter" centered >
			<Modal.Header closeButton onHide={props.onHide}  className='mx-2'>
				<Modal.Title id="contained-modal-title-vcenter">
					<FormattedMessage id='myaccount.addguest.add_new_guest' defaultMessage='Add New Guest'/>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex mb-7 fv-row fv-plugins-icon-container col-12">
					<div className='col-6 px-2'>
						<CustomButton title={intl.formatMessage({id:"myaccount.addguest.invite_others"})} onClick={()=>{
							props.onClick(GUEST_TYPE.OTHER)
						}} extraClass='w-100 customBtnNoBg'  />
					</div>
					<div className='col-6 px-2'>
						<CustomButton title={intl.formatMessage({id:"myaccount.addguest.add_dependent"})} onClick={()=>{
							props.onClick(GUEST_TYPE.DEPENDANT)
						}} extraClass='w-100 customBtnNoBg'  />
					</div>
				</div>
				<div className="d-flex fv-row fv-plugins-icon-container col-12">
					<div className='col-12 px-2'>
						<FormattedMessage id='myaccount.addguest.dependent_guest_linked_to_primary_guest' defaultMessage='Dependent Guest will be linked to the primary Guest account, they will need to register using his phone number.' /> 
					</div>
				</div>
				
			</Modal.Body>
		</Modal>
	)
}

export default NewGuestDropDown;


/*
import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import CustomButton from '../../../../common/button/CustomButton'

const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
  
  <CustomButton title='Add New Guest'  onClick={(e)=>{
    e.preventDefault()
    onClick(e)
  }} extraClass='w-100 customBtnNoBg'  />
))

{/* <button
    className='btn btn-icon'
    ref={ref}
    onClick={(e) => {
	 e.preventDefault()
	 onClick(e)
    }}
    >
    {' '}
    <i className='bi bi-person-plus' style={{fontSize: '2rem'}}></i>
    {children}
  </button> *//*}

export default function NewGuestDropDown(props) {
return (
  <Dropdown align={'end'}>
    <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'></Dropdown.Toggle>
    <Dropdown.Menu align={'end'}>
	 <Dropdown.Item style={styles.dropdownItem}>Invite Others</Dropdown.Item>
	 <Dropdown.Item style={styles.dropdownItem} onClick={props.addChild}>
	   Add Child
	 </Dropdown.Item>
	 <Dropdown.ItemText style={{minWidth:"174px"}} >Child account can be created by a parent or guardian 12 and younger</Dropdown.ItemText>
	 {/* <Dropdown.Divider /> *//*}
{/*  <Dropdown.Header> 
  
</Dropdown.Header> *//*}
</Dropdown.Menu>
</Dropdown>
)
}
let styles = {
dropdownItem: {height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center'},
}
*/