import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import CustomButton from '../../../common/button/CustomButton'
import { FormattedMessage, useIntl } from 'react-intl'

const Error404: FC = () => {
  const intl = useIntl();
  //Redirect back to base domain for respective location
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
  let navigateTo = useNavigate();
  let base_domain = LocSettings.base_domain;

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        {/* begin::Illustration */}
        <img
          src={LocSettings.appearance === 1 ? '/media/illustrations/sketchy-1/18-dark.png' : '/media/illustrations/sketchy-1/18.png'}
          alt=''
          className='mw-100 mb-10 h-lg-450px'
        />
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className='fw-bold mb-10' style={{ color: '#A3A3C7' }}>
        <FormattedMessage id='error.404.seems_nothing_here' defaultMessage='Seems there is nothing here' />
        </h1>
        {/* end::Message */}
        {/* begin::Link::Home */}
        <CustomButton onClick={() => navigateTo(base_domain)} type="button" extraClass="" title={intl.formatMessage({id:"error.404.return_home"})} />
        {/* end::Link */}
      </div>
    </div>
  )
}

export {Error404}
