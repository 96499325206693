import {HorizontalRuler} from "./HorizontalRuler";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import { FormattedMessage } from "react-intl";

export interface IReceiptPaymentTotals{
    sub_total:number|null,   
    tips:number,
    total:number,
   
}
export const ReceiptPaymentTotals = ({props}:{props:IReceiptPaymentTotals}) => {

    return(
        <table border={0}  cellPadding="5" cellSpacing="0" width="100%" id="receiptTotalsTable">
            <tbody>            
                {
                    props.sub_total !== null && 
                    <>
                        <tr>
                            <td className='text-start text-gray-700 fw-semibold fs-6 '><FormattedMessage id='profile.receipt.subtotal' defaultMessage='Subtotal' /></td>
                            <td/>
                            <td className='text-end'/>
                            <td className='text-end text-gray-400 fw-bold fs-6' id="sub_total">${(props.sub_total/100.00).toFixed(2)}</td>
                        </tr>      
                        <tr>
                            <td className='text-start text-gray-700 fw-semibold fs-6 '><FormattedMessage id='profile.receipt.tip' defaultMessage='Tip' /></td>
                            <td/>
                            <td className='text-end'/>
                            <td className='text-end text-gray-400 fw-bold fs-6' id="tips">${(props.tips/100.00).toFixed(2)}</td>
                        </tr>                     
                        <tr>
                            <td colSpan={4}>
                                <div className="separator separator-dashed my-1"></div>
                            </td>
                        </tr>
                    </>
                }
            <tr >
                <td className='text-start fw-semibold fs-6'><FormattedMessage id={`profile.receipt.${props.sub_total !== null ? 'total' : 'amount'}`} defaultMessage={props.sub_total !== null ? 'Total' : 'Amount'} /></td>
                <td/>
                <td/>
                <td className='text-end fw-bold fs-6' id="grand_total">${(props.total/100.00).toFixed(2)}</td>
            </tr>
            </tbody>
        </table>
    )
}
