import { useState } from 'react';

export const capitalizeWords = (initialValue: string | null) => {
    if(initialValue == null) {
        return null;
    }

    const words = initialValue.split(' ');

    const capitalizedWords = words.map((word) => {
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1);
        return `${firstLetter}${restOfWord}`;
    });

    return capitalizedWords.join(' ');
}

/*export const capitalizeWords = (initialValue: string | null) => {
    // The idea is to capitalize the first letter of each word with a special character or when all letters are capitalized and to skip words that are already capitalized;
    // Limiting user error when inputing names from dashboard;
    const words = initialValue ? initialValue.split(/\s+/) : ''.split(/\s+/);
    return words.map(word => {
        const match = word.match(/^(\W*)([a-zA-Z()\W]+)/);
        if (match) {
            const specialChars = match[1];
            const firstLetter = match[2].charAt(0);
            const restOfWord = match[2].slice(1);
            if (match[2] === match[2].toUpperCase()) {
                // Word is all caps, so capitalize only the first letter
                const capitalizedFirstLetter = firstLetter.toUpperCase();
                return `${specialChars}${capitalizedFirstLetter}${restOfWord.toLowerCase()}`;
            } else if (firstLetter === firstLetter.toUpperCase()) {
                // Word is already capitalized, so return it unchanged
                return `${specialChars}${match[2]}`;
            } else {
                // Capitalize the first letter and convert the rest of the word to lowercase
                const capitalizedFirstLetter = firstLetter.toUpperCase();
                return `${specialChars}${capitalizedFirstLetter}${restOfWord.toLowerCase()}`;
            }
        } else {
            return word;
        }
    }).join(' ');
    // How to use --> Add to file:
    // const [capitalizedWord, capitalizeWords] = useCapitalizeWords(string you want to capitalize);
    // useEffect(() => {
    //     capitalizeWords(string you want to capitalize);
    // }, [string you want to capitalize])
    // Then place: capitalizedWord --> where you need;
}*/
